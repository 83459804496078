import { EventEmitter, Injectable } from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    shareReplay,
    startWith,
    switchMap, merge,
    tap
} from 'rxjs';
import { AuthenticationService, LayerService, NodeService, PlotService } from './';
import { AppState, ItemSubLayer, LayerFeature, NodeRef, PlotRef, LayerCollection } from '@app/shared/models';
import { filter, takeUntil } from "rxjs/operators";
import { EntityType, ISelectedEntity } from "@shared/models/entity-type";
import { ImusDestroyService } from './destroy.service';
import { SettingsService } from './settings.service';


@Injectable({
    providedIn: 'root',
})
export class LayersStateService {
    private readonly _state = new AppState();
    public get state(): AppState {
        return this._state;
    }
    public readonly state$ = this._state.data$.pipe(shareReplay(1));

    private readonly _dataSubject: BehaviorSubject<AppState> = new BehaviorSubject<AppState>(this._state);
    public readonly data$: Observable<AppState> = this._dataSubject;

    public readonly layers$: Observable<LayerCollection> = this.layerService.changed$.pipe(
        startWith('start'),
        switchMap(() => this.layerService.getAll()),
        // tap(data => console.log('layerService.changed$',data)),
        shareReplay(1),
    );
    /** TODO: пока оставляю костыльное решение с хранением id */
    // private readonly _editableLayerId = new BehaviorSubject<number>(null);
    // public readonly editableLayerId$: Observable<number | null> = this.state.editableLayerId$//this._editableLayerId;
    public readonly editableLayerId$: Observable<number | null> = this.data$.pipe(
        switchMap(data => data.editableLayerId$)
    ) 
    public set editableLayerId(layerId: number) {
        this.state.editableLayerId = layerId 
    }
    public get editableLayerId(): number | null {
        return this.state.editableLayerId
    }

    /** Эмитим после обновления слоя */
    public readonly layerUpdated$ = new EventEmitter<number>();
    public readonly settingsUpdated$ = new EventEmitter();

    constructor(
        private layerService: LayerService,
        private nodeService: NodeService,
        // private readonly mapService: MapService,
        private plotService: PlotService,
        private authenticationService: AuthenticationService,
        private settingsService: SettingsService,
        private readonly _destroy$: ImusDestroyService
    ) {
        this.authenticationService.currentUser$
            .pipe(
                tap((currentUser) => {
                    // console.log('authenticationService.currentUser$', currentUser)
                    if (currentUser) this.layerService.updateAllLayersInfo();
                    // Обнуление AppState при логоффе
                    else {
                        // console.log('state',this.state)
                        this.state.setToNull();
                        // this._state = new AppState();
                        // this.setData(null)
                    }
                }),
                takeUntil(this._destroy$)
            )
            .subscribe();
        this.layerService.allLayerTypes$.subscribe(mapObj => this.state.allLayerTypes$.next(mapObj))

        this.data$
            .pipe(
                switchMap(appState => appState.items$),
                // tap(data => console.log('this.state.items$', data)),
                tap(items => this.settingsService.updateLayersSettings(items)),
                takeUntil(this._destroy$)
            )
            .subscribe();

        this.layerUpdated$
            .pipe(
                switchMap(_ => this._dataSubject.value.items$),
                tap(items => this.settingsService.applyLayersSettins(items)),
                takeUntil(this._destroy$)
            )
            .subscribe()


        /** Обновление инфы по всем слоям */
        this.state.allLayerTypes$.pipe(
            filter(Boolean),
            switchMap(_ => this.layers$),
            tap(layerCollection => layerCollection.features
                .sort((a, b) =>
                    a.properties.layer_type?.id - b.properties.layer_type?.id ||
                    a.properties.order - b.properties.order
                )
            ),
            tap(layerCollection => layerCollection.features
                .map((feature, index) => feature.properties.order = index + 1)
            ),
            // tap(layerCollection => console.log('layerCollection',layerCollection)),
            // New version start
            tap(layerCollection =>
                layerCollection.features.forEach((feature) =>
                    this._state.setLayer(feature)
                )
            ),
            // debounceTime(300),
            switchMap(_ => this.plotService.getAll()),
            tap(plotCollection =>
                plotCollection.plots.map((plotFeature) => {
                    this._state.setPlotToLayer(
                        new PlotRef(plotFeature.properties)
                    );
                })
            ),
            // New version end

            //Применяем настройки
            switchMap(_ => this.state.items$),
            tap(items => {
                this.settingsService.applyLayersSettins(items)
                this.setData()
            }),
            takeUntil(this._destroy$)
        ).subscribe();

        let _id: number; // Временная переменная
        /** Обновление инфы по одному слою */
        merge(
            this.layerService.updatedLayer$.pipe(
                tap((layerFeature: LayerFeature) => _id = layerFeature.properties.ID)
            ),
            this.layerService.changedLayer$.pipe(
                tap(id => _id = id),
                switchMap(id => this.layerService.getById(id))
            )
        ).pipe(
            tap(layer => {
                this._state.setLayer(layer);
                const appState = this._state;
                appState.selectedEntity = appState.selectedPlot
                    ? { id: appState.selectedPlot, type: EntityType.PLOT }
                    : appState.selectedNode
                        ? { id: appState.selectedNode, type: EntityType.NODE }
                        : null;
            }),
            switchMap(_ => this.plotService.getAll()),
            tap(plotCollection => plotCollection.plots
                .filter(plot => plot.properties.layer == _id)
                .map(plotFeature => this._state.setPlotToLayer(new PlotRef(plotFeature.properties)))
            ),
            tap(_ => this.layerUpdated$.emit(_id)),
            tap(_ => this.setData()),
            takeUntil(this._destroy$)
        )
            .subscribe();

        this.nodeService.changed$
            .pipe(
                tap(layerID => this.layerService.updateLayerInfo(layerID)),
                takeUntil(this._destroy$)
            )
            .subscribe();

        this.plotService.changed$
            .pipe(
                switchMap((id) => this.plotService.getById(id)),
                takeUntil(this._destroy$)
            )
            .subscribe((plot) => {
                // console.log(plot)
                this.state.setPlotToLayer(new PlotRef(plot.properties));
            });

        this.state.selectedEntity$.pipe(
            tap(value => {
                if (value || JSON.parse(localStorage.getItem('selectedEntity'))) {
                    let valueNew = structuredClone(value) as ISelectedEntity & { node?: NodeRef; plot?: PlotRef }
                    if (valueNew?.type === 'node') valueNew.node = this.state.getNodeByNodeId(valueNew.id)
                    if (valueNew?.type === 'plot') valueNew.plot = this.state.getPlotByPlotId(valueNew.id)
                    localStorage.setItem('selectedEntity', JSON.stringify(valueNew))
                }
            }),
            takeUntil(this._destroy$)
        )
        .subscribe()
    }

    // setData(newValue: Item[] = this.dataSubject.getValue()) {

    setData(newValue: AppState = this._dataSubject.getValue()) {
        this._dataSubject.next(newValue);
    }

    public getData(): AppState {
        return this._dataSubject.getValue();
    }

    // left-panel

    public hasLayerNode(layerId: number, nodeId: number): boolean {
        const item = <ItemSubLayer>(
            this._dataSubject.getValue().getLayerById(layerId)
        );
        return !!(
            item.nodes$ &&
            item.nodes$.getValue().find((node) => node.id == nodeId)
        );
    }

    public hasLayerPlot(layerId: number, plotId: number): boolean {
        const item = <ItemSubLayer>(
            this._dataSubject.getValue().getLayerById(layerId)
        );
        return !!(
            item.plots$ &&
            item.plots$.getValue().find((plot) => plot.id == plotId)
        );
    }


    /**
     * Удаляет Node из AppState
     * @param layerID
     * @param nodeId
     */
    public deleteNode(layerID: number, nodeId: number) {
        // debugger
        let appState: AppState = this._dataSubject.value;
        const nodes = (<ItemSubLayer>appState.getLayerById(layerID)).nodes$;
        const index = nodes.value.findIndex((node) => node.id === nodeId);
        if (index >= 0) {
            nodes.value.splice(index, 1);
            // if (appState.deleteNode(layerID, nodeId)) {
            appState.selectedNode = null;
            // const nodes = (<ItemSubLayer>appState.getLayerById(layerID)).nodes$;
            nodes.next(nodes.value);
            // this.setData()
        } else {
            throw new Error(`Node c id = ${nodeId} не существует`);
        }
    }

    /**
     * Удаляет Plot из AppState
     * @param layerID
     * @param plotId
     */
    public deletePlot(layerID: number, plotId: number) {
        // debugger
        let appState: AppState = this._dataSubject.value;
        const plots = (<ItemSubLayer>appState.getLayerById(layerID)).plots$;
        const index = plots.value.findIndex((plot) => plot.id === plotId);
        if (index >= 0) {
            plots.value.splice(index, 1);
            // if (appState.deletePlot(layerID, plotId)) {
            appState.selectedPlot = null;
            // const plots = (<ItemSubLayer>appState.getLayerById(layerID)).plots$;
            plots.next(plots.value);
            // this.setData()
        } else {
            throw new Error(`Plot c id = ${plotId} не существует`);
        }
    }

    public addNode(node: NodeRef) {
        // let item: Item;

        // item = this.getItem(node.layer);
        // if (!item.nodes) item.nodes = [];
        // item.nodes.push(node);
        // debugger
        const item = <ItemSubLayer>(
            this._dataSubject.value.getLayerById(node.layer)
        );
        item.nodes$.value.push(node);
        /** Оповещаем подписчиков об изменении */
        item.nodes$.next(item.nodes$.value);
        // console.log('item.nodes$', item.nodes$)
    }

    public addPlot(plot: PlotRef) {
        const item = <ItemSubLayer>(
            this._dataSubject.value.getLayerById(plot.layer)
        );
        item.plots$.value.push(plot);
        /** Оповещаем подписчиков об изменении */
        item.plots$.next(item.plots$.value);
        // console.log('item.nodes$', item.nodes$)
    }

    /**
     * "Разворачивает" Plot - начальная Node становится конечной и наоборот, массив координат реверсируется
     * @param plot
     */
    public turnAroundPlot(plot: PlotRef) {
        // console.log('plot start', plot)
        let tempNode = plot.startNode;
        plot.startNode = plot.endNode;
        plot.endNode = tempNode;
        plot.coords.reverse();
        // console.log('plot end', plot)
    }
}
