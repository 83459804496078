import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Item, ItemLayer, ItemSubLayer } from '../models';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    private _settingsSubject: BehaviorSubject<Settings> = new BehaviorSubject(null);
    public settings$: Observable<Settings> = this._settingsSubject;

    public get settingsValue() {
        return JSON.parse(localStorage.getItem('settings')) //this._settingsSubject.value;
    }

    private set settingsValue(settings: Settings) {
        // console.log('set settingsValue')
        localStorage.setItem('settings', JSON.stringify(settings));
        this._settingsSubject.next(settings)
    }

    private static _settingsApplied: boolean = false
    public get settingsApplied() {
      return SettingsService._settingsApplied
    }

    constructor(
      // private readonly _destroy$: ImusDestroyService,
    ) {
      this.initSettings()
    }

    public addLayerSettings(layer: ItemSubLayer) {
      // console.log('addLayerSettings')
      let settings = this.settingsValue
      let layerSettings: LayerSettings = {
        layerId: layer.layerId,
        objectLabels: layer.objectLabels,
        checked: layer.checked,
      }
      if (layer.hasFilters) {
        layerSettings.filters = (layer as ItemSubLayer).filters
      }

      let i = settings.layers.findIndex(value => value.layerId === layer.layerId)
      if (i > -1) settings.layers[i] = layerSettings
      else settings.layers.push(layerSettings)
      this.settingsValue = settings
    }

    /**
     * Инициализация настроек
     */
    public initSettings() {
      if (!this.settingsValue)
        this.settingsValue = {
          backgroundMap: 'OSM',
          layers: []
        }
    }

    public clearSettings() {
      SettingsService._settingsApplied = false
      localStorage.removeItem('settings');
    }

    public applyLayersSettins(items: ItemLayer[]) {
      // console.log('applyLayersSettins')
      items.forEach(item => {
        // console.log('applyLayersSettins', item)
        if (item instanceof ItemLayer) {
          const children = item.children$.getValue();
            children.forEach(child => {
            // console.log('applyLayersSettins child', child.layerId, child.objectLabels, child.checked)
            let layer = this.settingsValue.layers.find(layer => layer.layerId == child.layerId)
            if (layer) {
              child.objectLabels = layer.objectLabels
              child.checked = layer.checked
              child.filters = layer.filters
            } else {
              this.addLayerSettings(child)
            }
          })
          if (Array.from(children).every(([_, _layer]) => !_layer.checked)) {
            item.checked = false;
          }
        }
      })
      SettingsService._settingsApplied = true
    }

    public updateLayersSettings(items: ItemLayer[]) {
      if (!SettingsService._settingsApplied) return
      // console.log('updateLayersSettings')
      let layers = this.settingsValue.layers
      items.forEach(item => {
        // console.log('applyLayersSettins', item)
        if (item instanceof ItemLayer) {
          item.children$.value.forEach(child => {
            // console.log('applyLayersSettins child', child.layerId, child.objectLabels, child.checked)
            let layer = layers.find(layer => layer.layerId == child.layerId)
            if (layer) {
              layer.objectLabels = child.objectLabels
              layer.checked = child.checked
              layer.filters = child.filters
            }
          })
        }
      })
      this.updateSettings({
        layers : layers
      })

    }

    public updateSettings (settings: Partial<Settings>) {
      // console.log('updateSettings')
      this.settingsValue = Object.assign(
        this.settingsValue,
        settings
      )
    }
}

export interface Settings {
    backgroundMap: string;
    layers: LayerSettings[];
}

export interface LayerSettings {
    layerId: number;
    objectLabels: boolean;
    checked: boolean;
    filters?: {
      transparency: number;
      brightness: number;
      contrast: number;
    }
}

