<div class="app-input-area" [ngClass]="{hostborder: (title$ | async) !== undefined || (useIcon$ | async) }">
    <div *ngIf="(title$ | async) !== undefined" class="label-area">
        <mat-label matPrefix>{{title$ | async}}</mat-label>
    </div>
    <div *ngIf="useIcon$ | async" class="icon-area">
        <mat-icon>{{icon}}</mat-icon>
    </div>
    <div class="input-area">
        <mat-form-field class="input-form">
            <textarea matInput
                #inputELement
                [placeholder]="placeholder$ | async"
                [formControl]="innerControl"
                [rows]="rows"
                [readonly]="readonly$ | async"></textarea>
        </mat-form-field>
    </div>
</div>
