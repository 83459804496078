<div class="inputs-area">
  <div>
    <h1>Изменение пароля</h1>

    <ng-container *ngIf="!(emailChanged$ | async);else emailChanged">
    <form [formGroup]="account.form" (ngSubmit)="onSubmit()">

      <p>
        Введите новый пароль
      </p>

      <div class="inputs-grid">

        <!-- <app-input class="input-cell-1" [type]="inputType.email" imusRequired imusEmail [placeholder]="'Пароль'"
          formControlName="password"></app-input> -->
          <!-- <app-input class="input-cell" [type]="inputType.email" imusRequired [placeholder]="'Email'" formControlName="email"></app-input>
          <field-errors formFieldName="email"></field-errors> -->

          <app-input class="input-cell" [type]="inputType.password" imusRequired [placeholder]="'Password'" formControlName="password"></app-input>
          <field-errors formFieldName="password"></field-errors>

        <!-- <span class="password-icon__show" (click)="showPassword()"></span> -->
        <!-- <field-errors formFieldName="email"></field-errors> -->

        <!-- <div class="separator"></div> -->

        <app-input class="input-cell" [type]="inputType.password" imusRequired [placeholder]="'Повторите пароль'"
          formControlName="passwordConfirmation"></app-input>
        <!-- <span class="password-icon__show" (click)="showRepeatPassword()"></span> -->
        <field-errors formFieldName="passwordConfirmation"></field-errors>

        <app-button class="button-cell" [label]="'Отправить'"></app-button>

      </div>

      <!-- <div class="modal-form_group form_group form_group--icon password-icon">

        <input type="password" [type]="passwordInputType" placeholder="Пароль" formControlName="password">
        <span class="password-icon__show" (click)="showPassword()"></span> -->
        <!-- <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
            </div> -->
      <!-- </div> -->
      <!-- <div class="modal-form_group form_group form_group--icon password-icon"> -->
        <!-- <input type="password" [type]="passwordRepeatInputType" formControlName="passwordConfirmation"
          placeholder="Повторите пароль"> -->
        <!-- <span class="password-icon__show" (click)="showRepeatPassword()"></span> -->
        <!-- <div *ngIf="submitted && f['passwordConfirmation'].errors" class="invalid-feedback">
              <div *ngIf="f['passwordConfirmation'].errors.required">password confirmation is required</div>
            </div> -->
      <!-- </div> -->
      <!-- <div class="modal-form_group form_group form_group--center">
        <button [disabled]="loading" class="btn">Отправить</button>
      </div> -->

    </form>

    </ng-container>
    <ng-template #emailChanged>
      <p>
        Пароль успешно изменен!
      </p>
      <p>
        <a href="" (click)="toLoginFromChangePass($event)">Войти</a>
      </p> 
    </ng-template>
    


    <div class="separator"></div>
  </div>