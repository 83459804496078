export class NewLayerType {
    layerType: string;
    id: number;
    parent_id: number;
    name: LayerTypes;
    russian: string;
    hasFilters: boolean;
    multipleLayers: boolean;
    checked: boolean;
    editable: boolean;
    changeName: boolean;
    expanded: boolean;
    removable: boolean;
    hasBlockDataEdit: boolean;
    combineData: boolean;
    /** Отображать у слоев этого типа меню - надписи объектов */
    objectLabels: boolean;
    filters?: {
        transparency: number,
        brightness: number,
        contrast: number
    };
    constructor(source: NewLayerType) {
        Object.assign(this, source);
    }
}

export interface LayerTypesCollection {
    layer_types: NewLayerType[];
    type: string;
}

export enum LayerTypes {
    base = 'base', // 0
    canalization = 'CANALISATION', // 1
    electricity = 'ELECTRICITY', // 2
    gaspipes = 'GASPIPES', // 3
    waterpipes = 'WATERPIPES', // 4
    other = 'OTHER', // 5
    rastr = 'RASTR', // 6
    wmswmts = 'wmswmts', //wms/wmts
    vector = 'vector'
}

export enum LayerTypeIDs {
    base = 0, //'base', // 0
    CANALISATION = 1, //'CANALISATION', // 1
    ELECTRICITY = 2, //'ELECTRICITY', // 2
    GASPIPES = 3, //'GASPIPES', // 3
    WATERPIPES = 4, //'WATERPIPES', // 4
    OTHER = 5, //'OTHER', // 5
    RASTR = 6, //'RASTR', // 6
    wmswmts = 7, //'wms/wmts',
    vector = 8, //'vector'
}
