import { Coordinate } from "ol/coordinate";
import { node } from "@shared/models/node-ref";
import { ItemProperty } from "@shared/models/plot";

export class NodeRef {
    /** Идентификатор ноды */
    id: number | symbol = null;
    /** Координаты точки [lon,lat] */
    get coords(): Coordinate {
        return [+this.lon, +this.lat];
    };
    set coords(c: Coordinate) {
        this.lon = c[0].toString();
        this.lat = c[1].toString();
    };
    layer_id?: number | null = null;
    get layer(): number | null {
        return this.layer_id;
    };
    set layer(layer: number | null) {
        this.layer_id = layer;
    }
    /** TODO: выяснить, зачем? */
    user?: number = null;
    /** Название точки */
    name: string | null;
    /** Широта */
    lat: string = null;
    /** Долгота */
    lon: string = null;
    /** Материал */
    material: ItemProperty = null;
    /** Режим работы */
    on: boolean = false;

    /** Тип объекта */
    get nodeType(): NodeType {
        return this.node_type;
    };

    set nodeType(nodeType: NodeType) {
        this.node_type = nodeType;
    }

    getNodeReq(value?: node.NodeRequest): node.NodeRequest {
        return Object.assign({},
            {
                name: this.name,
                lon: this.lon,
                /** Долгота точки */
                lat: this.lat,
                /** Номер слоя */
                layer_id: this.layer_id,
                /** Номер типа слоя */
                node_type_id: this.nodeType.id,
                /** Отметка поверхности земли */
                height_mark: this.height_mark,
                /** Глубина (дно) колодца */
                well_depth: this.well_depth,
                /** Примечания */
                notes: this.notes,
                /** Материал */
                material_id: this.material?.properties?.id,
                /** Режим работы */
                on: this.on
            },
            value ?? {},
            {
                type: undefined,
                material: undefined
                },
            )
    }

    node_type: NodeType = null;
    /** Файлы, загруженные для точки */
    files: Array<{
        id: number;
        name: string;
        src: string;
    }> = null;
    /** Отметка поверхности */
    height_mark: number | null = null;
    /** Глубина колодца */
    well_depth: number | null = null;
    /** Примечание */
    notes: string | null = null;
    constructor(res?: node.NodeResponse) {
        Object.assign(this, res ?? {}, { on: res ? !!res.on : true });
    }
}

export class NodeCollection {
    type: string;
    features: NodeFeature[]
}

export class NodeFeature {
    type: string;
    properties: node.NodeResponse
}

export class NodeTypeId {
    layer_type_id?: number;
}

export class NodeType extends NodeTypeId {
    id: number;
    name: string;
    icon?: string;
    russian?: string;
}

