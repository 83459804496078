<div class="host-area" [ngClass]="{'hostborder': (title$ | async) !== undefined || (useIcon$ | async), 'no-border': noBorder }">
    <div *ngIf="(title$ | async) !== undefined" class="label-area" [ngClass]="{ 'no-border': noBorder }">
        <mat-label matPrefix>{{title$ | async}}</mat-label>
    </div>
    <div *ngIf="useIcon$ | async" class="icon-area" [ngClass]="{'no-border': noBorder}">
        <mat-icon>{{icon}}</mat-icon>
    </div>
    <div class="input-area">
        <mat-form-field class="input-form" [attr.appearance]="outline ? 'outline' : null">
            <input matInput #inputElement [placeholder]="placeholder$ | async" [formControl]="innerControl" [type]="type" [imask]="mask" [readonly]="readonly$ | async">
            <mat-icon matPrefix *ngIf="type === inputType.password" (mousedown)="onMouseDown($event)">{{eye}}</mat-icon>
        </mat-form-field>
        <mat-label *ngIf="required">*</mat-label>
    </div>
</div>