import { Injectable } from '@angular/core';
import { ItemLayer, ItemSubLayer, Layer, LayerType, NewLayerResponse, NodeFeature, NodeRef, PlotRef } from '@app/shared/models'
import { LayerTypeIDs, LayerTypes } from '@app/shared/models/layer-types';
import { node } from '@app/shared/models/node-ref';
import { LayerService, LayersStateService, NodeService, PlotService } from '@app/shared/services';
import { Observable, switchMap, tap, of, from, mergeMap, forkJoin, concatMap, finalize, BehaviorSubject, defaultIfEmpty } from "rxjs";
// import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class LayerEditService {

  private changeObjects: {
    newNodes: NodeRef[], // список точек, которые нужно добавить
    editNodes: NodeRef[],  // список точек, которые нужно отредактировать
    deleteNodes: NodeRef[]  // список точек, которые нужно удалить
    // newLayers: {
    //   layerTypeID: number,
    //   layerName: string
    // }[], // список слоев, которые нужно добавить
    newLayers: Layer [], // список слоев, которые нужно добавить
    editLayers: Layer[],  // список слоев, которые нужно отредактировать
    deleteLayers: number[]  // id слоев, которые нужно удалить
    newPlots: PlotRef[], // список линий, которые нужно добавить
    editPlots: PlotRef[],  // список линий, которые нужно отредактировать
    deletePlots: PlotRef[]  // список линий, которые нужно удалить
  }


  constructor(
    private layerService: LayerService,
    private layerStateService: LayersStateService,
    private nodeService: NodeService,
    private plotService: PlotService,
  ) {
    this.changeObjects = {
      newNodes: [],
      editNodes: [],
      deleteNodes: [],
      newLayers: [],
      editLayers: [],
      deleteLayers: [],
      newPlots: [],
      editPlots: [],
      deletePlots: []
      }
  }

  public addNewNode(node: NodeRef) {
    this.changeObjects.newNodes.push(node)
  }

  public addNodeToEdit(node: NodeRef) {
    if (
        !this.changeObjects.newNodes.find((o) => o.id === node.id)
      && !this.changeObjects.editNodes.find((o) => o.id === node.id)
    ) this.changeObjects.editNodes.push(node)
  }

  public addNodeToDelete(node: NodeRef) {
    // console.log('addNodeToDelete')
    let index: number
    index = this.changeObjects.newNodes.findIndex((o) => o.id === node.id)
    if (index >= 0) {
      this.changeObjects.newNodes.splice(index, 1)
    } else {
      index = this.changeObjects.editNodes.findIndex((o) => o.id === node.id)
      if (index >= 0) {
        this.changeObjects.editNodes.splice(index, 1)
      }
      if (!this.changeObjects.deleteNodes.find((o) => o.id === node.id))

        this.changeObjects.deleteNodes.push(node);
    }
  }

  public addNewPlot(plot: PlotRef) {
    this.changeObjects.newPlots.push(plot)
  }

  public addPlotToEdit(plot: PlotRef) {

    if (!this.changeObjects.newPlots.find((o) => o.id === plot.id)) {
      if (!this.changeObjects.editPlots.find((o) => o.id === plot.id))
        this.changeObjects.editPlots.push(plot)

    }

  }

  public addPlotToDelete(plot: PlotRef) {
    // if (!this.changeObjects.deletePlots.find((o) => o.id === plot.id))
    //   this.changeObjects.deletePlots.push(plot)

    let index: number
    index = this.changeObjects.newPlots.findIndex((o) => o.id === plot.id)
    if (index >= 0) {
      this.changeObjects.newPlots.splice(index, 1)
    } else {
      index = this.changeObjects.editPlots.findIndex((o) => o.id === plot.id)
      if (index >= 0) {
        this.changeObjects.editPlots.splice(index, 1)
      }
      if (!this.changeObjects.deletePlots.find((o) => o.id === plot.id))
        this.changeObjects.deletePlots.push(plot);
    }

  }

  public addNewLayer(layerType: LayerTypes, layerName: string, order?: number) {
    // let layer = new Layer
    let layer = <Layer>{
      name: layerName,
      layer_type: {
        id: LayerTypeIDs[layerType],
        name: layerType
      },
      order: order ?? Math.floor(Math.random() * 99) + 1
    }

    // let layer = new Layer
    // layer.name = layerName;
    // layer.layer_type = {
    //   id : layerTypeID,
    //   name : layerTypeID
    // }
    // layer.order = order??undefined

    this.layerService.add(layer).pipe(
      /** TODO: зачем смотрим на свойство status при статусе 200?? */
      tap((response) => {
        if (response.status !== 'ok') {
          throw new Error(`Ошибка добавления слоя, статус:${response.status}`)
        }
      }),
    ).subscribe((value: NewLayerResponse) => {
      // console.log('layerService.add')
      // console.log(value)
      this.layerService.updateLayerInfo(value.layer_id)
    })

    //Реализация добавления слоев через массив newLayers
    // this.changeObjects.newLayers.push(layer)
    // this.save()
  }

  public addLayerToEdit(layer: Layer) {
    this.changeObjects.editLayers.push(layer)
    this.save().subscribe()
  }

  public addLayerToDelete(layerID: number) {
    this.changeObjects.deleteLayers.push(layerID)
    this.save().subscribe()
  }

  deleteSelectedNode() {
    // debugger
    let appState = this.layerStateService.getData()
    if (appState.edit && appState.selectedNode && this.layerStateService.hasLayerNode(appState.editableLayerId, appState.selectedNode)) {
      this.addNodeToDelete(appState.getNodeByNodeId(appState.selectedNode))
      // this.addNodeToDelete(this.layerStateService.getNode(appState.selectedNode))
      // appState.deleteNode(appState.selectedNode)
      this.layerStateService.deleteNode(appState.editableLayerId, appState.selectedNode)
    }

    // if (appState.edit && appState.selectedPlotPoint && this.layerStateService.hasLayerPlot(appState.editableLayerId, appState.selectedPlot)) {
    //   // appState.getPlotByPlotId(appState.selectedPlot).coordinates.splice(appState.selectedPlotPoint, 1)
    //   // coordinates.splice(1, 1);
    //   // line.getGeometry().setCoordinates(coordinates);
    // }

  }

  deleteSelectedPlot() {
    // debugger
    let appState = this.layerStateService.getData()
    if (appState.edit && appState.selectedPlot && this.layerStateService.hasLayerPlot(appState.editableLayerId, appState.selectedPlot)) {
      this.addPlotToDelete(appState.getPlotByPlotId(appState.selectedPlot))
      this.layerStateService.deletePlot(appState.editableLayerId, appState.selectedPlot)
    }
  }

  /**
   * "Разворачивает" выбранную линию - Selected Plot
   */
  public turnAroundSelectedPlot() {
    let appState = this.layerStateService.getData()
    if (appState.edit && appState.selectedPlot && this.layerStateService.hasLayerPlot(appState.editableLayerId, appState.selectedPlot)) {
      let plot = appState.getPlotByPlotId(appState.selectedPlot)
      this.layerStateService.turnAroundPlot(plot)
      this.addPlotToEdit(plot)
    }
  }


  public save(layerTypeId?: number) {
    let finishedAll$: Observable<Object>[] = [];
    let finishedAllPlots$: Observable<Object>[] = [];
    
    // debugger
    let layersToUpdate: number[] = []
    while (this.changeObjects.newLayers.length > 0) {
      let layer = this.changeObjects.newLayers.pop()
      this.layerService.add(layer).pipe(
        /** TODO: зачем смотрим на свойство status при статусе 200?? */
        tap((response) => {
          if (response.status !== 'ok') {
            throw new Error(`Ошибка добавления слоя, статус:${response.status}`)
          }
        }),
      ).subscribe((value: NewLayerResponse) => {
        // console.log('layerService.add')
        // console.log(value)
        this.layerService.updateLayerInfo(value.layer_id)
      })
    }

    while (this.changeObjects.editLayers.length > 0) {
      let layer = this.changeObjects.editLayers.pop()
      finishedAll$.push(this.layerService.editPutLayer(layer).pipe(
        tap (() => {
          if (!layersToUpdate.find(x => x == layer.ID))
          layersToUpdate.push(layer.ID)
        })
      ))
      // this.layerService.edit(layer.layerName, layer.layerTypeID).subscribe((value: Record<any, any>) => {
      //   // console.log('layerService.edit')
      //   // console.log(value)
      // })
    }

    while (this.changeObjects.deleteLayers.length > 0) {
      let layerID = this.changeObjects.deleteLayers.pop()
      finishedAll$.push(this.layerService.delete(layerID))
      // this.layerService.delete(layerID).subscribe(value => {
      //   // console.log('layerService.delete')
      //   // console.log(value)
      //   // return value
      // })
    }

    while (this.changeObjects.newNodes.length > 0) {
      let node = this.changeObjects.newNodes.pop()
      finishedAll$.push(this.nodeService.add(node.getNodeReq()).pipe(
          tap (responce => {
            // node.id = (responce as node.NodeData).data.properties.id
            // node.id = (responce as node.NodeData).properties.id
            node.id = (responce as NodeFeature).properties.id
            if (!layersToUpdate.find(x => x == node.layer))
            layersToUpdate.push(node.layer)
            })
        )
      //   .subscribe(value => {
      //   // console.log('nodeService.add')
      //   // console.log(value)
      //   if (!layersToUpdate.find(x => x == node.layer))
      //     layersToUpdate.push(node.layer)
      //   // return value
      // })
      )
    }

    while (this.changeObjects.editNodes.length > 0) {
      if (this.changeObjects.deleteNodes.length > 0) {
        this.changeObjects.deleteNodes.map(node => {
          let index = this.changeObjects.editNodes.findIndex((o) => o.id === node.id)
          if (index >= 0) {
            this.changeObjects.editNodes.splice(index, 1)
          }
        })
      }

      let node = this.changeObjects.editNodes.pop()
      if (node) {
        finishedAll$.push(this.nodeService.edit(<number>node.id, node.getNodeReq()).pipe(
          tap (() => {
          if (!layersToUpdate.find(x => x == node.layer))
            layersToUpdate.push(node.layer)
          })
        ))
        // this.nodeService.edit(node).subscribe(value => {
        //   // console.log('nodeService.edit')
        //   // console.log(value)
        //   if (!layersToUpdate.find(x => x == node.layer))
        //     layersToUpdate.push(node.layer)
        //   // return value
        // })
      }
    }

    while (this.changeObjects.deleteNodes.length > 0) {
      let node = this.changeObjects.deleteNodes.pop()
      finishedAll$.push(this.nodeService.delete(<number>node.id).pipe(
        tap (() => {
        if (!layersToUpdate.find(x => x == node.layer))
          layersToUpdate.push(node.layer)
        })
      ))
      // this.nodeService.delete(<number>node.id).subscribe(value => {
      //   // console.log('nodeService.delete')
      //   // console.log(value)
      //   if (!layersToUpdate.find(x => x == node.layer))
      //     layersToUpdate.push(node.layer)
      //   // return value
      // })
    }

    /** Plot section start */
    if (finishedAll$.length == 0) {
      while (this.changeObjects.newPlots.length > 0) {
        let plot = this.changeObjects.newPlots.pop()
        finishedAll$.push(this.plotService.add(plot)
          .pipe(
            tap(() => {
              if (!layersToUpdate.find(x => x == plot.layer))
                layersToUpdate.push(plot.layer)
            })
          )
        )
      }
    }

    while (this.changeObjects.editPlots.length > 0) {
      if (this.changeObjects.deletePlots.length > 0) {
        this.changeObjects.deletePlots.map(plot => {
          let index = this.changeObjects.editPlots.findIndex((o) => o.id === plot.id)
          if (index >= 0) {
            this.changeObjects.editPlots.splice(index, 1)
          }
        })
      }

      let plot = this.changeObjects.editPlots.pop()
      if (plot && typeof plot.id == 'number') {
        // console.log(plot)
        finishedAll$.push(this.plotService.editPut(plot.getPlotReq(), <number>plot.id).pipe(
          tap (() => {
          if (!layersToUpdate.find(x => x == plot.layer))
            layersToUpdate.push(plot.layer)
          })
        ))
      }
    }

    while (this.changeObjects.deletePlots.length > 0) {
      let plot = this.changeObjects.deletePlots.pop()
      finishedAll$.push(this.plotService.delete(<number>plot.id).pipe(
        tap (() => {
        if (!layersToUpdate.find(x => x == plot.layer))
          layersToUpdate.push(plot.layer)
        })
      ))
    }

    /** Plot section end */

    // if (finishedAll$.length > 0) {
      return forkJoin(finishedAll$).pipe(
        tap(_ => {
          while (this.changeObjects.newPlots.length > 0) {
            let plot = this.changeObjects.newPlots.pop()
            finishedAllPlots$.push(this.plotService.add(plot)
              .pipe(
                tap (() => {
                  if (!layersToUpdate.find(x => x == plot.layer))
                  layersToUpdate.push(plot.layer)
                  })
              )
            )
          }
        }),
        concatMap(_ => forkJoin(finishedAllPlots$)),

        // defaultIfEmpty(true),

        // tap(val => console.log(val)),
        // switchMap(val => from(layersToUpdate).pipe(
        //   tap(layerId => this.layerService.updateLayerInfo(layerId)),
        //   // tap(plots => console.log('plotService.getAll', plots)),
        //   // switchMap(plots => from(plots).pipe(
        //   //   tap(plot => this._state.setPlotToLayer(plot))
        //   // )),
        // )),
        finalize(() =>  {
          layersToUpdate.map(layerId => this.layerService.updateLayerInfo(layerId))
          layersToUpdate = []
          })
      )

    // while (layersToUpdate.length > 0) {
    //   let layerID = layersToUpdate.pop()
    //   // let item = this._layerStateService.getData().items.find(x => x.layerId == layerID)
    //   // let layer = new Layer {
    //   //   ID = layerID,
    //   //   // user: string;
    //   //   name = item.name,
    //   //   color = item.color,
    //   //   layer_type = {
    //   //     id = 1,
    //   //     name = item.layerType
    //   //   }
    //   // }

    //   // this.layerStateService.updateLayer(layerID)
    //   this.layerService.updateLayerInfo(layerID)
    // }




    // this.changeObjects.newLayers.forEach(layer => {
    //   this.layerService.add(layer.name, layer.layer_type.name).subscribe(value => {
    //     console.log('layerService.add')
    //     console.log(value)
    //   })
    // })
    // this.changeObjects.editLayers.forEach(layer => {
    //   this.layerService.edit(layer.name, layer.ID)
    // })
    // this.changeObjects.deleteLayers.forEach(layer => {
    //   this.layerService.delete(layer.ID).subscribe(value => {
    //     console.log('layerService.delete')
    //     console.log(value)
    //   })
    // })

    // return finishedAll$;
    // return new BehaviorSubject(finishedAll$);
    // _modalState = new BehaviorSubject<ModalState>(ModalState.newPass);
    // modalState$: Observable<ModalState> = this._modalState.asObservable();

  }

}
