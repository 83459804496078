// import { Feature } from './layer-features';
import {
    Item,
    ItemLayer,
    ItemSubLayer,
    UnitedItem,
} from './layers-typings';
import { NodeRef } from './node';
import { BehaviorSubject, map, Observable, shareReplay } from "rxjs";
import { LayerTypes, NewLayerType } from "@shared/models/layer-types";
import { LayerFeature, PlotRef } from "@shared/models";
import { EntityType, ISelectedEntity } from "@shared/models/entity-type";
import { Attachment, Attachments } from "@shared/attachment";

// import { Node } from './node';

export class AppState {
    // /** @deprecated */
    // items: Item[];
    //#region Layer
    private readonly _selectedLayer$ = new BehaviorSubject<number>(null);
    public readonly selectedLayer$: Observable<number> = this._selectedLayer$;

    /** выбранный слой */
    public get selectedLayer(): number {
        return this._selectedLayer$.getValue();
    };

    public set selectedLayer(value: number | null) {
        this._selectedLayer$.next(value);
    };
    //#endregion Layer

    public allLayerTypes$ = new BehaviorSubject<Map<number, NewLayerType> | null>(null);

    //#region Node
    private readonly _selectedNode$ = new BehaviorSubject<number>(null);
    private readonly _selectedEntity$ = new BehaviorSubject<ISelectedEntity>(null);
    public readonly selectedNode$: Observable<number> = this._selectedNode$;
    public readonly selectedEntity$: Observable<ISelectedEntity> = this._selectedEntity$;
    private readonly _editableLayerId = new BehaviorSubject<number>(null);
    public readonly editableLayerId$: Observable<number> =   this._editableLayerId;

    public get selectedEntity(): ISelectedEntity {
        return this._selectedEntity$.getValue();
    };

    public set selectedEntity(value: ISelectedEntity | null) {
        this._selectedEntity$.next(value);
    };

    public get editableLayerId(): number {
        return this._editableLayerId.getValue();
    };

    public set editableLayerId(value: number | null) {
        this._editableLayerId.next(value);
    };

    /** выбранный Node */
    public get selectedNode(): number {
        return this._selectedNode$.getValue();
    };

    public set selectedNode(value: number | null) {
        this._selectedNode$.next(value);
    };
    //#endregion Node
    //#region Plot
    private readonly _selectedPlot$ = new BehaviorSubject<number>(null);
    public readonly selectedPlot$: Observable<number> = this._selectedPlot$;
    /** выбранный Plot */
    public get selectedPlot(): number {
        return this._selectedPlot$.getValue();
    };

    public set selectedPlot(value: number | null) {
        this._selectedPlot$.next(value);
    };
    //#endregion Plot
    // /** выбранный Plot */
    // selectedPlot: number;
    /** Индекс выбранного узла Plot */
    selectedPlotPoint: number;
    /** тип выбранного слоя */
    selectedLayerType: string;
    /**
     * TODO
     * ItemLayer слой может быть другого типа ItemRastеr и т.д.
     */
    private readonly _data$: BehaviorSubject<Map<LayerTypes, ItemLayer>> =
        new BehaviorSubject(new Map());
    public readonly data$: Observable<Map<LayerTypes, ItemLayer>> = this._data$;
    public readonly items$ = this._data$.pipe(
        map((mapData) => Array.from(mapData.values())),
        shareReplay(1)
        // tap(mapData => console.log(mapData)),
        // delay(1000)
    );

    //#region Edit
    // информация о редактируемом слое либо null если слой не редактируется
    private readonly _edit$ = new BehaviorSubject<Edit>(null);
    public readonly edit$: Observable<Edit> = this._edit$;

    public get edit(): Edit {
        return this._edit$.getValue();
    };

    public set edit(value: Edit | null) {
        // console.log('set Edit in app-state', value)
        this._edit$.next(value);
    };
    //#endregion Edit

    // edit: Edit = null; // информация о редактируемом слое либо null если слой не редактируется
    // editingLayerId: number = null // id редактируемого слоя либо null если слой не редактируется
    // addNode : Node = null // добавить колодец/точку подключения
    // // addPlot: Plot = null //  добавить участок
    // addingObjectType: 'node' | 'plot' = null //тип добавляемого объекта либо null - объект нельзя добавлять

    get editableSubLayer(): ItemSubLayer {
        const _layers = this._data$.getValue().values();
        let item: ItemSubLayer;
        for (let i = 0; i < this._data$.getValue().size && !item; i++) {
            const layer = _layers.next().value as ItemLayer;
            item = layer.children$.getValue().get(this.editableLayerId);
        }
        return item;
    }

    constructor() {
        // this.items = [];
    }

    setToNull() {
        this.selectedPlotPoint = null
        this.selectedLayer = null
        this.selectedEntity = null
        this.selectedNode = null
        this.selectedPlot = null
        this._data$.value.clear()
        this.edit = null
    }

    public deleteLayer(layer: ItemSubLayer): void {
        layer.parent?.children$?.value.delete(layer.layerId);
        // Предыдущая версия
        // if ((layer as ItemSubLayer).parent) {
        //     if ((layer as ItemSubLayer).parent.children$) {
        //         (layer as ItemSubLayer).parent.children$.getValue().delete(layer.layerId);
        //     }
        // }
    }

    /**
     * Возвращает слой по ID
     * @param layerId ID слоя
     * @returns ссылку на слой или null если не найден
     *
     */

    public getLayerById(layerId: number): UnitedItem {
        for (let layer of Array.from(this._data$.getValue().values()).filter((i) => i instanceof ItemLayer)) {
            if (layer.children$?.value.has(layerId))
                return layer.children$.value.get(layerId)
        }
        return null;

        // Предыдущая версия 2.0
        // let items = Array.from(this._data$.getValue().values());
        // for (let layer of items.filter((i) => i instanceof ItemLayer)) {
        //     let item = layer.children$?.value.get(layerId); //.find(item => item.layerId == layerId)
        //     if (item) return item;
        // }
        // return null;

        // Предыдущая версия 1.0
        // return Array.from(this._data$.getValue().values()).map(
        //     l => l.children$.getValue()
        // ).find(
        //     k => k.get(layerId)
        // ).get(layerId);
    }

    /**
     * Получить Node по id
     *
     * @param nodeId - идентификатор ноды
     * @param layerId - идентификатор слоя, к которому относится Node, необязательный параметр
     * @return NodeRef
     * */
    public getNodeByNodeId(nodeId: number | symbol, layerId: number = null): NodeRef {
        if (layerId !== null)  {
            const layer = this.getLayerById(layerId);
            if (layer instanceof ItemSubLayer) {
                return layer.nodes$.getValue().find(n => n.id === nodeId)
            }
        }
        return Array.from(this._data$.getValue().values())
            .map((l) => Array.from(l.children$.getValue().values()))
            .flat()
            .map((l) => l.nodes$.getValue())
            .flat()
            .find((n) => n.id === nodeId);
    }

    public getPlotByPlotId(plotId: number | symbol): PlotRef {
        return Array.from(this._data$.getValue().values()).map(
            l => Array.from(l.children$.getValue().values())
        ).flat().map(l => l.plots$.getValue()).flat().find(n => n.id === plotId);
    }

    public setPlotToLayer(plot: PlotRef): void {
        let layer = plot.layer ? <ItemSubLayer>this.getLayerById(plot.layer) : <ItemSubLayer>this.getLayerById(plot.startNode?.layer)
        if (layer) {
            let _tempNode = this.getNodeByNodeId(plot.startNode.id)
            if (_tempNode) plot.startNode = _tempNode
            // const _tempEndNode = this.getNodeByNodeId(plot.endNode.id)
            // if (_tempEndNode) plot.endNode = _tempEndNode
            _tempNode = this.getNodeByNodeId(plot.endNode.id)
            if (_tempNode) plot.endNode = _tempNode
            const _plots = layer.plots$.getValue();
            const _length = _plots.length;
            if (!_length) {
                layer.plots$.next(_plots.concat(plot));
            }
            for (let i = 0; i < _length; i++) {
                if (_plots[i].id === plot.id) {
                    _plots[i] = plot;
                    layer.plots$.next(_plots);
                    break;
                }
                if (i === _length - 1 && _plots[i].id !== plot.id) {
                    layer.plots$.next(_plots.concat(plot));
                }
            }
        }
        this.updateSelected();
    }

    public updateSelected() {
        this.selectedEntity = this.selectedPlot
            ? { id: this.selectedPlot, type: EntityType.PLOT }
            : this.selectedNode
                ? { id: this.selectedNode, type: EntityType.NODE }
                : null;
    }

    // public getPlotByPlotId(plotId: number): Plot {
    //     return Array.from(this._data$.getValue().values()).map(
    //         l => Array.from(l.children$.getValue().values())
    //     ).flat().map(l => l.plots$.getValue()).flat().find(n => n.id === plotId);
    // }

    // /**
    //  * Удаляет Node по id
    //  * @param nodeId
    //  * @returns true if an element in the Map existed and has been removed, or false if the element does not exist.
    //  */
    // public deleteNode(layerId: number, nodeId: number): boolean {
    //     let layer = <ItemSubLayer>this.getLayerById(layerId)
    //     if (layer) {
    //         let index = layer.nodes$.value.findIndex(node => node.id === nodeId)
    //         if (index > 0) {
    //             layer.nodes$.value.splice(index, 1)
    //             return true
    //         } else {
    //             throw new Error('Нет Node с id = ${nodeId}')
    //         }
    //     }

    //     // for (let [keyItem, valueItem] of this._data$.value) {
    //     //     if (valueItem instanceof ItemLayer && valueItem.children$) {
    //     //         for (let [key, value] of valueItem.children$.value) {
    //     //             for (let i=0; i < value.nodes$.value.length; i++){
    //     //                 if(value.nodes$.value[i].id === nodeId) {
    //     //                     value.nodes$.value.splice(i, 1)
    //     //                     return true
    //     //                 }
    //     //             }
    //     //         }
    //     //     }
    //     // }
    //     return false
    // }

    public setLayer(layerFeature: LayerFeature): void {
        if (!layerFeature) {
            throw new Error('Не определён layerFeature');
        }
        const layer = layerFeature.properties;
        if (!layer) {
            throw new Error('Не определён layer');
        }
        // доп проверка на ломанные слои без типа (не обрабатываем их)
        if (!layer.layer_type) {
            return;
        }
        const _layers = this._data$.getValue();
        /** TODO: зачем сохранять базовый слой?? Взято из легаси */
        // if (!_layers.has(LayerTypes.base)) {
        //     _layers.set(LayerTypes.base, itemsInit.find((item) => item.layerType == LayerTypes.base));
        // }
        /**
         * если нет такого типа слоя, то инициализируем
         * */
        if (!_layers.has(layer.layer_type.name)) {
            this._data$.next(
                _layers.set(
                    layer.layer_type.name,
                    new ItemLayer(
                        Object.assign(
                            {},
                            this.allLayerTypes$.getValue().get(layer.layer_type.parent_id),
                            {
                                hasChildren: true,
                                children$: new BehaviorSubject<Map<number, ItemSubLayer>>(new Map())
                            }
                        )
                    )
                )
            );
        }
        /** если есть такой тип слоя */
        if (_layers.has(layer.layer_type?.name)) {
            const _layer = _layers.get(layer.layer_type.name);
            /** если у слоя с таким типом нет подслоя с таким ID, то инициализируем */
            const _children = _layer.children$.getValue();
            _children.set(
                layer.ID,
                new ItemSubLayer(
                    Object.assign(
                        {},
                        this.allLayerTypes$.getValue().get(layer.layer_type.id),
                        {
                            russian: layer.name,
                            name: layer.name,
                            layerId: layer.ID,
                            color: layer.color,
                            order: layer.order,
                            creator_type: layer.creator_type,
                            global: layer.global,
                            layerTypeId: layer.layer_type.id,
                            nodes$: new BehaviorSubject<NodeRef[]>([]),
                            plots$: new BehaviorSubject<PlotRef[]>([]),
                            hasChildren: false,
                            // data: JSON.parse(layer.data),
                            data: layer.data,
                            files: layer.files?.length ? new Attachments(...layer.files.map(f => new Attachment(f))) : new Attachments(),
                            rastr: layer.rastr?.length ? new Attachments(...layer.rastr.map(f => new Attachment(f))) : new Attachments(),
                            w: layer.w,
                            h: layer.h,
                            format: layer.format ?? null,
                            scale: layer.scale ?? null,
                            center: layer.center ?? null,
                            rotate: layer.rotate ?? null,
                            objectLabels: layer.objectLabels ?? false 
                        }
                    )
                )
            );

            // console.log('_children', _children);

            //TODO проверить типы
            if (layerFeature.rastr) {
                _children.get(layer.ID).rastr = layerFeature.rastr;
                // _children.get(layer.ID).data = JSON.parse(layerFeature.properties.data);
                _children.get(layer.ID).data = layerFeature.properties.data;
                _children.get(layer.ID).w = layerFeature.properties.w;
                _children.get(layer.ID).h = layerFeature.properties.h;

                // _children.get(layer.ID).fileExtent = [0];
                // this.layerService.getJpgwToExtent(src)

            }


            /** обновляем массив точек */
            _children
                .get(layer.ID)
                .nodes$.next(layerFeature.nodes.features.map((p) => new NodeRef(p.properties)));

            /** обновляем массив линий */
            // _children
            //     .get(layer.ID)
            //     .plots$.next(
            //         layerFeature.points.features.map((p) => p.properties)
            //     );


            /** обновляем стрим массива точек */
            // _children.get(layer.ID).nodes = layerFeature.points.features.map(p => p.properties);
            _layer.children$.next(_children);
        }

        this.setParent(_layers.get(layer.layer_type?.name ?? LayerTypes.base));
        this.updateSelected();
    }

    private setParent(item: ItemLayer) {
        if (item instanceof ItemLayer && item.children$.getValue()) {
            const _children = item.children$.getValue();
            _children.forEach((child) => {
                child.parent = item;
            });
        }
    }
}

export class Edit {
    // editingLayerType: string = null // тип редактируемого слоя либо null если слой не редактируется
    // editingLayerId: number = null // id редактируемого слоя либо null если слой не редактируется
    nodeToAdd: NodeRef = null // добавить колодец/точку подключения
    plotToAdd: PlotRef = null //  добавить участок
    addingObjectType: 'node' | 'plot' = null //тип добавляемого объекта либо null - объект нельзя добавлять
    layerTypeId: number = null // идентификатор типа слоя, в подслой которого добавляется объект
    constructor(id: number) {
      if (id === undefined) {
        throw new Error('При добавлении объекта необходимо передать идентификатор типа самого верхнего слоя (канализация, водопровод и т.д.)')
      }
      this.layerTypeId = id;
    }
}
