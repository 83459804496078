import { EventEmitter, Injectable } from '@angular/core';
import { DevicesRouteService } from '@app/routes/device-route.service';
import {
    BehaviorSubject,
    Observable,
    Subscriber,
    of,
    shareReplay,
    startWith,
    switchMap,
    timestamp,
} from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';
import { MapService } from '../../../../map/services/map.service';
import { fromLonLat, Projection, ProjectionLike, toLonLat, transform, transformExtent } from 'ol/proj';
import { Fill, RegularShape, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import * as olExtent from 'ol/extent';
import { Coordinate } from 'ol/coordinate';
import { ToastrService } from 'ngx-toastr';
import Map from 'ol/Map';
import { LayersStateService } from '@app/shared/services';
import { MainPageService } from '@app/modules/main/services/main-page.service';
import { LayerAddFormService } from './layer-add-form.service';
// import { HelmertTransform } from '../models/helmert-transform';
import { Extent } from 'ol/extent';
import { Img } from '../models/wapp-img';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { Size } from 'ol/size';
import { LineString } from 'ol/geom';
// import Helmert from './Helmert/helmerttransform.js';
// import GeoImageLayer from 'ol-ext/layer/GeoImageLayer';

// interface ControlPoint {
//     id: number;
//     img: Feature;
//     map: Feature;
//     img2: Feature;
// }

// interface Layers {
//     image?: ImageLayer<ImageSource>; //ol.layer.Image;
//     vector?: VectorLayer<VectorSource<Geometry>>; //ol.layer.Vector;
//     iclick?: Interaction;
//     imask?: Interaction;
// }

export interface RasterImageData {
        w: number,
        h: number,
        src: string,
}
interface SourceXY {
    name?: string;
    sourceX?: number;
    sourceY?: number;
    sourceLat?: number;
    sourceLon?: number;
    mapX?: number;
    mapY?: number;
    mapLat?: number;
    mapLon?: number;
}

export interface GeoImageSource {
    url?: string;
    image?: any; // TODO: image type
    imageCenter?: Coordinate;
    imageScale?: Size | number;// = [1,1];
    imageRotation?: number;
    imageCrop?: Extent;
    imageMask?: Coordinate[];// = null;
    projection?: ProjectionLike;
}

@Injectable({
    providedIn: 'root',
})
export class RastrBindService {
    // public bindList$ = this.devicesRouteService.devicesList$;
    // public loadingBindList$ = this.devicesRouteService.loadingList$;

    public bindList = new BehaviorSubject<SourceXY[]>([]);

    public bindSelected = new BehaviorSubject<SourceXY>(null);
    public bindSelected$ = this.bindSelected.asObservable();

    public selectMode = new BehaviorSubject<string>(null);
    public selectMode$ = this.selectMode.asObservable();

    public imageUrl = new BehaviorSubject<any>(null);
    public imageUrl$ = this.imageUrl.asObservable();

    public rastr = new BehaviorSubject<RasterImageData>(null);
    public rastr$ = this.rastr.asObservable();

    public imageExtent = new BehaviorSubject<Extent>(null);
    public imageExtent$ = this.imageExtent.asObservable();

    public imageExtentResult = new BehaviorSubject<Extent>(null);
    public imageExtentResult$ = this.imageExtentResult.asObservable();

    public imageResult$ = new BehaviorSubject<GeoImageSource>(null); //: GeoImage;

    // public imageExtentCoords;

    // public bindList$ = this.devicesRouteService.devicesList$;
    // public loadingBindList$ = this.devicesRouteService.loadingList$;

    // public bindList$ = this.loadBindList();

    public imageImg: Img;
    public newMap: Map;

    public readonly changed$ = new EventEmitter<void>();
    public readonly bindList$ = this.changed$.pipe(
        startWith(''),
        switchMap(() => this.loadBindList()),
        shareReplay(1)
    );

    constructor(
        private devicesRouteService: DevicesRouteService,
        private mapService: MapService,
        private layersStateService: LayersStateService,
        public pageService: MainPageService,
        public layerAddFormService: LayerAddFormService,
        private toastr: ToastrService, // private rastrImageService: RastrImageService
        private readonly _destroy$: ImusDestroyService,
    ) {
        this.bindSelected$
            .pipe(takeUntil(this._destroy$))
            .subscribe(newExtent => {
            console.log('newExtent', newExtent);
            // if(newExtent) {
            //     this.imageExtent.next(newExtent);
            // }
        });
        // let helm = new Helmert()
        // console.log(helm)

        this.bindList$.pipe(
            tap(_ => console.log('changed$')),
            filter(_ => !!this.rastr?.value && this.bindList?.value?.length >= 2),
            // filter(bindList => bindList?.length > 0),
            tap(_ => this.extentCalc()),
            takeUntil(this._destroy$)
        )
        .subscribe()

    }

    loadBindList(): Observable<SourceXY[]> {
        return of(this.bindList.value);
    }

    public pushBind() {
        this.bindList.next(
            this.bindList.getValue().concat([
                {
                    name: new Date().getTime().toString(),
                    sourceX: 0,
                    sourceY: 0,
                    sourceLat: 0,
                    sourceLon: 0,
                    mapX: 0,
                    mapY: 0,
                    mapLat: 0,
                    mapLon: 0,
                },
            ])
        );
        this.changed$.emit();
        this.bindSelected.next(
            this.bindList.getValue()[this.bindList.getValue().length - 1]
        );

        // this.selectMode.next('source');
        this.setBindMode('source');

        return this.bindList.getValue()[this.bindList.getValue().length - 1];
    }

    setDemoData() {
        console.log('setDemoData');

        this.loadImage('/assets/data/rastr-demo/rastr34.jpg');

        this.bindList.next([
            {
                sourceX: 8,
                sourceY: 26,
                sourceLat: 37.62474913270115,
                sourceLon: 55.74006088355384,
                mapLat: 37.6076254664689,
                mapLon: 55.73977947238848,
            },
            {
                sourceX: 145,
                sourceY: 260,
                sourceLat: 37.62474913270115,
                sourceLon: 55.74006088355384,
                mapLat: 37.61383814334132,
                mapLon: 55.74266569477891,
            },
        ]);

        this.pushBind();
    }

    public getPointStyle() {
        return new Style({
            fill: new Fill({
                color: '#33cc33', //'rgba(255, 255, 255, 0.2)',
            }),
            stroke: new Stroke({
                color: '#000000', //'#33cc33',
                width: 3,
            }),
            // text: text,
            image: new CircleStyle({
                radius: 8,
                fill: new Fill({ color: '#33cc33' }),
                stroke: new Stroke({
                    color: '#000000', //'#33cc33',
                    width: 2,
                }),
            }),
        });
    }

    public getPointStyleSource() {
        return new Style({
            fill: new Fill({
                color: '#33cc33', //'rgba(255, 255, 255, 0.2)',
            }),
            stroke: new Stroke({
                color: '#ffa600', //'#33cc33',
                width: 2,
            }),
            // text: text,
            image: new CircleStyle({
                radius: 8,
                fill: new Fill({ color: '#ffa600' }),
                stroke: new Stroke({
                    color: '#000000', //'#33cc33',
                    width: 2,
                }),
            }),
        });
    }

    public onClickMap(event: MouseEvent) {
        console.log('serv onClickMap', event);
        if (this.selectMode.value == null) return;

        let map = this.mapService.getMap();

        // let coordinate = toLonLat(event.coordinate);
        let coords = map.getEventCoordinate(event)
        let coordinate = toLonLat(coords);

        let pixel = //event.pixel
            map.getEventPixel({
                clientX: event.clientX,
                clientY: event.clientY,
            });

        console.log('onClickMap',this.imageExtent.value)
        let startPixel = this.imageExtent.value.slice(0,2)
        // let endPixel = map.getPixelFromCoordinate(this.imageExtent.value.slice(-2))

        let newCoords = coords
        newCoords[0] -= startPixel[0]
        newCoords[1] -= startPixel[1]

        if (this.selectMode.value == 'source') {
            this.updateSource({
                sourceX: pixel[0], //Math.floor(pixel[0]-startPixel[0]),
                sourceY: pixel[1], //Math.floor(-pixel[1]+startPixel[1]),
                // sourceX: Math.floor(pixel[0]-startPixel[0]),
                // sourceY: Math.floor(-pixel[1]+startPixel[1]),
                // sourceX: newCoords[0],
                // sourceY: newCoords[1],
                sourceLat: coordinate[0],
                sourceLon: coordinate[1],
            });
        } else if (this.selectMode.value == 'map') {
            this.updateMap({
                mapX: pixel[0],
                mapY: pixel[1],
                mapLat: coordinate[0],//toLonLat(newCoords)[0],
                mapLon: coordinate[1],//toLonLat(newCoords)[1],
            });
        }
    }

    updateSource(newSource: SourceXY) {
        console.log('updateSource', newSource);

        this.bindSelected.value.sourceX = newSource.sourceX;
        this.bindSelected.value.sourceY = newSource.sourceY;

        this.bindSelected.value.sourceLat = newSource.sourceLat;
        this.bindSelected.value.sourceLon = newSource.sourceLon;

        // this.bindList.next({...this.bindList[this.bindSelected.value], ...newSource});
        this.changed$.emit();
    }

    setBindMode(mode: string) {
        console.log('setBindMode', mode);

        if (mode == 'source') {
            this.toastr.info('Режим выбора точки на карте', 'Растр', {
                closeButton: true,
                // disableTimeOut: true,
                positionClass: 'toast-bottom-left-margin',
            });
        } else if (mode == 'map') {
            this.toastr.info('Режим выбора точки на карте', 'Карта', {
                closeButton: true,
                // disableTimeOut: true,
                positionClass: 'toast-bottom-left-margin',
            });
        }

        this.selectMode.next(mode);
    }

    deleteBind(row: SourceXY) {
        this.bindList.next(
            this.bindList.value.filter((item) => item.name != row.name)
        );
        this.changed$.emit();
    }

    updateMap(newMap: SourceXY) {
        this.bindSelected.value.mapLat = newMap.mapLat;
        this.bindSelected.value.mapLon = newMap.mapLon;
        this.bindSelected.value.mapX = newMap.mapX;
        this.bindSelected.value.mapY = newMap.mapY;

        // this.bindList.next({...this.bindList[this.bindSelected.value], ...newSource});
        this.changed$.emit();
    }

    loadImage(url: string) {
        var request = new XMLHttpRequest();
        request.open('GET', url, true);
        request.responseType = 'blob';
        request.onload = () => {
            this.readImage(request);
        };
        request.send();

        // return of()
    }

    readImage(request: XMLHttpRequest) {
        let imageData: RasterImageData;
        const imgElement = document.createElement('img');
        imgElement.style.display = 'none';

        var reader = new FileReader();
        reader.readAsDataURL(request.response);

        reader.onload = (e: ProgressEvent<FileReader>) => {
            console.log('DataURL:', e.target.result);

            const img = new window.Image();
            img.onload = () => {
                imageData = {
                    w: img.width,
                    h: img.height,
                    src: img.src,
                };

                console.log('img.onload', imageData);
                this.rastr.next(imageData);
                // observer.next(imageData);
                // observer.complete();
            };
            // const result = (<any>event.target).result;
            img.src = reader.result as string;

            imgElement.src = reader.result as string;
            // return this.readImage(new Blob([e.target.result])).subscribe();
            // return this.readImage(new Blob([e.target.result])).subscribe();

            console.log('imgElement', imgElement);

            document.body.appendChild(imgElement);

            // var pixelProjection = new Projection({
            //     code: "pixel",
            //     units: "pixels",
            //     extent: [-100000, -100000, 100000, 100000],
            // });

            // this.newMap = new Map({
            //     target: "img",
            //     view: new View({
            //         projection: pixelProjection,
            //         zoom: 7,
            //         center: [0, 0],
            //     }),
            //     interactions: defaults({
            //         altShiftDragRotate: false,
            //         pinchRotate: false,
            //     }),
            // })

            // this.imageImg = new Img('x', reader.result as string, this.newMap, this.mapService.getMap())
            // console.log('imageImg',this.imageImg)

            // this.imgInit(
            //     'x',
            //     reader.result as string,
            //     this.mapService.getMap(),
            //     this.mapService.getMap()
            // );
        };
    }

    setExtentFirst() {
        console.log('setExtentFirst');

        const map = this.mapService.getMap();

        let centerTemp = fromLonLat(
            transform(map.getView().getCenter(), 'EPSG:3857', 'EPSG:4326')
        );

        console.log('centerTemp', centerTemp);
        this.imageExtent.next([
            centerTemp[0],
            centerTemp[1],
            centerTemp[0] + this.rastr.getValue().w,
            centerTemp[1] + this.rastr.getValue().h,
        ]);
    }

    extentCalc() {
        // console.log('extentCalc');

        this.selectMode.next(null);

        const map = this.mapService.getMap();
        const bindListResult = this.bindList.getValue();

        // console.log('bindListResult', bindListResult);
        let firstLink = bindListResult[0]
        let secondLink = bindListResult[1]
        
        let sourcePoint1 = map.getPixelFromCoordinate(fromLonLat([firstLink.sourceLat, firstLink.sourceLon]))
        let sourcePoint2 = map.getPixelFromCoordinate(fromLonLat([secondLink.sourceLat, secondLink.sourceLon]))
        let mapPoint1 = map.getPixelFromCoordinate(fromLonLat([firstLink.mapLat, firstLink.mapLon]))
        let mapPoint2 = map.getPixelFromCoordinate(fromLonLat([secondLink.mapLat, secondLink.mapLon]))

        let startImageCenter = map.getPixelFromCoordinate([(this.imageExtent.value[0]+this.imageExtent.value[2])/2,(this.imageExtent.value[1]+this.imageExtent.value[3])/2])

        // console.log('sourcePoint1',sourcePoint1)
        // console.log('sourcePoint2',sourcePoint2)
        // console.log('startImageCenter',startImageCenter)
        // console.log('imageExtent',this.imageExtent.value)
        
        let image: GeoImageSource = {}
        image.imageRotation = Math.atan((sourcePoint1[0]-sourcePoint2[0])/(sourcePoint1[1]-sourcePoint2[1]))-
            Math.atan((mapPoint1[0]-mapPoint2[0])/(mapPoint1[1]-mapPoint2[1]));
        
        if (mapPoint1[1] > mapPoint2[1]) image.imageRotation += Math.PI
        // this.imageExtent[2] - this.imageExtent[0] < 0

        // let _tempScale = Math.hypot((firstPoint.mapX-secondPoint.mapX),(firstPoint.mapY-secondPoint.mapY))/
        // Math.hypot((firstPoint.sourceX-secondPoint.sourceX),(firstPoint.sourceY-secondPoint.sourceY))
        let _tempScale = Math.hypot((mapPoint1[0]-mapPoint2[0]),(mapPoint1[1]-mapPoint2[1]))/
            Math.hypot((sourcePoint1[0]-sourcePoint2[0]),(sourcePoint1[1]-sourcePoint2[1]))
        // let _tempScale = this.distanceBetweenPoints(mapPoint1,mapPoint2) / 
        //                  this.distanceBetweenPoints(sourcePoint1,sourcePoint2)

        // image.imageCenter = fromLonLat([(firstLink.mapLat+secondLink.mapLat)/2,(firstLink.mapLon+secondLink.mapLon)/2])

        // finalImageCenter[0] = mapPoint1[0] + (sourcePoint1[0]-startImageCenter[0])*_tempScale*Math.cos(image.imageRotation)
        // finalImageCenter[1] = mapPoint1[1] + (sourcePoint1[1]-startImageCenter[1])*_tempScale*Math.sin(image.imageRotation)
        // let _tempXScale = (mapPoint1[0]-mapPoint2[0]) / (sourcePoint1[0]-sourcePoint2[0])
        // let _tempYScale = (mapPoint1[1]-mapPoint2[1]) / (sourcePoint1[1]-sourcePoint2[1])
        // finalImageCenter[0] = mapPoint1[0] + (startImageCenter[0] - sourcePoint1[0])*_tempXScale*(Math.cos(image.imageRotation))
        // finalImageCenter[1] = mapPoint1[1] - (startImageCenter[1] - sourcePoint1[1])*_tempYScale*(1 + Math.sin(image.imageRotation))

        // Вычисляем вектор и масштабируем
        let x = (startImageCenter[0] - sourcePoint1[0]) * _tempScale
        let y = (startImageCenter[1] - sourcePoint1[1]) * _tempScale
        // Делаем поворот вектора
        let rotatedX = x * Math.cos(image.imageRotation) - y * Math.sin(image.imageRotation)
        let rotatedY = x * Math.sin(image.imageRotation) + y * Math.cos(image.imageRotation)
        // Прибавляем координаты
        rotatedX = rotatedX + mapPoint1[0]
        rotatedY = rotatedY + mapPoint1[1]

        image.imageCenter = map.getCoordinateFromPixel([rotatedX,rotatedY])
        
        image.imageScale = [_tempScale,_tempScale]
                // [(firstPoint.mapLat-secondPoint.mapLat)/(firstPoint.sourceLat-secondPoint.sourceLat), 
                //             (firstPoint.mapLon-secondPoint.mapLon)/(firstPoint.sourceLon-secondPoint.sourceLon)]
        
        // console.log('image',image.imageRotation,image.imageScale, image.imageCenter,)
        // console.log('finalImageCenter',finalImageCenter)

        // let centerTemp = fromLonLat([
        //     bindListResult[0].mapLat,
        //     bindListResult[0].mapLon,
        // ]);

        // let newPixel = map.getPixelFromCoordinate(fromLonLat([bindListResult[0].mapLon,bindListResult[0].mapLat]))
        // newPixel[0] -= bindListResult[0].sourceX
        // newPixel[1] -= bindListResult[0].sourceY
        // let startCoords = fromLonLat(map.getCoordinateFromPixel(newPixel))

        let newPixel = fromLonLat([bindListResult[0].mapLat,bindListResult[0].mapLon])
        newPixel[0] -= bindListResult[0].sourceX
        newPixel[1] -= bindListResult[0].sourceY

        let startCoords = newPixel

        // newPixel = map.getPixelFromCoordinate(fromLonLat([bindListResult[1].mapLon,bindListResult[1].mapLat]))
        // newPixel[0] -= bindListResult[1].sourceX
        // newPixel[1] -= bindListResult[1].sourceY

        // let endCoords = fromLonLat(map.getCoordinateFromPixel(newPixel))

        newPixel = fromLonLat([bindListResult[1].mapLat,bindListResult[1].mapLon])
        newPixel[0] -= bindListResult[1].sourceX
        newPixel[1] -= bindListResult[1].sourceY

        // let endCoords = newPixel

        // let centerTemp = fromLonLat(
        //     transform(
        //         [bindListResult[0].mapLat, bindListResult[0].mapLon],
        //         'EPSG:3857',
        //         'EPSG:4326'
        //     )
        // );


        // this.imageExtentResult.next(null);

        this.imageExtentResult.next([
            startCoords[0],
            startCoords[1],
            // endCoords[0] + this.rastr.getValue().w, // centerTemp[0] + this.rastr.getValue().w / 2,
            // endCoords[1] + this.rastr.getValue().h, // centerTemp[1] + this.rastr.getValue().h / 2,
            startCoords[0] + this.rastr.getValue().w, 
            startCoords[1] + this.rastr.getValue().h, 
        ]);

        this.imageResult$.next(image)

        // this.imageExtentCoords = fromExtent(this.imageExtent.getValue());
    }

    private distanceBetweenPoints (latlng1: Coordinate, latlng2: Coordinate): number {
        var line = new LineString([latlng1, latlng2]);
        return Math.round(line.getLength() * 100) / 100;
    };

    public onImageClick(mess) {
        console.log('onImageClick', mess);
    }

    public extentCornersCoord(extent: Extent) {
        let coordsExtent = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');

        let topLeft = olExtent.getTopLeft(coordsExtent);
        let topRight = olExtent.getTopRight(coordsExtent);
        let bottomLeft = olExtent.getBottomLeft(coordsExtent);
        let bottomRight = olExtent.getBottomRight(coordsExtent);

        const cornersArray: [Coordinate, Coordinate, Coordinate, Coordinate] = [
            topLeft,
            topRight,
            bottomRight,
            bottomLeft,
        ];
        // console.log('extentCornersCoord', cornersArray);
        return cornersArray;

        // const bottomLeft = getBottomLeft(feature.getGeometry().getExtent());
        // const bottomRight = getBottomRight(feature.getGeometry().getExtent());
        // const topLeft = getTopLeft(feature.getGeometry().getExtent());
        // const topRight = getTopRight(feature.getGeometry().getExtent());
        // console.log(`bottomLeft = ${ bottomLeft }, bottomRight = ${ bottomRight }, topLeft = ${ topLeft }, topRight = ${ topRight }`);
    }

    
}

