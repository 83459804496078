// import { NodeCollection } from "./node";
import { LayerTypeIDs, LayerTypes, NewLayerType } from '@shared/models/layer-types';
import { NodeFeature, NodeCollection } from '@shared/models/node';
import { AttachmentModel } from "@shared/attachment";
import { node } from './node-ref';
import { GlobalPlot } from './plot';

export class LayerCollection {
    type: string;
    features: LayerFeature[];
    data: LayerFeature[];
}

export class LayerFeature {
    files: any[]; //TODO проверить типы
    rastr: any[]; //TODO проверить типы
    // points: NodeCollection;
    nodes: NodeCollection;
    type: string;
    properties: Layer;
    // data: any;
    // w: any;
    // h: any;
}

export class GlobalLayer {
    layer_id: number; 
    layer_type_id: number;
    layerType?: LayerType;
    can_read: boolean; 
    // points: NodeCollection;
    nodes: node.NodeGlobalResponse[];
    plots: GlobalPlot[]
}

export interface RightsType{
    301?: "can_read",
    303?: "can_update",
    304?: "can_delete",
    305?: "can_change_level",
    306?: "can_set_global"
}
export class Layer {
    ID: number;
    user_id: number;
    can_rights?: string;
    own_layer?: boolean;
    global?: boolean;
    /* 801, 802 и т.д.*/
    creator_type?: number;
    /* Права объекта*/
    rights?: RightsType;
    name: string;
    color: string;
    layer_type: NewLayerType;
    order: number;
    // data: string | null; 
    data: number[] | null;
    format: string;
    w: number;
    h: number;
    files: AttachmentModel[];
    rastr: AttachmentModel[];
    scale: number[];
    rotate: number;
    center: number[];
    objectLabels?: boolean;
}

export class LayerType {
    id: LayerTypeIDs;
    name: LayerTypes;
}

export interface NewLayerResponse {
    name: string;
    status: string;
    color: string;
    layer_id: number;
    user_id: number;
    order:number;
    /** TODO: Почему стринга?? */
    layer_type_id: number;
}

export interface CopyItemsResponse {
    layer_id: number;
    nodes: number[];
    plots: number[];
}
