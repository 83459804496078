import { Injectable } from '@angular/core';
import { NodeRef, PlotRef } from '@app/shared/models';
import { ColorLike } from 'ol/colorlike';
import { Circle, Fill, Icon, RegularShape, Stroke, Style, Text } from 'ol/style';
import { MapService } from './map.service';
import { LayersStateService } from '@app/shared/services';
import CircleStyle from 'ol/style/Circle';
import Layer from 'ol/layer/Layer';
import {altKeyOnly, altShiftKeysOnly, always, click, never, pointerMove, singleClick} from 'ol/events/condition.js'
import { Projection, fromLonLat, toLonLat, transform } from 'ol/proj';
import { Type } from 'ol/geom/Geometry';
import { Coordinate, createStringXY, toStringXY } from 'ol/coordinate';
import { Geometry, LineString, MultiPolygon, Point, Polygon, GeometryCollection } from 'ol/geom';
import { FeatureLike } from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import MapBrowserEvent from 'ol/MapBrowserEvent';

import { LayerEditService } from '@app/modules/main/modules/layer-edit-panel/services/layer-edit.service';
import { InteractionType } from '../models';
import { MapSearchService } from './mapSearch.service';
import { CopyObjectsService } from "@services/copy-objects.service";

@Injectable({
  providedIn: 'root'
})
export class MapDataService {



constructor(
    private mapService: MapService,
    private layersStateService: LayersStateService,
    private copyState: CopyObjectsService,
    private mapSearchService: MapSearchService,
    // private layerEditService: LayerEditService,
) { }

public getNodeStyle(node: NodeRef, color: ColorLike): Style[] {
    // console.log('setPlotStyle')

    let styles: Style[] = [];
    // const viewRes = this.mapService.map.getView().getResolution() // 0.0001
    let fillColor = (this.copyState.isCopyLayerMode ? this.copyState.selectedNodes.includes(node.id as number) : (node.id == this.layersStateService.getData().selectedNode)) ? '#cc2323': color
    let objectLabels = this.layersStateService.getData().getLayerById(node.layer)?.objectLabels ?? false

    //TODO HIGHLIGHT

    if (this.mapSearchService.highlightedNode.value
        && this.mapSearchService.highlightedNode.value.properties
        && node.id == this.mapSearchService.highlightedNode.value.properties.id) {
            fillColor = '#cc2323'
        }

    const fillBlack = new Fill({color: '#000000'})
    const fillGrey = new Fill({color: '#888888'})
    const fillLayerColor = new Fill({color: fillColor})

    let text = objectLabels
        ? new Text({
            font: '14px Calibri,sans-serif',
            fill: fillBlack,
            stroke: new Stroke({
                color: '#ffffff',
                width: 5,
            }),
            textAlign: 'start',
            offsetY: -14,
            offsetX: -3,
            // backgroundFill: new Fill({
            //         color: 'rgba(255, 255, 255, 1)',
            //     }),
            text: node.name
        })
        : undefined

    // Колодец, точка, потребитель внешняя окружность
    let style1 = new Style({
        // fill: new Fill({
        //   color: color, //'rgba(255, 255, 255, 0.2)',
        // }),
        // stroke: new Stroke({
        //   color: '#000000', //'#33cc33',
        //   width: 2,
        // }),
        text: text,
        image: new CircleStyle({
            radius: 8,
            fill: fillLayerColor,
            stroke: new Stroke({
                color: '#000000', //'#33cc33',
                width: 2,
            }),
        }),
    });

    // Разветвление
    let style11 = new Style({
        text: text,
        image: new CircleStyle({
            radius: 4,
            fill: fillLayerColor,
            stroke: new Stroke({
                color: '#000000', //'#33cc33',
                width: 2,
            }),
        }),
    });

    // Резервуар, водопровод
    let style12 = new Style({
        text: text,
        image: new RegularShape({
              fill: fillLayerColor,
              stroke: new Stroke({
                color: '#000000',
                width: 2,
                }),
              points: 4,
              radius: 10,
              angle: Math.PI / 4,
            }),
    });

    // Колодец - внутренний круг
    let style2 = new Style({
        image: new CircleStyle({
          radius: 4,
          fill: fillBlack,
        }),
      });

    // Точка, потребитель - внутренний круг
    let style3 = new Style({
        image: new CircleStyle({
          radius: 2,
          fill: fillBlack,
        }),
      });

    switch (node.nodeType.id) {
        case 1 : //Колодец, канализация
        case 2 : //Точка, канализация
        case 6 : //Колодец, водопровод
        case 4 : //Потребитель, водопровод
        case 3 : //Источник, водопровод
            styles.push(style1)
            switch (node.nodeType.id) {
                case 1 : //Колодец, канализация
                case 6 : //Колодец, водопровод
                    styles.push(style2)
                    break;
                case 2 : //Точка, канализация
                case 4 : //Потребитель, водопровод
                    styles.push(style3)
                    break;
            }
            break;
        case 5 : //Резервуар, водопровод
            styles.push(style12)
            break;
        case 7 : //Разветвление, водопровод
        case 8 : //Разветвление, канализация
            styles.push(style11)
            break;
    }

    // styles.push(style1)
    // if (node.nodeType.id == 1) styles.push(style2)
    // if (node.nodeType.id == 2) styles.push(style3)

    return  styles;
}

public getPlotStyle(plot: PlotRef, color: ColorLike): Style[] {
    // console.log('setPlotStyle');
    let styles: Style[] = [];
    // const viewRes = this.mapService.map.getView().getResolution() // 0.0001
    let lineColor = this.getPlotColor(plot, color)
    // if (plot.id == this.layersStateService.getData().selectedPlot) {lineColor = '#cc2323'}

    //TODO HIGHLIGHT
    if (this.mapSearchService.highlightedPlot.value && this.mapSearchService.highlightedPlot.value.properties && plot.id == this.mapSearchService.highlightedPlot.value.properties.id) {lineColor = '#cc2323'}

    let objectLabels = this.layersStateService.getData().getLayerById(plot.layer)?.objectLabels ?? false

    const stroke1 = new Stroke({
        color: '#fffff',
        width: 5
    })
    const stroke2 = new Stroke({
        color: lineColor,
        width:  plot.plot_type?.id === 2 ? 5 : 3,
        lineDash: plot.plot_type?.id === 2 ? [7, 12] : null
    });

    // styles.push(new Style ({
    //     stroke: stroke1
    // }))
    styles.push(new Style ({
        stroke: stroke2,
        text: objectLabels ? new Text({
            font: '14px Calibri,sans-serif',
            fill: new Fill({
                color: '#000000',
            }),
            stroke: new Stroke({
                color: '#ffffff',
                width: 5,
            }),
            // textAlign: 'start',
            // offsetY: 14,
            // offsetX: 5,
            // backgroundFill: new Fill({
            //         color: 'rgba(255, 255, 255, 1)',
            //     }),
            text: plot.name,
            placement: 'line',
            textBaseline: 'bottom',
            // overflow: true
        }): undefined,

    }));

    // if (!plot.coords) {
    //     debugger
    // }
    // for (let i = 0 ; i < plot.coords.length-1; i++) {
    //     let start: Coordinate = plot.coords[i]
    //     let end: Coordinate = plot.coords[i+1]
    //     start = transform(start, 'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(), ) //'EPSG:3857'
    //     end = transform(end,  'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(), )

    //     const dx = end[0] - start[0];
    //     const dy = end[1] - start[1];
    //     const delta: Coordinate = [(end[0] + start[0])/2, (end[1] + start[1])/2]
    //     const rotation = Math.atan2(dy, dx);
    //     const koeff = viewRes > 1000 ? 3000: viewRes*3

    //     const p1: Coordinate = [delta[0] - 2*koeff, delta[1] - koeff]
    //     const p2: Coordinate = [delta[0] - 2*koeff, delta[1] + koeff]

    //     let pol = new Polygon([[p1, delta, p2, p1]])
    //     pol.rotate(rotation, delta);
    //     styles.push(new Style({
    //         geometry: pol,
    //         stroke: stroke2,
    //         fill: new Fill({
    //             color: lineColor,
    //         })
    //     }))
    // }

    styles.push(new Style({
        // geometry: pol,
        geometry: //this.arrowGeometry,
            (feature) => {
            // console.log('geometry function', this,feature)
            // let geometry = feature.getGeometry();
            // let geomArray: Geometry[] = [<Geometry>geometry];

            // let pol = new Polygon([[p1, delta, p2, p1]])
            // pol.rotate(rotation, delta);
            // geomArray.push(pol)



            // return  this.arrowGeometry(feature, transform(plot.coordinates[0], 'EPSG:4326', 'EPSG:3857' ), transform(plot.coordinates[plot.coordinates.length-1], 'EPSG:4326', 'EPSG:3857' )) //  this.getArrowGeometry(plot)//new GeometryCollection(geomArray);
            return  this.arrowGeometry(feature, fromLonLat(plot.startNode.coords), fromLonLat(plot.endNode.coords))


        },

        stroke: stroke2,
        fill: new Fill({
            color: lineColor,
        })
    }))

    return  styles;
}

arrowLines (coords: Coordinate[]): Coordinate[][][] {

    let resultCoords: Coordinate[][][] = [];
    const viewRes = this.mapService.map.getView().getResolution()/35 // 0.0001

    // let lineColor = '#' + color
    // if (plot.id == this.layersStateService.getData().selectedPlot) {lineColor = '#cc2323'}

    // const stroke1 = new Stroke({
    //     color: '#fffff',
    //     width: 5
    // })
    // const stroke2 = new Stroke({
    //     color: lineColor,
    //     width: 3
    // });

    // styles.push(new Style ({
    //     stroke: stroke1
    // }))
    // styles.push(new Style ({
    //     stroke: stroke2
    // }));

    for (let i = 0 ; i < coords.length-1; i++) {
        let start: Coordinate = coords[i]
        let end: Coordinate = coords[i+1]
        start = transform(start, 'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(), ) //'EPSG:3857'
        end = transform(end,  'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(), )

        const dx = end[0] - start[0];
        const dy = end[1] - start[1];
        const delta: Coordinate = [(end[0] + start[0])/2, (end[1] + start[1])/2]
        const rotation = Math.atan2(dy, dx);

        const p1: Coordinate = [delta[0] - 200*viewRes, delta[1] - 100*viewRes]
        const p2: Coordinate = [delta[0] - 200*viewRes, delta[1] + 100*viewRes]

        let pol = new Polygon([[p1, delta, p2, p1]])
        pol.rotate(rotation, delta);
        resultCoords.push(pol.getCoordinates())
    }

    return  resultCoords;

}

arrowStyleFunction = function (feature: FeatureLike, viewRes: number): Style|Array<Style>|void {
    let geometry = feature.getGeometry();
    let styles: Style[] = [];
    let color = feature.getProperties()['color']

    // console.log('arrowStyleFunction',viewRes,(<LineString>geometry).getCoordinates()[0])

    // let stroke1 = new Stroke({
    //     color: '#fffff',
    //     width: 5
    // })

    let stroke2 = new Stroke({
        color: color,
        width: 3
    });

    // styles.push(new Style ({
    //     stroke: stroke1
    // }))
    styles.push(new Style ({
        stroke: stroke2
    }));


    let coords = (<LineString>geometry).getCoordinates()
    for (let i = 0 ; i < coords.length-1; i++) {
        const start: Coordinate = coords[i]
        const end: Coordinate = coords[i+1]
        const dx = end[0] - start[0];
        const dy = end[1] - start[1];
        const delta: Coordinate = [(end[0] + start[0])/2, (end[1] + start[1])/2]
        const rotation = Math.atan2(dy, dx);
        const koeff = viewRes > 1000 ? 3000: viewRes*3

        const p1: Coordinate = [delta[0] - 2*koeff, delta[1] - koeff]
        const p2: Coordinate = [delta[0] - 2*koeff, delta[1] + koeff]

        let pol = new Polygon([[p1, delta, p2, p1]])
        pol.rotate(rotation, delta);
        styles.push(new Style({
            geometry: pol,
            stroke: stroke2,
            fill: new Fill({
                color: color
            })
        }))

    }

    // (<LineString>geometry).forEachSegment(function (start, end) {
    //     });

    return  styles;
}

getArrowGeometry(plot: PlotRef): Geometry {
    let multyPolygon: MultiPolygon
    // let geomArray: Geometry[] = []
    const viewRes = this.mapService.map.getView().getResolution()/35 // 0.0001

    for (let i = 0 ; i < plot.coordinates.length-1; i++) {
        let start: Coordinate = plot.coordinates[i]
        let end: Coordinate = plot.coordinates[i+1]
        start = transform(start, 'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(), ) //'EPSG:3857'
        end = transform(end,  'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(), )

        const dx = end[0] - start[0];
        const dy = end[1] - start[1];
        const delta: Coordinate = [(end[0] + start[0])/2, (end[1] + start[1])/2]
        const rotation = Math.atan2(dy, dx);

        const p1: Coordinate = [delta[0] - 200*viewRes, delta[1] - 100*viewRes]
        const p2: Coordinate = [delta[0] - 200*viewRes, delta[1] + 100*viewRes]

        let pol = new Polygon([[p1, delta, p2, p1]])
        pol.rotate(rotation, delta);
        if (multyPolygon) multyPolygon.appendPolygon(pol)
        else multyPolygon = new MultiPolygon([pol])
        // geomArray.push(pol)
    }

    return multyPolygon
}

getPlotStroke(plot: PlotRef, color: ColorLike): Stroke {
    let lineColor = '#' + color
    if (plot.id == this.layersStateService.getData().selectedPlot) {lineColor = '#cc2323'}
    return new Stroke({
        color: lineColor,
        width: 5
    })
}

getPlotFill(plot: PlotRef, color: ColorLike): Fill {
    let lineColor = '#' + color
    if (plot.id == this.layersStateService.getData().selectedPlot) {lineColor = '#cc2323'}
    return new Fill({
        color: lineColor
    })
}

getPlotColor (plot: PlotRef, color: ColorLike): ColorLike {
    let lineColor = color
    if (this.copyState.isCopyLayerMode ? this.copyState.selectedPlots.includes(plot.id as number) : plot.id == this.layersStateService.getData().selectedPlot) {lineColor = '#cc2323'}
    return lineColor

}
/**
 * Рисует стрелки на линиях
 * @param feature
 * @returns
 */
arrowGeometry (feature: FeatureLike, plotStartCoords: Coordinate, plotEndCoords: Coordinate): GeometryCollection {

    let lineString = feature.getGeometry() as LineString;
    let geomArray: Geometry[] = [];// = [<Geometry>geometry];
    const viewRes = this.mapService.map.getView().getResolution()

    let coords = lineString.getCoordinates();
    if (
        coords[coords.length-1][0] == plotStartCoords[0]
        && coords[coords.length-1][1] == plotStartCoords[1]
        && coords[0][0] == plotEndCoords[0]
        && coords[0][1] == plotEndCoords[1]
        ) {
        // console.log('reverse')
        coords.reverse()
        lineString.setCoordinates(coords)
    }
    for (let i = 0 ; i < coords.length-1; i++) {
        const start: Coordinate = coords[i]
        const end: Coordinate = coords[i+1]
        const dx = end[0] - start[0];
        const dy = end[1] - start[1];
        const delta: Coordinate = [(end[0] + start[0])/2, (end[1] + start[1])/2]
        const rotation = Math.atan2(dy, dx);
        const koeff = viewRes > 1000 ? 3000: viewRes*3

        const p1: Coordinate = [delta[0] - 2*koeff, delta[1] - koeff]
        const p2: Coordinate = [delta[0] - 2*koeff, delta[1] + koeff]

        let pol = new Polygon([[p1, delta, p2, p1]])
        pol.rotate(rotation, delta);
        geomArray.push(pol)

    }

    // let styles = feature
    return  new GeometryCollection(geomArray);
}

getPlotWithArrowsGeometry(plot: PlotRef): GeometryCollection {
    let geomArray: Geometry[] = [];

    let multyPolygon: MultiPolygon
    // let geomArray: Geometry[] = []
    const viewRes = this.mapService.map.getView().getResolution()/35 // 0.0001

    for (let i = 0 ; i < plot.coordinates.length-1; i++) {
        let start: Coordinate = plot.coordinates[i]
        let end: Coordinate = plot.coordinates[i+1]
        start = transform(start, 'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(), ) //'EPSG:3857'
        end = transform(end,  'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(), )

        const dx = end[0] - start[0];
        const dy = end[1] - start[1];
        const delta: Coordinate = [(end[0] + start[0])/2, (end[1] + start[1])/2]
        const rotation = Math.atan2(dy, dx);

        const p1: Coordinate = [delta[0] - 200*viewRes, delta[1] - 100*viewRes]
        const p2: Coordinate = [delta[0] - 200*viewRes, delta[1] + 100*viewRes]

        let pol = new Polygon([[p1, delta, p2, p1]])
        pol.rotate(rotation, delta);
        if (multyPolygon) multyPolygon.appendPolygon(pol)
        else multyPolygon = new MultiPolygon([pol])
        // geomArray.push(pol)
    }

    geomArray.push(new LineString(
        plot.coordinates.map(
            coords => transform(<Coordinate>coords, 'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(), )
            )));
    geomArray.push(multyPolygon);

    // (<LineString>geometry).forEachSegment(function (start, end) {
    //     let tempLine = new LineString([start, end]);
    //     let delta = tempLine.getCoordinateAt(0.5)
    //     let len = tempLine.getLength()
    //     let koeff = len /400
    //     var dx = end[0] - start[0];
    //     var dy = end[1] - start[1];
    //     // let delta: Coordinate = [(end[0] + start[0])/2, (end[1] + start[1])/2]
    //     var rotation = Math.atan2(dy, dx);

    //     // let len = Math.sqrt((dx.) ** 2 + )

    //     const p1 = [delta[0] - 20*koeff, delta[1] - 10*koeff]
    //     const p2 = [delta[0] - 20*koeff, delta[1] + 10*koeff]


    //     // var lineStr = new LineString([p1, end, p2]);
    //     // lineStr.rotate(rotation, end);
    //     // geomArray.push(lineStr)


    //     // var lineStr1 = new LineString([end, [end[0] - 200, end[1] + 200]]);
    //     // lineStr1.rotate(rotation, end);
    //     // var lineStr2 = new LineString([end, [end[0] - 200, end[1] - 200]]);
    //     // lineStr2.rotate(rotation, end);
    //     // geomArray.push(lineStr1)
    //     // geomArray.push(lineStr2)

    //     // let coords: Coordinate = [dx,dy]
    //     // let point = new Point(coords)
    //     // geomArray.push(point)

    //     let pol = new Polygon([[p1, delta, p2, p1]])
    //     pol.rotate(rotation, delta);
    //     geomArray.push(pol)

    // });
    return  new GeometryCollection(geomArray);
}



}

// export enum InteractionType {
//     select = 1,
//     /** Применяет Select ко всем слоям */
//     selectAll = 2,
//     draw = 3,
//     modify = 4,
//     snap = 5
// }
