import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input, OnChanges,
    OnInit,
    Output, SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {Observable, takeUntil} from "rxjs";

import {ImusDestroyService} from "@services/destroy.service";
import {Accounts} from "@shared/models/contractors";
import {SysRoles} from "@shared/models/menu";
import {MenuService} from "@services/menu.service";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {SharedModule} from "@shared/shared.module";
import {AlertService, UserService} from "@shared/services";
import {ButtonComponentSettings} from "@shared/components/button/button.component";
import {UserAccountsInfo} from "@shared/models";

@Component({
    selector: 'main-account',
    standalone: true,
    imports: [CommonModule, MatFormFieldModule, MatOptionModule, MatSelectModule, ReactiveFormsModule, MatInputModule, SharedModule],
    templateUrl: './main-account.component.html',
    styleUrls: ['./main-account.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class MainAccountComponent implements  OnInit, OnChanges {
    private destroy$ = inject(ImusDestroyService);
    private menuService = inject(MenuService);
    private userService = inject(UserService);
    private fb = inject(FormBuilder);
    private alertService = inject(AlertService);

    public readonly buttonType = ButtonComponentSettings.ButtonType;
    _accounts: Accounts;

    @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>()

    @Input() userAccountsInfo: UserAccountsInfo;
    @Input() userId: string;
    @Input() set accounts(accounts: Accounts) {
        this._accounts = accounts;
        this.setData();
    };

    get accounts() {
        return this._accounts;
    };

    sysRolesList$: Observable<SysRoles[]>;
    form: FormGroup;
    signer = [
        { title: 'Доверенности', value: true },
        { title: 'Устава', value: false },
    ]

    ngOnChanges(changes: SimpleChanges) {
        if (changes['userAccountsInfo']?.currentValue && !changes['userAccountsInfo']?.previousValue) {
            // console.log('ngOnChanges', changes['userAccountsInfo'])
            this.sysRolesList$ = this.menuService
                .getRolesList({
                    'where[account_type_id]': this.userAccountsInfo?.account_type_id,
                    paginate: false
                })
                // .pipe(takeUntil(this.destroy$))
        }
    }

    ngOnInit() {
        this.initForm();
    }

    submit() {
        if (this.form.invalid) {
            return;
        }

        this.userService.accountSave(this.form.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.alertService.openSnackbar('Данные обновлены!')
                this.changed.next(true);
            })
    }

    initForm() {
        this.form = this.fb.group({
            account_role_id: [null, Validators.required],
            account_detail_job_post: [null],
            account_start_date: [null],
            account_end_date: [null],
            account_is_primary: [null],
            account_detail_is_signer: [false],
            account_detail_is_proxy: [null],
            account_detail_proxy_doc_number: [null],
            account_detail_proxy_doc_date: [null],
            account_detail_job_post_gen: [null],
            account_id: [null],
        });
    }

    setData() {
        if (!this.accounts || !this.form) {
            return;
        }

        // console.log('setData this.accounts', this.accounts)
        this.form.patchValue({
            account_role_id: this.accounts.role_id,
            account_detail_job_post: this.accounts.job_post,
            account_start_date: new Date(this.accounts.start_date),
            account_end_date : this.accounts.end_date ? new Date(this.accounts.end_date) : null,
            account_is_primary: this.accounts.is_primary,
            account_detail_is_signer: this.accounts.is_signer ? this.accounts.is_signer : false,
            account_detail_is_proxy: this.accounts.is_proxy ? this.accounts.is_proxy : false,
            account_detail_proxy_doc_number: this.accounts.proxy_doc_number,
            account_detail_proxy_doc_date : this.accounts.proxy_doc_date ? new Date(this.accounts.proxy_doc_date) : null,
            account_detail_job_post_gen: this.accounts.job_post_gen,
            account_id: this.accounts.id,
        })
    }
}
