import {
    ChangeDetectorRef,
    Component,
    ViewChild,
    ChangeDetectionStrategy,
    OnInit,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { ButtonComponentSettings } from '@app/shared/components/button/button.component';
import { PaginatorComponent } from '@app/shared/components/paginator/paginator.component';
import { ProjectTypes } from '@app/shared/models/project';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { ProjectService } from '@app/shared/services/project.service';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { ProjectSectionsDialogComponent } from '../project-sections-dialog/project-sections-dialog.component';
import { MainPageService, PageServiceTypes } from '@app/modules/main/services/main-page.service';
import { PlotService } from '../../../shared/services/plot.service';
import { PlotFeature, PlotRef } from '@app/shared/models';
import { MapService } from '../../map/services/map.service';
import { TooltipComponent } from '@angular/material/tooltip';
import { LayersStateService } from '@app/shared/services';
import { EntityType } from '@app/shared/models/entity-type';

@Component({
    selector: 'app-projects-grid-dialog',
    templateUrl: './projects-grid-dialog.component.html',
    styleUrls: ['./projects-grid-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ImusDestroyService]
})
export class ProjectsGridDialogComponent implements OnInit{
    buttonType = ButtonComponentSettings.ButtonType;
    pageIndex = 0;
    pageSize = 5;
    pageSizeOptions = [5, 10, 25];
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
    @ViewChild(MatSort) sort: MatSort;

    loadingProjects$ = this.projectService.loadingProjects$;
    projects: ProjectTypes.IProjectListResponse = null;

    projectList$ = new BehaviorSubject<ProjectTypes.IProjectResponseItem[]>([]);

    displayedColumns: string[] = [
        'name',
        'controlInfo',
        'controlLocation',
        'client',
        'contractor',
        'updated_at',
        'created_at',
        'controlEdit',
        'controlRemove',
    ];

    private dialog;
    private deleteDialogOpened = false;

    get ifDialogOpeningAllowed() {
        return this.dialog === undefined || this.dialog.closed  && !this.deleteDialogOpened;
    }

    constructor(
        private projectService: ProjectService,
        private readonly destroy$: ImusDestroyService,
        private matDialog: MatDialog,
        private dialogRef: MatDialogRef<ProjectsGridDialogComponent>,
        private changeDetector: ChangeDetectorRef,
        private mainPageService: MainPageService,
        private plotService: PlotService,
        private mapService: MapService,
        private layersStateService: LayersStateService
    ) {}

    ngOnInit(): void {
        this.init();
        this.initData();
    }

    private init() {
        this.projectService.projects$
            .pipe(takeUntil(this.destroy$))
            .subscribe((inspections) => {
                this.projects = inspections;
                this.sortingPagingProjects();
            });
    }

    private initData() {
        this.projectService.loadProjects();
    }

    public refreshData() {
        this.initData();
    }

    sortingPagingProjects() {
        if (this.paginator && this.sort && this.projects) {
            let gridData =
                this.sort.direction == 'asc'
                    ? this.projects.features.sort((a, b) =>
                          a.properties[this.sort.active] <
                          b.properties[this.sort.active]
                              ? -1
                              : 1
                      )
                    : this.projects.features.sort((a, b) =>
                          a.properties[this.sort.active] >
                          b.properties[this.sort.active]
                              ? -1
                              : 1
                      );
            const startPoint =
                this.paginator.pageIndex * this.paginator.pageSize;
            gridData = gridData.slice(
                startPoint,
                startPoint + this.paginator.pageSize
            );
            this.projectList$.next(gridData);
        }
    }

    onPageEvent() {
        this.sortingPagingProjects();
    }

    onSectionsDialogClick(project: ProjectTypes.IProjectResponseItem) {
        this.dialog = this.matDialog
            .open(ProjectSectionsDialogComponent, {
                data: { project: project },
            })
            .afterClosed()
            .subscribe(() => {
                this.refreshData();
                this.changeDetector.markForCheck();
            });
    }

    isLocationClickAllowed(item) {
        return (
            item.sections &&
            item.sections.Sections[0] &&
            item.sections.Sections[0].properties &&
            item.sections.Sections[0].properties.plot_id
        );
    }

    onLocationClick(project?: ProjectTypes.IProjectResponseItem) {
        if (project.sections.Sections[0].properties.plot_id) {
            this.plotService
                .getById(project.sections.Sections[0].properties.plot_id)
                .subscribe((plot: PlotFeature) => {
                    console.log('plot', plot);
                    if (
                        plot &&
                        plot.properties 
                        // TODO Нужны условия coords
                        // plot.properties.coords &&
                        // plot.properties.coords[0]
                    ) {
                        this.mapService.zoomToPlot(plot);                           
                        if(!this.layersStateService.state.getPlotByPlotId(plot.properties.id))
                            this.layersStateService.state.setPlotToLayer(new PlotRef(plot.properties));
                        this.layersStateService.state.selectedEntity = {id: plot.properties.id, type: EntityType.PLOT };                                                
                        this.mainPageService.setActivePanel(PageServiceTypes.PanelType.nodesEditPanel);
                    }
                });
            this.matDialog.closeAll();
        }
    }

    onRemoveClick(project: ProjectTypes.IProjectResponseItem){
        this.deleteDialogOpened = true;
        this.changeDetector.markForCheck();
        this.projectService.deleteProject(project).subscribe((result) => {
            if (result !== undefined) {
                this.deleteDialogOpened = false;
                if(result === true)
                    this.refreshData();
                this.changeDetector.markForCheck();
            }
        });   
    }

    onEditProjectClick(project: ProjectTypes.IProjectResponseItem) {        
        this.projectService.editableProject = project;
        this.projectService.gridDialogRolledUp = true;
        this.dialogRef.close();
        this.matDialog.closeAll();
    }

    @ViewChild('tooltipLocation') public tooltipLocation: TooltipComponent | any;
    @ViewChild('tooltipInfo') public tooltipInfo: TooltipComponent | any;
    @ViewChild('tooltipEdit') public tooltipEdit: TooltipComponent | any;
    @ViewChild('tooltipRemove') public tooltipRemove: TooltipComponent | any;

    public helpActive: boolean = false;
    tooltipsShowAll() {
        this.helpActive = true;
        this.tooltipInfo.show();
        this.tooltipLocation.show(400);
        this.tooltipEdit.show(1000);
        // this.tooltipRemove.show(800);
        setTimeout(() => {
            this.tooltipInfo.hide();
            this.tooltipLocation.hide(100);
            this.tooltipEdit.hide(200);
            // this.tooltipRemove.hide(300);
            this.helpActive = false;
        }, 3000);
    }

}
