import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";

export namespace ButtonComponentSettings {
    export enum ButtonType {
        blueButton,
        deepBlueButton,
        icon,
        basic,
        primaryButton
    }
}

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ButtonComponent {

    @Input()
    type: ButtonComponentSettings.ButtonType = ButtonComponentSettings.ButtonType.blueButton;

    @Input()
    isSubmitted = false;
    /**
     * Добавлен ng-content внутрь тега button
     * @deprecated     */
    @Input()
    label: string;

    @Input()
    icon: string;

    @Input()
    disabled: boolean = false;

    @Input()
    fullWidth: boolean = false;

    @Input()
    large: boolean = false;

    @Input()
    active: boolean = false;

    @Input()
    primary: boolean = false;

    @Input()
    color: string = "#ffffff";

    @Output()
    onclick: EventEmitter<PointerEvent> = new EventEmitter();

    get basicColor() {
        return {
            // color: this.color
        }
    }

    buttonType = ButtonComponentSettings.ButtonType;

    public onButtonClick(event: PointerEvent): void {
        this.onclick.emit(event);
    }
}
