import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './modules/material/material.module';
import { AlertComponent } from './components/alert/alert.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageAboutComponent } from './pages/page-about/page-about.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PageAccessDeniedComponent } from './pages/page-access-denied/page-access-denied.component';
import { PageHelloComponent } from './pages/page-hello/page-hello.component';

import {
  DeviceService,
  MeasurementService,
  ReportService,
  AzimuthService
} from './services';
import { InputComponent } from './components/input/input.component';
import { ButtonComponent } from './components/button/button.component';
import { ImusFileInputComponent } from '@shared/components/imus-file-input/imus-file-input.component';
import { SwitchEditorComponent } from './components/switch-editor/switch-editor.component';
import { ImusSelectComponent } from './components/imus-select/imus-select.component';
import { IMaskDirective } from 'angular-imask';
import { ImusDestroyService } from './services/destroy.service';

import {
    FormFieldErrorLabelComponent
} from "@shared/components/form-field-error-label/form-field-error-label.component";
import {
    FormFieldErrorContainerDirective
} from "@shared/components/form-field-error-container/form-field-error-container.directive";
import { ImusRequiredValidatorDirective } from "@shared/validators/imus-required-validator.directive";
import { ImusEmailValidatorDirective } from "@shared/validators/imus-email-validator.directive";

import { TextSeparatorComponent } from './components/text-separator/text-separator.component';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { TextAreaComponent } from './components/text-area/text-area.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ImusInputComponent } from './components/imus-input/imus-input.component';
import { PageEmailConfirmationComponent } from './pages/page-email-confirmations/page-email-confirmations.component';
import { PageChangePasswordComponent } from './pages/page-change-password/page-change-password.component';
import { PageVerifyRegistrationComponent } from './pages/page-verify-registration/page-verify-registration.component';
import { ObservationEventToEditDirective } from './directives/observation-event-to-edit.directive';
import { VPlayerComponent } from './components/v-player/v-player.component';
import { PopupVPlayerComponent } from './components/popup-v-player/popup-v-player.component';
import { SortByLayerTypePipe } from './pipes/sort-by-layer-type.pipe';

const VALIDATORS: any[] = [
    ImusRequiredValidatorDirective,
    ImusEmailValidatorDirective
]

@NgModule({
  declarations: [
    AlertComponent,
    PageAboutComponent,
    PageNotFoundComponent,
    PageAccessDeniedComponent,
    PageHelloComponent,
    PageEmailConfirmationComponent,
    PageChangePasswordComponent,
    InputComponent,
    ImusFileInputComponent,
    ButtonComponent,
    PaginatorComponent,
    SwitchEditorComponent,
    ImusSelectComponent,
    SpinnerComponent,
    ConfirmDialogComponent,

    FormFieldErrorLabelComponent,
    FormFieldErrorContainerDirective,
    ...VALIDATORS,

    TextSeparatorComponent,
    SelectSearchComponent,
    TextAreaComponent,
    ImusInputComponent,
    PageVerifyRegistrationComponent,
    ObservationEventToEditDirective,
    VPlayerComponent,
    PopupVPlayerComponent,
    SortByLayerTypePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    // FlexLayoutModule,
    IMaskDirective
  ],
	exports: [
		MaterialModule,
		// FlexLayoutModule,
		AlertComponent,
		InputComponent,
		ButtonComponent,
		PaginatorComponent,
		ImusFileInputComponent,
		SwitchEditorComponent,
		ImusSelectComponent,
		SpinnerComponent,
		ConfirmDialogComponent,

		FormFieldErrorLabelComponent,
		FormFieldErrorContainerDirective,
		...VALIDATORS,

		TextSeparatorComponent,
		SelectSearchComponent,
		TextAreaComponent,
		ImusInputComponent,
		ObservationEventToEditDirective,
		SortByLayerTypePipe
	],
  // entryComponents: [
  //   InputComponent,
  //   ButtonComponent,
  //   TextSeparatorComponent,
  //   SelectSearchComponent,
  //   TextAreaComponent
  // ],
  providers: [
    // AuthenticationService,
    //  CookieService,
    // LayerService,
    DeviceService,
    MeasurementService,
    // NodeService,
    // FileService,
    ReportService,
    AzimuthService,
    ImusDestroyService,
  ],
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule { }
