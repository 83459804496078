import {EventEmitter, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Layer, LayerCollection, NewLayerResponse, LayerFeature, CopyItemsResponse, GlobalLayer } from '@app/shared/models';
import { Observable, tap, shareReplay, map, takeUntil, filter, switchMap, of } from "rxjs";
import { LayerTypeIDs, LayerTypes, LayerTypesCollection, NewLayerType } from '@shared/models/layer-types';
import { ImusDestroyService } from './destroy.service';
import { Feature } from 'ol';
// import { UserService, AuthenticationService } from '@app/_services';

@Injectable({
    providedIn: 'root'
  })
export class LayerService {

    public readonly changed$ = new EventEmitter<void>();
    public readonly changedLayer$ = new EventEmitter<number>();
    public readonly updatedLayer$ = new EventEmitter<LayerFeature>();
    public readonly allLayerTypes$: Observable<Map<number, NewLayerType>> = this.getLayerTypes().pipe(
        // map(collection => collection.layer_types.reduce((layerTypesMap: Map<number, NewLayerType>, layerType) => layerTypesMap.set(layerType.id, layerType), new Map())),
        map(layerTypes => layerTypes.reduce((layerTypesMap: Map<number, NewLayerType>, layerType) => layerTypesMap.set(layerType.id, layerType), new Map())),
        shareReplay(1)
    );
    public readonly allGlobalLayers$ = this.getAllGlobalLayers().pipe(
        tap(layers => layers.forEach(layer => layer.layerType = this.getNewLayerTypeById(layer.layer_type_id))),
        shareReplay(1)
    )
    private _allLayerTypes: Map<number, NewLayerType>

    constructor(
        // private authenticationService: AuthenticationService,
        private http: HttpClient,
        private readonly destroy$: ImusDestroyService

    ) {
        this.allLayerTypes$.pipe(
            tap(value => this._allLayerTypes = value),
            takeUntil(this.destroy$)
        )
        .subscribe()
    }

    public updateLayerInfo(id: number) {
        this.changedLayer$.emit(id);
    }

    public updateAllLayersInfo() {
        this.changed$.emit();
    }

    /**
     * Возвращает объект NewLayerType по id типа слоя
     * @param id id типа слоя
     * @returns объект типа слоя
     */
    public getNewLayerTypeById(id: number): NewLayerType {
        return this._allLayerTypes.get(id)
    }

    /**
     * Возвращает объект NewLayerType по полю name типа слоя и по наличию родителя
     * @param name поле name типа слоя
     * @param hasParent имеет родительский тип слоя, по умолчанию true
     * @returns объект типа слоя
     */
    public getNewLayerTypeByName(name: string, hasParent: boolean = true): NewLayerType {
        return Array.from(this._allLayerTypes)
        .find(value => value[1].name == name && !!value[1].parent_id == hasParent)[1]
    }

    /**
     * Получить все слои
     * @returns LayerCollection
     */
    getAll() {
        return this.http.get<LayerCollection>(`${environment.apiUrl}/layer/`)
        .pipe(
            switchMap(collection => {
                const newColl = new LayerCollection
                newColl.data = collection.data
                newColl.type = collection.type
                newColl.features = collection.features.filter(feature => feature.properties.layer_type?.parent_id)
                return of(newColl)
            })
        );
    }

    getAllGlobalLayers() {
        return this.http.get<GlobalLayer[]>(`${environment.apiUrl}/layer/global`)
        // .pipe();
    }

    getById(id: number) {
        return this.http.get<LayerFeature>(`${environment.apiUrl}/layer/${id}`);
    }

    // add(name: string, layerTypeId: number): Observable<NewLayerResponse> {
    //     return this.http.post<NewLayerResponse>(`${environment.apiUrl}/layer`, {
    //             name: name,
    //             layer_type_id: layerTypeId
    //         }
    //     );
    // }

    add(layer: Layer): Observable<NewLayerResponse> {
        return this.http.post<NewLayerResponse>(`${environment.apiUrl}/layer`, {
                name: layer.name,
                layer_type_id: layer.layer_type.id,
                order: layer.order,
            }
        );
    }

    addRastr(name: string, file?: File): Observable<NewLayerResponse> {
        // console.log('addRastr', this._allLayerTypes)
        const data = new FormData();
        data.append('name', name);
        data.append('layer_type_id',  this.getNewLayerTypeByName(LayerTypes.rastr).id.toString()),
                    // Array.from(this._allLayerTypes)
                    //     .find(value => value[1].name == 'RASTR' && value[1].parent_id != null)[1].id
                    //     .toString()); //LayerTypeIDs[LayerTypes.rastr].toString()); // '6'
        data.append('rastr', file, file.name);
        data.append('order', (Math.floor(Math.random() * 99) + 1).toString());
        return this.http.post<NewLayerResponse>(`${environment.apiUrl}/layer`, data);
    }

    addFile(layerId: number, data: FormData): any {
        return this.http.post(`${environment.apiUrl}/layer/${layerId}/file`, data);
    }

    // addRastrFile(layerId: number, file: File): any {
    //     const data = new FormData();
    //     data.append('file', file, file.name);
    //     return this.http.post(`${environment.apiUrl}/layer/${layerId}/file`, data);
    // }

    edit(layer: Layer , file?: File, rastr?: File) {
        const data = new FormData();
        // let layerID: number

        // if (layer instanceof Layer) {
        // layerID = layer.ID
        data.append('name', layer.name);
        data.append('layer_type_id', layer.layer_type.id.toString());
        data.append('order', layer.order.toString());
        data.append('color', layer.color);

        // data.append('data', layer.data.map(item => item.toString()));
        data.append('data', JSON.stringify(layer.data));
        data.append('w', layer.w?.toString());
        data.append('h', layer.h?.toString());
        data.append('format', layer.format);
        // }
        if (file) data.append('file', file, file.name);
        if (rastr) data.append('rastr', rastr, rastr.name);

        return this.http.put(`${environment.apiUrl}/layer/${layer.ID}`, data);
    }

    editPutLayer(layer: Layer) {
        const data = {
            name: layer.name,
            layer_type_id: layer.layer_type.id,
            order: layer.order,
            color: layer.color.replace('#',''),
            data: layer.data,
            w: layer.w,
            h: layer.h,
            format: layer.format
        }

        return this.http.put(`${environment.apiUrl}/layer/${layer.ID}`, data);
    }

    //layer: NewLayerResponse
    editPut(layer: any) { 
        const data = {
            name: layer.name,
            layer_type_id: layer.layer_type_id 
                                            ? parseInt(layer.layer_type_id) 
                                            : layer.layerTypeId ? parseInt(layer.layerTypeId) : parseInt(layer.layer_type.id),
            order:  layer.order ? layer.order : Math.floor(Math.random() * 99) + 1,
            color: layer.color.replace('#',''),
            data: layer.data,
            w: layer.w,
            h: layer.h,
            format: layer.format,
            scale: layer.scale ?? undefined,
            rotate: layer.rotate ?? undefined,
            center: layer.center ?? undefined
        }

        // const data = new FormData();
        // data.append('name', layer.name);
        // data.append('layer_type_id', layer.layer_type_id 
        //                                 ? layer.layer_type_id.toString() 
        //                                 : layer.layerTypeId ? layer.layerTypeId.toString() : layer.layer_type.id.toString());
        // data.append('order', layer.order ? layer.order.toString() : Math.floor(Math.random() * 99) + 1);
        // data.append('color', layer.color);
        // data.append('data', JSON.stringify(layer.data));
        // data.append('w', layer.w?.toString());
        // data.append('h', layer.h?.toString());
        // data.append('format', layer.format);

        return this.http.put(`${environment.apiUrl}/layer/${layer.layer_id}`, data);
        
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/layer/${id}`);
    }

    // getLayerTypes(): Observable<LayerTypesCollection> {
    //     return this.http.get<LayerTypesCollection>(`${environment.apiUrl}/layer_type`);
    // }
    getLayerTypes(): Observable<NewLayerType[]> {
        return this.http.get<NewLayerType[]>(`${environment.apiUrl}/layer_type`);
    }

    joinLayers(data: {acceptor_id: number; donor_id: number}): Observable<LayerFeature> {
        return this.http.post<LayerFeature>(`${environment.apiUrl}/layer/join_layers`, data).pipe(
            tap(layer => this.updatedLayer$.next(layer))
        );
    }

    copyItems(req: CopyItemsResponse): Observable<{ layer: LayerFeature }> {
        return this.http.post<{ layer: LayerFeature }>(`${environment.apiUrl}/layer/copy_items`, req).pipe(
            tap(res => this.updatedLayer$.next(res.layer))
        );
    }
}
