import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter, HostListener,
    inject,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NgControl,
    ValidationErrors,
    Validator
} from '@angular/forms';
import { animationFrameScheduler, BehaviorSubject, takeUntil } from 'rxjs';
import { ImusDestroyService } from '@shared/services/destroy.service';
import { imus } from '@app/shared/imus';

@Component({
    selector: '',
    template: ``,
    host: {
        class: 'imus-input'
    },
    providers: [ImusDestroyService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class ImusBaseInputComponent<T> implements imus.foundation.IFieldComponent<T>, OnInit, ControlValueAccessor, Validator {

    @HostListener('focusout')
    public focusLost():void {
        this.propagateTouched();
    }

    private _cdRef = inject(ChangeDetectorRef);

    private _propagateChange: ((_: T | null) => void) | undefined;

    private _propagateTouched: (() => void) | undefined;

    /** флаг установки невалидности */
    @Input('invalid')
    set _invalid(value: boolean) {
        this.invalid$.next(value);
    };

    /** disabled для input */
    @Input('readonly')
    set _readonly(value: boolean | string) {
        this.readonly$.next((value === '' ? true : !!value)!);
    };

    @Input('disabled')
    set _disabled(value: boolean | string) {
        this.disabled$.next((value === '' ? true : !!value)!);
    };

    @Input('label')
    set _title(value: string) {
        this.title$.next(value);
    }

    @Input('placeholder')
    set _placeholder(value: string) {
        this.placeholder$.next(value);
    }

    public get errors(): ValidationErrors {
        return this._control?.errors ?? {};
    }

    public get invalid(): boolean {
        return this._control?.invalid ?? false;
    }

    @Output()
    public changed: EventEmitter<T | null>;

    public readonly title$ = new BehaviorSubject<string>(undefined);
    public readonly placeholder$ = new BehaviorSubject<string>('');

    public readonly invalid$ = new BehaviorSubject<boolean>(false);

    public readonly readonly$ = new BehaviorSubject<boolean>(false);
    public readonly disabled$ = new BehaviorSubject<boolean>(false);
    /**
     * Элемент инпута
     *
     * Для определения необходимо в наследнике получить поле через @ViewChild
     *
     * @see InputComponent
     * */
    protected _inputElement?: ElementRef;

    /** геттер значения инпута */
    public get value(): T | null {
        return this.innerControl.value;
    }

    /** сеттер значения инпута */
    @Input('value')
    public set value(value: T | null) {
        this.innerControl.setValue(value);
        if (this._propagateChange) {
            this._propagateChange(value);
        }
        if (this.changed) {
            this.changed.emit(value);
        }
        this.afterRefreshValue(value);
    }

    public innerControl = new FormControl<T>(null);

    protected constructor(
        protected readonly _destroy$: ImusDestroyService,
        protected readonly _control: NgControl
    ) {
        if (_control)
            _control.valueAccessor = this;
    }

    ngOnInit(): void {
        if (this._control && this._control.control && this._control.statusChanges) {
            this.innerControl.valueChanges.pipe(
                takeUntil(this._destroy$)
            ).subscribe((value) => {
                if ( this._propagateChange) {
                    this._propagateChange(value);
                }
            });

            // this.innerControl.setValidators(this._control.control.validator ?? []);
            // this.innerControl.setAsyncValidators(this._control.control.asyncValidator ?? []);

            this._control.statusChanges.pipe(
                takeUntil(this._destroy$)
            ).subscribe(() => {
                this.innerControl.setErrors(this._control.errors);
                if (this.innerControl.dirty) {
                    this._control.control.markAsDirty();
                } else {
                    this._control.control.markAsPristine();
                }
            });
        }
    }

    //#region ControlValueAccessor
    public writeValue(value: T): void {
        animationFrameScheduler.schedule(() => {
            this.innerControl.setValue(this.beforeSetOuterValue(value), { emitEvent: false });
            this.afterRefreshValue(this.value);
        })
    }

    public registerOnChange(fn: (_: T | null) => void): void {
        this._propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this._propagateTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled$.next(isDisabled);
        if (isDisabled) {
            this.innerControl.disable()
        } else {
            this.innerControl.enable()
        }
    }

    //#endregion ControlValueAccessor

    public validate(control: AbstractControl): ValidationErrors | null {
        return undefined;
    }

    /**
     * Метод для реализации дополнительных действий перед установкой значения из VM.
     * Например, конвертации.
     */
    protected beforeSetOuterValue(value: unknown): T {
        return value as T;
    }

    /**
     * Метод для реализации дополнительных действий после обновления значения
     *
     * @remarks - значение считается обновленным вне зависимости, было ли оно установлено из вне или было изменено в компоненте
     */
    // eslint-disable-next-line no-unused-vars
    protected afterRefreshValue(value: T | null) {
        this._cdRef.markForCheck();
    }

    public propagateTouched() {
        if (this._propagateTouched) {
            this._propagateTouched();
        }
    }

}
