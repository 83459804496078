import { HttpClient } from '@angular/common/http';
import { Injectable,EventEmitter } from '@angular/core';
import { IPlotFeature } from '@app/shared/models';
import { CodeTypes } from '@app/shared/models/code';
import { inspection } from '@app/shared/models/inspection';
import { ProjectTypes } from '@app/shared/models/project';
import { HttpSettings } from '@app/shared/utilities/http-utility';
import { map, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProjectRouteService {
    private readonly changed$ = new EventEmitter<void>();

    constructor(private httpClient: HttpClient) {}

    loadProjects() {
        return this.httpClient
            .get<ProjectTypes.IProjectListResponse>(
                `${HttpSettings.apiHost}/project`
            )
            .pipe(tap(() => this.changed$.emit()))
            .pipe(
                map((responce) => {
                    responce.features = responce.features.map((project) => {
                        project.properties.created_at = new Date(
                            project.properties.created_at
                        );
                        project.properties.project_created_at = new Date(
                            project.properties.project_created_at
                        );
                        project.properties.updated_at = new Date(
                            project.properties.updated_at
                        );
                        return project;
                    });
                    return responce;
                })
            );
    }

    loadProject(id: number) {
        return this.httpClient
            .get<ProjectTypes.IProjectResponseItem[]>(
                `${HttpSettings.apiHost}/project/${id}`
            )
            .pipe(tap(() => this.changed$.emit()))
            .pipe(                
                map((responce) => {
                    responce = responce.map((project) => {
                        project.properties.created_at = new Date(
                            project.properties.created_at
                        );
                        project.properties.project_created_at = new Date(
                            project.properties.project_created_at
                        );
                        project.properties.updated_at = new Date(
                            project.properties.updated_at
                        );
                        return project;
                    });
                    return responce;
                })
            );
    }

    deleteProject(id: number){
        return this.httpClient.delete(`${HttpSettings.apiHost}/project/${id}`)
        .pipe(
                        tap(() => this.changed$.emit())
        );
    }

    loadSection(id: number) {
        return this.httpClient.get<ProjectTypes.ISectionResponseItem[]>(
            `${HttpSettings.apiHost}/section/${id}`
        )
        .pipe(tap(() => this.changed$.emit()));
    }
    
    editSection(sectionId: number, plotId: any) {
        return this.httpClient.put<any>(
            `${HttpSettings.apiHost}/section/${sectionId}`,
            {
                plot: plotId
            }
        )
        .pipe(tap(() => this.changed$.emit()));
    }

    loadCodeCollection() {
        return this.httpClient.get<CodeTypes.CodeCollection>(
            `${HttpSettings.apiHost}/code`
        )
        .pipe(tap(() => this.changed$.emit()));
    }

    loadPlot(id: number) {
        return this.httpClient.get<IPlotFeature>(
            `${HttpSettings.apiHost}/plot/${id}`
        )
        .pipe(tap(() => this.changed$.emit()));
    }

    loadInspection(id: number){
        return this.httpClient.get<inspection.IResponse>(
            `${HttpSettings.apiHost}/inspection/${id}`)
        .pipe(tap(() => this.changed$.emit()))
    }

    updateProject(id: number, request: ProjectTypes.IProjectUpdateRequest){
        return this.httpClient.put(`${HttpSettings.apiHost}/project/${id}`, request)
        .pipe(tap(()=>this.changed$.emit()));
    }

    updateCompany(id: number, request: ProjectTypes.ICompanyUpdateRequest){
        return this.httpClient.put(`${HttpSettings.apiHost}/company/${id}`, request)
        .pipe(tap(()=>this.changed$.emit()));
    }
}
