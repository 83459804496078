<div class="app-input-area" [ngClass]="{hostborder: (title$ | async) !== undefined || (useIcon$ | async) }">
    <div *ngIf="(title$ | async) !== undefined" class="label-area">
        <mat-label matPrefix>{{title$ | async}}</mat-label>
    </div>
    <div *ngIf="useIcon$ | async" class="icon-area">
        <mat-icon>{{icon}}</mat-icon>
    </div>
    <div class="input-area">
        <mat-form-field class="input-form">
            <mat-select #selectELement [value]="value" [placeholder]="placeholder$ | async" [disabled]="disabled$ | async" (selectionChange)="onSelectChangeSelect($event)">

                <mat-option *ngFor="let optionItem of options" [value]="optionItem[objectKeyProperty]" (onSelectionChange)="onSelectionChange($event)">
                    {{ optionItem[objectNameProperty] }}{{ optionItem['units'] ? ', ' + optionItem['units'] : '' }}
                </mat-option>

            </mat-select>
        </mat-form-field>
        <mat-label *ngIf="required">*</mat-label>
    </div>
</div>