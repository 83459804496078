import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullComponent } from './shared/components/layouts/full/full.component';
import { PageAboutComponent } from './shared/pages/page-about/page-about.component';
import { PageAccessDeniedComponent } from './shared/pages/page-access-denied/page-access-denied.component';
import { PageHelloComponent } from './shared/pages/page-hello/page-hello.component';
import { canActivateAccountFn, canActivateFn } from './shared/interceptors/auth.guard';
import {
    PageEmailConfirmationComponent
} from './shared/pages/page-email-confirmations/page-email-confirmations.component';
import { PageChangePasswordComponent } from './shared/pages/page-change-password/page-change-password.component';
import {
    PageVerifyRegistrationComponent
} from './shared/pages/page-verify-registration/page-verify-registration.component';
import { MenuCode } from "@shared/models/menu";
import { MenuSettingsComponent } from "@app/modules/account/pages/menu-settings/menu-settings.component";
import { AccountsComponent } from "@app/modules/account/pages/accounts/accounts.component";
import {
    PermissionSettingsComponent
} from "@app/modules/account/pages/permission-settings/permission-settings.component";
import { ContractorsComponent } from "@app/modules/account/pages/contractors/contractors.component";
import { InfoComponent } from "@app/modules/account/pages/info/info.component";
import { ProfileComponent } from "@app/modules/account/pages/profile/page/profile.component";
import { PROFILE_ROUTE } from "@shared/route";
import { canActivateChildAccountProfileGuardFn } from "@app/modules/account/pages/profile/guards/account-profile.guard";
import {
    CompanyDetailsComponent
} from "@app/modules/account/pages/profile/components/company-details/company-details.component";
import { CredentialsComponent } from "@app/modules/account/pages/profile/components/credentials/credentials.component";
import { GuestsComponent } from "@app/modules/account/pages/profile/components/guests/guests.component";
import { EmployeesComponent } from "@app/modules/account/pages/profile/components/employees/employees.component";
import { PersonalComponent } from "@app/modules/account/pages/profile/components/personal/personal.component";
import {
    AdditionalAccountsComponent
} from "@app/modules/account/pages/profile/components/additional-accounts/additional-accounts.component";
import {UserProfileComponent} from "@app/modules/account/pages/user-profile/user-profile.component";
import { RastrEditorPageComponent } from './modules/layer/pages/rastr-editor-page/rastr-editor-page.component';
import { ContractorEditComponent } from './modules/account/pages/contractor/pages/contractor-edit/contractor-edit.component';
import { CONTRACTROR_ROUTE } from './modules/account/pages/contractor/models/contractor';
import { ContractorCompanyDetailsComponent } from './modules/account/pages/contractor/components/contractor-company-details/contractor-company-details.component';
import { ContractorPersonalComponent } from './modules/account/pages/contractor/components/contractor-personal/contractor-personal.component';
import { ContractorEmployeesComponent } from './modules/account/pages/contractor/components/employees/contractor-employees.component';

export const routes: Routes = [
    {
        path: '',
        component: FullComponent,
        canActivate: [canActivateFn],//[AuthGuard],
        children: [
            {
                path: 'about',
                component: PageAboutComponent,
                // data: {
                //   title: $localize`About`,
                // },
            },
            {
                path: 'access-denied',
                component: PageAccessDeniedComponent,
                // data: {
                //   title: $localize`Access denied`,
                // },
            },

            {
                path: '',
                loadChildren: () =>
                    import('./modules/main/main.module').then(
                        (m) => m.MainModule
                    ),
            },
            {
                path: 'account-old',
                loadChildren: () =>
                    import('./modules/account/account.module').then((m) => m.AccountModule),
            },
            {
                path: 'layer',
                loadChildren: () =>
                import('./modules/layer/layer.module').then((m) => m.LayerModule),
            },
            {
                path: 'account',
                loadComponent: () => import('./modules/account/page/account.page/account.page.component').then((c) => c.AccountPageComponent),
                canActivateChild: [canActivateAccountFn],
                children: [
                    {
                        path: MenuCode.MENU_SETTINGS,
                        component: MenuSettingsComponent
                    }, {
                        path: MenuCode.ACCOUNTS,
                        children: [
                            {
                                path: '',
                                component: AccountsComponent
                            },
                            {
                                path: 'user/:id',
                                component: UserProfileComponent
                            }
                        ]
                    }, {
                        path: MenuCode.PERMISSION_SETTINGS,
                        component: PermissionSettingsComponent
                    }, {
                        path: MenuCode.CONTRACTORS,
                        children: [
                            {
                                path: '',
                                component: ContractorsComponent
                            },
                            {
                                path: ':id',
                                component: ContractorEditComponent,
                                children: [
                                    {
                                        path: '',
                                        redirectTo: CONTRACTROR_ROUTE.legalDetails,
                                        pathMatch: 'full'
                                    },
                                    {
                                        path: CONTRACTROR_ROUTE.legalDetails,
                                        component: ContractorCompanyDetailsComponent
                                    },
                                    {
                                        path: CONTRACTROR_ROUTE.guests,
                                        component: GuestsComponent,
                                    },
                                    {
                                        path: CONTRACTROR_ROUTE.employees,
                                        component: ContractorEmployeesComponent,
                                        pathMatch: 'full'
                                    },
                                    {
                                        path: CONTRACTROR_ROUTE.personal,
                                        component: ContractorPersonalComponent,
                                    },
                                ]
                            }
                        ]
                    }, {
                        path: MenuCode.USER_GUIDE,
                        component: InfoComponent
                    }, {
                        path: MenuCode.USER_ACCOUNTS,
                        component: ProfileComponent,
                        canActivateChild: [canActivateChildAccountProfileGuardFn],
                        children: [
                            {
                                path: '',
                                redirectTo: PROFILE_ROUTE.credentials,
                                pathMatch: 'full'
                            },
                            {
                                path: PROFILE_ROUTE.individualDetails,
                                component: CompanyDetailsComponent,
                            },
                            {
                                path: PROFILE_ROUTE.legalDetails,
                                component: CompanyDetailsComponent
                            },
                            {
                                path: PROFILE_ROUTE.credentials,
                                component: CredentialsComponent
                            },
                            {
                                path: PROFILE_ROUTE.guests,
                                component: GuestsComponent,
                            },
                            {
                                path: PROFILE_ROUTE.employees,
                                component: EmployeesComponent,
                            },
                            {
                                path: PROFILE_ROUTE.personal,
                                component: PersonalComponent,
                            },
                            {
                                path: PROFILE_ROUTE.additionalAccounts,
                                component: AdditionalAccountsComponent,
                            }
                        ]
                    },
                ]
            }
        ],
    },
    // {
    //   path: 'public',
    //   loadChildren: () =>
    //     import('./modules/public/public.module').then(
    //       (m) => m.PublicModule
    //     ),
    // },
    {
        path: 'login',
        component: PageHelloComponent,
        // data: {
        //   title: $localize`Authorization`,
        // },
    },
    {
        path: 'email-confirmation',
        component: PageEmailConfirmationComponent,
        canActivate: [canActivateFn],//[AuthGuard],
        // data: {
        //   title: $localize`Authorization`,
        // },
    },
    {
        path: 'verify/registration',
        component: PageVerifyRegistrationComponent,
        // data: {
        //   title: $localize`Authorization`,
        // },
    },
    {
        path: 'password/reset',
        component: PageChangePasswordComponent,
        // data: {
        //   title: $localize`Authorization`,
        // },
    },
    {
        path: 'dev',
        loadChildren: () =>
            import('./shared/modules/dev/dev.module').then((m) => m.DevModule),
    },
    // otherwise redirect to home
    { path: '**', redirectTo: '' },
    // { path: '**', component: PageNotFoundComponent },
];
export const accountsRoutes = []

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // initialNavigation: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
