import { ChangeDetectionStrategy, Component, inject, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { EmployeeAccount } from "@shared/models/contractors";
import { SharedModule } from "@shared/shared.module";
import { ButtonComponentSettings } from "@shared/components/button/button.component";
import { imus } from "@shared/imus";
import { FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { InputType } from "@shared/input-type";
import { MenuService } from "@services/menu.service";
import { AccountService } from "@app/modules/account/services/account.service";
import { BehaviorSubject, catchError, of, shareReplay, switchMap, takeUntil } from "rxjs";
import { AccountsService } from "@app/modules/account/services/accounts.service";
import { AccountsViewData, UserAccountsInfo } from "@shared/models";
import { ImusDestroyService } from "@services/destroy.service";
import { filter, finalize, tap } from "rxjs/operators";
import { AlertService } from "@shared/services";

interface FormModel {
    id: number;
    role_id: number;
    job_post: string;
    is_primary: boolean;
    is_proxy: boolean;
    is_signer: boolean;
    job_post_gen: string;
    start_date: string;
    proxy_doc_date: string;
    end_date: string;
    proxy_doc_number: number;
}

interface Employee {
    form: imus.form.IFormGroup<FormModel>,
    model: FormModel;
}

@Component({
    selector: 'app-employee-edit-dialog',
    standalone: true,
    imports: [CommonModule, MatDialogModule, SharedModule, ReactiveFormsModule],
    templateUrl: './employee-edit.dialog.component.html',
    styleUrls: ['./employee-edit.dialog.component.scss'],
    providers: [ImusDestroyService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeEditDialogComponent implements OnInit {
    private readonly menuService = inject(MenuService);
    private readonly fb = inject(FormBuilder);
    private readonly accountsService = inject(AccountsService);
    private readonly destroy$ = inject(ImusDestroyService);
    private readonly alertService = inject(AlertService);
    private account: UserAccountsInfo;
    public readonly dialogRef = inject(MatDialogRef<EmployeeEditDialogComponent>);
    private dialogResult: any = null
    private canExecuteDialogCloseWithResult: boolean;
    public isLoading$ = new BehaviorSubject<boolean>(false);
    public readonly inputType = InputType;
    public readonly buttonType = ButtonComponentSettings.ButtonType;
    public readonly roles$ = inject(AccountService).account$.pipe(
        tap(acc => this.account = acc),
        switchMap(acc => this.menuService.getRolesList({ 'where[account_type_id]': acc.account_type_id, paginate: false })),
        shareReplay({ refCount: true, bufferSize: 1 })
    )
    public readonly employee: Employee = {
        form: null,
        model: {
            role_id: null,
            job_post: null,
            is_primary: null,
            is_proxy: true,
            is_signer: null,
            job_post_gen: null,
            start_date: null,
            proxy_doc_date: null,
            end_date: null,
            proxy_doc_number: null,
            id: null
        },
    };
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: EmployeeAccount
    ) {
        this.employee.form = this.fb.fromTypedModel(this.employee.model);
    }

    ngOnInit(): void {
        this.canExecuteDialogCloseWithResult = true
        if (this.data) {
            Object.keys(this.employee.model).forEach(key => {
                if (key in this.data) {
                    this.employee.form.controls[key].patchValue(this.data[key])
                }
            })
        }
        // Возвращаем сохраненный результат при любом способе закрытия диалога
        this.dialogRef.beforeClosed()
        .pipe(
            filter(_ => this.canExecuteDialogCloseWithResult),
            tap(_ => {
                this.canExecuteDialogCloseWithResult = false
                this.dialogRef.close(this.dialogResult)
            }),
            takeUntil(this.destroy$))
        .subscribe()
    }

    public onSubmit(): void {
        if (this.employee.form.invalid) {
            return;
        }
        const value = Object.assign({}, this.employee.form.toModel(this.employee.model, {
            proxy_doc_date: this.parseDate.bind(this),
            start_date: this.parseDate.bind(this),
            end_date: this.parseDate.bind(this),
        }), {})
        const req = {} as any;
        Object.keys(value).forEach(key => {
            let detail: string
            [
                'is_proxy',
                'is_signer',
                'job_post',
                'job_post_gen',
                'proxy_doc_date',
                'proxy_doc_number'
            ]
            .includes(key) 
                ? detail = 'detail_' 
                : detail = ''
            req['account_' + detail + key] = value[key];
        })
        this.isLoading$.next(true);
        req.account_start_date = req.account_start_date ? new Date(req.account_start_date) : null
        req.account_end_date = req.account_end_date ? new Date(req.account_end_date) : null
        req.account_detail_is_signer = req.account_detail_is_signer ?? undefined
        this.accountsService.editMainAccount(req as unknown as AccountsViewData).pipe(
            catchError(err => {
                this.alertService.openSnackbar('Произошла ошибка при редактировании. Попробуйте позже');
                this.isLoading$.next(false);
                return of(null);
            }),
            finalize(() => this.isLoading$.next(false)),
            takeUntil(this.destroy$)
        ).subscribe(data => {
            this.alertService.openSnackbar('Данные обновлены!')
            this.isLoading$.next(false);
            this.dialogResult = data
            // this.dialogRef.close(data)
        })
    }

    private parseDate(date: Date | string): string | null {
        return (!!date && date instanceof Date) ? this.getLocalIsoTime(date) : date as string || null;
    }

    private getLocalIsoTime(date: Date): string {
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
    }
}
