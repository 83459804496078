import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DeviceTypes, NodeFeature, NodeRef, PlotFeature, PlotRef } from '@shared/models';
import {
    DeviceService,
    FileService,
    LayerService,
    LayersStateService,
    NodeService,
    PlotService
} from '@services/index';
import {
    animationFrameScheduler,
    BehaviorSubject,
    catchError,
    combineLatest,
    finalize, first, from,
    Observable,
    of, ReplaySubject,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
    withLatestFrom
} from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { SwitchEditorComponent } from '@shared/components/switch-editor/switch-editor.component';
import { ImusDestroyService } from "@services/destroy.service";
import { MatDialog } from "@angular/material/dialog";
import { AlertDialogComponent } from "@app/modules/main/dialogs/alert.dialog/alert.dialog.component";
import { imus } from "@shared/imus";
import { TelevisionInspectionService } from "@services/television-inspection.service";
import { MainPageService, PageServiceTypes } from '@app/modules/main/services/main-page.service';
import { DevicesRouteService } from "@app/routes/device-route.service";
import { EntityType, ISelectedEntity } from "@shared/models/entity-type";
import {
    editPanelForm
} from "@app/modules/main/modules/details-info-panel/components/details-edit-panel/details-edit-panel-form.models";
import { node } from "@shared/models/node-ref";
import { inspection } from "@shared/models/inspection";
import { EditingFieldNameProvider } from "@app/modules/main/modules/details-info-panel/editing-field-name.provider";
import { InspectionPanelService } from '../../../inspection-panel/inspection-panel.service';
import {
    DeviceMeasurementsComponent
} from "@app/modules/measurement/device-measurements/device-measurements.component";

type SelectedEntity = { node: NodeRef } | { plot: PlotRef }

@Component({
    selector: 'app-details-edit-panel',
    templateUrl: './details-edit-panel.component.html',
    styleUrls: ['./details-edit-panel.component.scss'],
    providers: [ImusDestroyService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailsEditPanelComponent implements OnInit {
    public entity = {
        [EntityType.NODE]: {
            form: null as imus.form.IFormGroup<editPanelForm.FormNodeModel>,
            model: {
                name: null,
                node_type_id: null,
                lon: null,
                lat: null,
                height_mark: null,
                well_depth: null,
                material_id: null,
                notes: null,
                on: false
            } as editPanelForm.FormNodeModel
        },
        [EntityType.PLOT]: {
            form: null as imus.form.IFormGroup<editPanelForm.FormPlotModel>,
            model: {
                name: null,
                start_node: null,
                end_node: null,
                length: null,
                sectionLength: null,
                laying_year: null,
                diameter: null,
                slope: null,
                section_length: null,
                notes: null,
                shape_id: null,
                material_id: null,
                coating_id: null,
                location_id: null,
                use_id: null,
                purpose_id: null,
                on: false,
                plot_type_id: null,
            } as editPanelForm.FormPlotModel
        }
    }

    @ViewChildren(SwitchEditorComponent)
    private _switchEditorList: QueryList<SwitchEditorComponent>;
    public readonly uploaded$ = new BehaviorSubject(false);
    public info$: Observable<ISelectedEntity & SelectedEntity> = this.layerStateService.state.selectedEntity$.pipe(
        tap(() => this.editingFieldName.current = null),
        map(selected => {
            if (selected) {
                if (selected.type === EntityType.NODE) {
                    const item = Object.assign<ISelectedEntity, SelectedEntity>(selected, {
                        node: this.layerStateService.state.getNodeByNodeId(selected.id)
                    });
                    this.patchForm(EntityType.NODE, item);
                    return item;
                } else {
                    const item = Object.assign<ISelectedEntity, SelectedEntity>(selected, {
                        plot: this.layerStateService.state.getPlotByPlotId(selected.id)
                    });
                    this.patchForm(EntityType.PLOT, item);
                    return item;
                }
            } else {
                return null;
            }
        }),
        tap((res) => {
            if (res && this.pageService.leftClosed) {
                this.pageService.setCloseToLeftPanel(false);
                if (this._switchEditorList) {
                    this._switchEditorList.forEach(item => item.closeEditor());
                }
            }
        }),
        shareReplay( { refCount: true, bufferSize: 1 } )
    );
    public inspectionList$: Observable<inspection.IResponse[]> = combineLatest([this.televisionInspectionService.inspectionList$.pipe(
        map(i => i[0]),
    ), this.info$.pipe(
        filter(i => i?.type === EntityType.PLOT)
    )]).pipe(
        map(([all, info]) => all.filter(inspection => inspection.section?.properties.plot_id === (info[EntityType.PLOT] as PlotRef)?.id)),
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    public readonly devices$ = combineLatest([this.info$, this._deviceService.devicesList$]).pipe(
        filter(([entity]) => Boolean(entity)),
        map(([entity, res]) => {
            return res.devices.filter(d => d[entity.type] && (d[entity.type] as unknown as NodeFeature | PlotFeature).properties.id === entity.id) ?? []
        }),
        shareReplay({ refCount: true, bufferSize: 1 })
    )
    public uploadFileControl = new FormControl<File[]>(null);

    private readonly _currentEditPlotField$ = new ReplaySubject<string>(1);
    public readonly currentEditPlotField$: Observable<string> = this._currentEditPlotField$;

    constructor(
        public pageService: MainPageService,
        public layerStateService: LayersStateService,
        private deviceService: DeviceService,
        private nodeService: NodeService,
        private plotService: PlotService,
        private layerService: LayerService,
        private televisionInspectionService: TelevisionInspectionService,
        private readonly _fb: FormBuilder,
        private readonly _destroy$: ImusDestroyService,
        private readonly _matDialog: MatDialog,
        private readonly _deviceService: DevicesRouteService,
        private readonly matDialog: MatDialog,
        private readonly editingFieldName: EditingFieldNameProvider,
        private readonly inspectionPanelSevice: InspectionPanelService,
        private readonly fileService: FileService,
        private readonly cdRef: ChangeDetectorRef
    )
    {
        this.entity[EntityType.NODE].form = this._fb.fromTypedModel(this.entity[EntityType.NODE].model);
        this.entity[EntityType.PLOT].form = this._fb.fromTypedModel(this.entity[EntityType.PLOT].model);
    }

    ngOnInit(): void {
        this.pageService.setCloseToLeftPanel(true);
        this.uploadFileControl.valueChanges.pipe(
            debounceTime(50),
            tap(() => this.uploaded$.next(true)),
            switchMap((file) => (file && file[0]?.size > 1000000000
                    ? this._matDialog.open(AlertDialogComponent, {
                        width: '480px',
                        data: {
                            okButtonText: 'Продолжить',
                            cancelButtonText: 'Отменить',
                            message: 'Файл слишком большой будет долго загружаться, точно хотите его загрузить?'
                        }
                    }).afterClosed().pipe(
                        map(result => result ? file : [])
                    )
                    : of(file)
            ).pipe(
                withLatestFrom(this.info$),
                switchMap(([file, info]) => (info.type === EntityType.NODE ? this.nodeService : this.plotService).uploadFile(
                    file[0],
                    this.layerStateService.getData().selectedEntity.id,
                    this.layerStateService.getData().selectedLayer
                ).pipe(
                    tap(() => {
                        this.layerService.updateLayerInfo(this.layerStateService.getData().selectedLayer);
                    }),
                    switchMap(() => info.type === EntityType.PLOT
                        ? this.plotService.getById(info.id).pipe(
                            tap(plot => this.layerStateService.state.setPlotToLayer(new PlotRef(plot.properties)))
                        )
                        : of(undefined)),
                    finalize(() => this.uploaded$.next(false)),
                    catchError(() => {
                        this.uploadFileControl.setErrors({ serverError: 'Файл не загружен, попробуйте ещё раз' });
                        return of(undefined);
                    })
                ))
            )),
            takeUntil(this._destroy$)
        ).subscribe(() => {
            this.uploadFileControl.setValue(null, { emitEvent: false });
            this.layerStateService.state.updateSelected();
        });
    }

    private patchForm(entity: EntityType,  item: ISelectedEntity & SelectedEntity): void {
        const _value: Record<string, unknown> = {};
        if (entity === EntityType.NODE) {
            _value['node_type_id'] = (item[EntityType.NODE] as NodeRef)?.node_type?.id ?? null;
            _value['material_id'] = (item[EntityType.NODE] as NodeRef)?.material?.properties.id ?? null;
        }
        if (entity === EntityType.PLOT) {
            _value['start_node'] = (item[EntityType.PLOT] as PlotRef)?.startNode?.name ?? null;
            _value['end_node'] = (item[EntityType.PLOT] as PlotRef)?.endNode?.name ?? null;
            _value['shape_id'] = (item[EntityType.PLOT] as PlotRef)?.shape?.properties.id ?? null;
            _value['material_id'] = (item[EntityType.PLOT] as PlotRef)?.material?.properties.id ?? null;
            _value['coating_id'] = (item[EntityType.PLOT] as PlotRef)?.coating?.properties.id ?? null;
            _value['purpose_id'] = (item[EntityType.PLOT] as PlotRef)?.purpose?.properties.id ?? null;
            _value['location_id'] = (item[EntityType.PLOT] as PlotRef)?.location?.properties.id ?? null;
            _value['layer_id'] = (item[EntityType.PLOT] as PlotRef)?.layer ?? null;
            _value['use_id'] = (item[EntityType.PLOT] as PlotRef)?.use?.properties.id ?? null;
            _value['plot_type_id'] = (item[EntityType.PLOT] as PlotRef)?.plot_type?.id ?? null;
        }
        this.entity[entity].form.patchValue(Object.assign({}, item[entity], _value), { emitEvent: false });
        this.cdRef.markForCheck();
    }

    public editNode(event: editPanelForm.EntityFieldEventType<NodeRef>): void {
        const _value = this.entity[EntityType.NODE].form.toModel(this.entity[EntityType.NODE].model);
        this.nodeService.edit(<number>event.item.id, event.item.getNodeReq(_value as unknown as node.NodeRequest)).pipe(
            takeUntil(this._destroy$)
        ).subscribe(() => {
            this.editingFieldName.current = event.fieldName;
            this.layerStateService.state.updateSelected();
            this.layerService.updateLayerInfo(event.item.layer);
        });
    }

    public editPlot(event: editPanelForm.EntityFieldEventType<PlotRef>) {
        const _req = {
            start_node: <number>event.item.startNode.id,
            end_node: <number>event.item.endNode.id,
            plot_type_id: event.item.plot_type?.id ?? null
        }
        // FIXME: Пока не заполнены типы у всех плотов, ставлю такой костыль
        if (event.fieldName === 'plot_type_id') {
            _req.plot_type_id = +event.value
        } else if (_req.plot_type_id === null) {
            throw new Error('Для редактирования участка необходимо установить тип участка!')
        }
        this.plotService.editPatch(Object.assign(_req, { [event.fieldName]: event.value }), <number>event.item.id).pipe(
            switchMap((plot) => from([this.layerStateService.state.setPlotToLayer(new PlotRef(plot.properties))])),
            takeUntil(this._destroy$)
        ).subscribe(() => {
            this.editingFieldName.current = event.fieldName;
            this.layerStateService.state.updateSelected();
            this._currentEditPlotField$.next(event.fieldName);
            animationFrameScheduler.schedule(() => {
                this._currentEditPlotField$.next(null);
            })
        })
    }

    /** Удаление файла */
    public deleteFile(id: number) {
        of(true).pipe(
            withLatestFrom(this.info$.pipe(first())),
            switchMap(([_, info]) => this.fileService.deleteFile(id, info.type, info.id)),
            takeUntil(this._destroy$)
        ).subscribe(() => this.layerStateService.state.updateSelected());
    }

    /** Скачивание файла */
    public downloadFile(id: number) {

    }

    public abort() {
        this.uploadFileControl.setValue([]);
    }

    public editDevice(device: DeviceTypes.IDevice): void {
        // this.deviceService.gridDialogRolledUp = false;
        // this.deviceService.editableDevice.next(device);
        this.matDialog.open(
            DeviceMeasurementsComponent,
            { data: { device: device } }).afterClosed().subscribe();
    }

    public addDevice(): void {
        this.info$.subscribe((info: any) => {
            if(info) {
                if(info.type == 'node') {
                    this.deviceService.nodeSelected.next(info.id.toString());
                }
                if(info.type == 'plot') {
                    this.deviceService.plotSelected.next(info.id.toString());
                }
            }
            this.deviceService.gridDialogRolledUp = false;
            this.deviceService.editableDevice.next({});
        })
        // this.matDialog.open(AddEditDeviceDialogComponent).afterClosed().subscribe();
    }

    public onInspectClick(inspection: inspection.IResponse) {
        this.inspectionPanelSevice.openInspections(inspection)
            .subscribe((_) => {
                this.pageService.setActivePanel(PageServiceTypes.PanelType.inspectionPanel);
            });
    }
}
