import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, forkJoin, tap } from 'rxjs';
import { ObservationComponent } from './components/observation/observation.component';
import { ProjectRouteService } from '@app/routes/project-route.service';
import { inspection } from '@app/shared/models/inspection';
import { ProjectTypes } from '@app/shared/models/project';
import { CodeTypes } from '@app/shared/models/code';
import { IPlot } from '@app/shared/models';


export namespace InspectionPanelSettings {
  export const SCHEMA_MIN_HEIGHT = 500;
  export const LINE_POINT_RADIUS = 16;

  export enum PipeDirection {
    downStream = "По течению",
    upStream = "Против течения"
  }
}

@Injectable({
  providedIn: 'root'
})
export class InspectionPanelService {

  public codeCollection: CodeTypes.CodeCollection;

  private inspection: BehaviorSubject<inspection.IResponse> = new BehaviorSubject<inspection.IResponse>(null);
  public inspection$ = this.inspection.asObservable();

  private plot: BehaviorSubject<IPlot> = new BehaviorSubject<IPlot>(null);
  public plot$ = this.plot.asObservable();

  private project: BehaviorSubject<ProjectTypes.IProjectResponseItem> = new BehaviorSubject<ProjectTypes.IProjectResponseItem>(null);
  public project$ = this.project.asObservable();

  private observations: BehaviorSubject<ProjectTypes.IObservation[]> = new BehaviorSubject<ProjectTypes.IObservation[]>([]);
  public observations$ = this.observations.asObservable();

  private observationExpandingCollapsing: Subject<0|1> = new Subject();
  public observationExpandingCollapsing$ = this.observationExpandingCollapsing.asObservable();

  private _selectedSection: BehaviorSubject<ProjectTypes.ISectionResponseItem> = new BehaviorSubject<ProjectTypes.ISectionResponseItem>(null);
  public selectedSection$ = this._selectedSection.asObservable();
  get selectedSection() {
    return this._selectedSection.value;
  }
  set selectedSection(section: ProjectTypes.ISectionResponseItem) {
    this.projectRouteService.loadSection(section.properties.id).subscribe((sections) => {
      this._selectedSection.next(sections.length >= 0 ? sections[0] : null);  
    });    
  }

  private _initialHeight: BehaviorSubject<number> = new BehaviorSubject<number>(InspectionPanelSettings.SCHEMA_MIN_HEIGHT);
  public initialHeight$ = this._initialHeight.asObservable();
  set initialHeight(value: number) {
    this._initialHeight.next(Math.max(value - 3.25 * InspectionPanelSettings.LINE_POINT_RADIUS, InspectionPanelSettings.SCHEMA_MIN_HEIGHT));
    this._schemaHeight.next(this._initialHeight.value);
  }

  private _schemaHeight: BehaviorSubject<number> = new BehaviorSubject<number>(InspectionPanelSettings.SCHEMA_MIN_HEIGHT);
  public schemaHeight$ = this._schemaHeight.asObservable();
  set schemaHeight(value: number) {
    this._schemaHeight.next(Math.max(value, this._initialHeight.value));
    this.schemaScale = (this.selectedSection.properties.pipe.length - this._startPath.value) / (this._schemaHeight.value - 3.25 * InspectionPanelSettings.LINE_POINT_RADIUS);
  }
  get schemaHeight() {
    return this._schemaHeight.value;
  }

  private _observationComponents: ObservationComponent[] = [];
  get observationComponents() {
    return this._observationComponents;
  }

  public registerObservationComponent(component: ObservationComponent){
    this._observationComponents.push(component);    
  }

  public clearObservationComponents(){
    this._observationComponents = [];
    this.observationsCompleted.next(0);
  }

  private observationsCompleted: Subject<0 | 1>  = new Subject<0 | 1>();
  public observationsCompleted$ = this.observationsCompleted.asObservable();

  private calcObservationsScale() {
    if (this.selectedSection) {
      this.observationsScaleY = (this.selectedSection.properties.pipe.length - this._startPath.value) / (this._initialHeight.value - 86);
    }
  }

  private _startPath: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public startPath$ = this._startPath.asObservable();
  set startPath(value: number) {
    const start = 0.875 * value * (value / this.selectedSection.properties.pipe.length);
    this._startPath.next(start);
  }
  get startPath() {
    return this._startPath.value;
  }

  public schemaContainer;

  public observationsScaleY: number;
  public observationsShift: number;
  public schemaScale: number;

  public lastObservationPosition: number = 0;

  constructor(private readonly projectRouteService: ProjectRouteService) {
    this.init();
  }

  private init(){
    this.selectedSection$.subscribe((section) => {
      this._schemaHeight.next(this._initialHeight.value);
      if(section)
        this.observations.next(section.properties.observations.sort((a,b) => Number(a.properties.path) - Number(b.properties.path)));
      else
        this.observations.next([]);
    });

    this._startPath.subscribe((path) => {
      this.calcObservationsScale();
    });

    this.projectRouteService.loadCodeCollection().subscribe((collection)=>{      
      this.codeCollection = collection;
    });
  }

  public calcSchemaHeight(observationsFullUpdating: boolean = false) {
    for (const observation of this.observationComponents) {
      observation.hideConnector();
    }

    setTimeout(() => {
      this.schemaHeight = this.schemaContainer.scrollHeight + InspectionPanelSettings.LINE_POINT_RADIUS;
      for (const observation of this.observationComponents) {
        observation.setConnectorPosition();
      }
      if(observationsFullUpdating)
      this.observationsCompleted.next(1);
    });
  }

  public openInspections(inspection: inspection.IResponse) {
    this.inspection.next(inspection);    
    return forkJoin([this.projectRouteService.loadSection(inspection.section.properties.id),
      this.projectRouteService.loadPlot(inspection.section.properties.plot_id),
      this.projectRouteService.loadProject(inspection.section.project.properties.id)])
      .pipe(      
        tap((data) => {                
        this._selectedSection.next(data[0].length >= 0 ? data[0][0] : null);      
        this.plot.next(data[1].properties);
        this.project.next(data[2].length > 0 ? data[2][0] : null);
    }));
  }

  public observationExpandChanging(){
    this.observationExpandingCollapsing.next(1);
  }
}


