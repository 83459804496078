import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import OsmSource from 'ol/source/OSM';
import BingMaps from 'ol/source/BingMaps';
import StamenSource from 'ol/source/Stamen';
import VectorSource from 'ol/source/Vector';
import { Type } from 'ol/geom/Geometry';
import { fromLonLat, toLonLat, transform } from 'ol/proj';
import { defaults as defaultControls } from 'ol/control';

import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Collection, Feature } from 'ol';
import { Geometry, Point, LineString } from 'ol/geom';
import { Circle, Fill, Icon, Stroke, Style } from 'ol/style';
import { Coordinate } from 'ol/coordinate';
import { altKeyOnly, altShiftKeysOnly, always, click, never, pointerMove, singleClick } from 'ol/events/condition.js'
import { LayerService, LayersStateService, NodeService, } from '@app/shared/services/';

import {
    BehaviorSubject,
    Observable,
    Subscription,
    map,
    timer,
    switchMap,
    of,
    takeWhile,
    shareReplay,
    takeUntil, Subject
} from 'rxjs';
import { Item, UnitedItem, ItemLayer, ItemRaster, DeviceTypes, PlotFeature, NodeFeature } from '@app/shared/models';
// import { features } from 'process';
import { LayerTypes } from "@shared/models/layer-types";
// import { debug } from 'console';
import {
    Draw,
    Modify,
    Select,
    Snap,
} from 'ol/interaction';
import { debounceTime, filter, tap } from "rxjs/operators";
import Layer from 'ol/layer/Layer';
import OSM from 'ol/source/OSM';
import { LayerEditService } from '@app/modules/main/modules/layer-edit-panel/services/layer-edit.service';
import { MapSearchService } from './mapSearch.service';
import { InteractionType } from '../models';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { getArea } from 'ol/extent';
import { unByKey } from 'ol/Observable';
import { EventsKey } from 'ol/events';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { getLength } from 'ol/sphere';
import { SettingsService } from '@app/shared/services/settings.service';

export interface TileSource {
    name: string;
    source: OSM | BingMaps; // | StamenSource
}

@Injectable({
    providedIn: 'root'
})
export class MapService implements OnInit, OnDestroy {

    private searchableMarkerLayer: VectorLayer<VectorSource<Geometry>>;
    private searchableContourLayer: VectorLayer<VectorSource<Geometry>>;
    public sonarView = new BehaviorSubject<boolean>(false);
    public sonarView$ = this.sonarView.asObservable();

    public modifyInteraction: Modify;
    public drawInteraction: Draw;
    public snapInteraction: Snap;
    public selectInteraction: Select;
    public selectAllInteraction: Select = null;

    public featureDragAndDroped = '';
    public removeDrawFeature: boolean = false;
    static drawLineEnded: boolean = true;
    static onKey: EventsKey

    // private timerRefresh$ = new Subject<number | null>();
    // private readonly deselectTimer$ = this.timerRefresh$.pipe(
    //     debounceTime(300),
    //     switchMap(seconds => seconds
    //         ? timer(0, 1000).pipe(
    //             map(i => seconds - i),
    //             takeWhile(timeLeft => timeLeft >= 0),
    //             map(timeLeft => timeLeft === 0
    //                 ? null
    //                 : timeLeft)
    //         )
    //         : of(null)
    //     ),
    //     filter(timeLeft => timeLeft === null),
    //     shareReplay({ refCount: true, bufferSize: 1 })
    // );

    public zoom: 10;

    public center: any = [37.764079, 55.759886];

    public tileSources: TileSource[] = [
        // { name: 'None', source: null },
        { name: 'OSM', source: new OsmSource() },
        {
            name: 'BING Спутник', source: new BingMaps({
                key: 'Amg8ISd26zW7qrx1StqZxn-vFcW-X1xsNviwcaQs3kZT_HVYMirnkhb3LrllvKiY',
                imagerySet: 'Aerial',
                placeholderTiles: false
            })
        },
        // { name: 'Яндекс Спутник', source: new StamenSource({ layer: 'toner' }) },
    ];

    // public selectedTileSource = this.tileSources[0];
    // public selectedTileSourceSubject = new BehaviorSubject(this.selectedTileSource);
    public selectedTileSource: TileSource
    public selectedTileSourceSubject = new BehaviorSubject<TileSource>(null);
    public selectedTileSource$: Observable<TileSource> = this.selectedTileSourceSubject;


    private timerRefresh$ = new Subject<number | null>();
    // public timerRefresh$ = new Subject<number | null>();
    private readonly deselectTimer$ = this.timerRefresh$.pipe(
        // public readonly deselectTimer$ = this.timerRefresh$.pipe(
        debounceTime(300),
        switchMap(seconds => seconds
            ? timer(0, 1000).pipe(
                map(i => seconds - i),
                takeWhile(timeLeft => timeLeft >= 0),
                map(timeLeft => timeLeft === 0
                    ? null
                    : timeLeft)
            )
            : of(null)
        ),
        filter(timeLeft => timeLeft === null),
        shareReplay({ refCount: true, bufferSize: 1 })
    );


    public vectorSources: any[] = [];

    public map: Map;
    private readonly tileLayer: TileLayer<OsmSource>;
    private readonly vectorLayer: VectorLayer<any>;

    // private subcriptionLayerState: Subscription = null;


    constructor(
        private layersStateService: LayersStateService,
        private layerEditService: LayerEditService,
        private mapSearchService: MapSearchService,
        private settingsService: SettingsService,
        // private mapDataService: MapDataService,
        private readonly _destroy$: ImusDestroyService,
    ) {
        this.tileLayer = new TileLayer();
        // this.vectorLayer = new VectorLayer<any>();

        this.initMap();

        this.setTileSource();

        /** Подписываемся на таймер, по которому выключаем маркер и контуры */
        this.deselectTimer$.subscribe(() => {
            this.removeMarker();
            this.removeContour();
        });

        this.layersStateService.layerUpdated$.pipe(
            tap(layerID => {
                if (this.layersStateService.editableLayerId)
                    this.updateInteractions()
            }),
            takeUntil(this._destroy$)
        )
            .subscribe()

        this.selectedTileSource$.pipe(
            tap(tileSource => this.settingsService.updateSettings({
                backgroundMap: tileSource.name
            })),
            takeUntil(this._destroy$)
        )
            .subscribe()
    }

    public addSearchableMarker(layer: VectorLayer<VectorSource<Geometry>>): void {
        this.removeMarker();
        this.searchableMarkerLayer = layer;
        this.map.addLayer(this.searchableMarkerLayer);
        this.timerRefresh$.next(5);
    };

    public addSearchableContour(layer: VectorLayer<VectorSource<Geometry>>): void {
        this.removeContour();
        this.searchableContourLayer = layer;
        this.map.addLayer(this.searchableContourLayer)
        this.timerRefresh$.next(5);
    };

    /**
     *
     * @param layerID ID слоя куда добавляются Interactions, если ID не указан - добавляется ко всем слоям, например Select
     * @param typeSelect Тип геометрии 'Point', 'LineString' и т.д. - по умолчанию 'Point'
     * @param interactions Массив Interactions для добавления - по умолчанию пустой
     */

    public addInteractions(layerID?: number, typeSelect: Type = 'Point', interactions: InteractionType[] = []) {
        let layer: Layer
        let source: VectorSource
        // let pointFeatures : Feature[]

        // debugger
        // if (!source) {
        layer = this.map
            .getAllLayers()
            .find(layer => (
                layer.getProperties()['id'] &&
                layer.getProperties()['id'] == layerID
            ))
        if (layer) source = <VectorSource>layer.getSource()
        // }

        // if (source) pointFeatures = source.getFeatures().filter(feature => feature.getGeometry().getType() == 'Point')

        if (!this.modifyInteraction && layer) {
            this.modifyInteraction = new Modify({
                source: source,
                // features: new Collection(pointFeatures)
                // Можно модифицировать только перетаскивая за точки
                // condition: (event: MapBrowserEvent<UIEvent>) => {
                //     return true
                //     let returnValue = false
                //     this.map.forEachFeatureAtPixel(event.pixel, function(featureLike, layerModify, geometry) {
                //         // console.log('featureLike', featureLike)
                //         // console.log('layer', layerModify)
                //         // console.log('geometry', geometry, geometry.getCoordinates())
                //         // console.log(event.coordinate)
                //         if (layerModify
                //             && ((geometry.getType() == 'Point'
                //             //     (<VectorSource>layerModify.getSource())
                //             // .getFeaturesAtCoordinate(event.coordinate)
                //             // .some(feature => feature.getGeometry().getType() == 'Point')
                //             )
                //             || (geometry.getType() == 'LineString'
                //                 && geometry.getCoordinates().some(coord => coord[0] == event.coordinate[0] && coord[1] == event.coordinate[1])
                //             ))
                //             ) returnValue = true
                //     }, {
                //         layerFilter: (layerFilter) => {
                //             return !!layerFilter.getProperties()['id'] &&
                //             layerFilter.getProperties()['id'] == layerID
                //         }
                //     })
                //     return returnValue
                // },
                condition: always,
                // Условие при котором вставляется промежуточная точка
                insertVertexCondition: altKeyOnly,
                //     (event) => {
                //     let altKey = altKeyOnly(event)
                //     let cclick = click(event)
                //     console.log('altKey, sClick', altKey, cclick)
                //     return altKey && cclick
                // },
                // Условие при котором удаляется промежуточная точка
                deleteCondition: altShiftKeysOnly//never //altShiftKeysOnly && click
            });
            this.modifyInteraction.on('modifyend', (event) => {
                // console.log('modifyend', event)
                // event.features.forEach(feature => console.log('feature', feature , feature.getProperties()['id']))
                for (let feature of event.features.getArray()) {
                    if (feature.getGeometry().getType() == 'LineString') {
                        let plot = this.layersStateService.getData().getPlotByPlotId(feature.getProperties()['id'])
                        if (plot) {
                            let coords = (feature.getGeometry() as LineString)
                                    .getCoordinates()
                                    .map((coords) => <[number, number]>toLonLat(coords))
                            plot.coords = coords.slice(1,-1);
            
                            plot.startNode.coords = coords[0]
                            plot.endNode.coords = coords.at(-1)

                            // plot.coords =
                            //     (feature.getGeometry() as LineString) //<LineString>
                            //         .getCoordinates()
                            //         .map(coords => [toLonLat(coords)[0], toLonLat(coords)[1]]).slice(1,-1) as [number, number][]
                                    
                            plot.length = Math.floor(getLength(feature.getGeometry() as LineString) * 100) / 100; //, {projection: 'EPSG:4326'}

                            this.layerEditService.addPlotToEdit(plot)
                        }
                    }
                }
            })
        }

        // this.map.removeInteraction(this.selectAllInteraction);

        // if (this.selectAllInteraction.getActive()) this.selectAllInteraction.setActive(false)

        const style = new Style({
            image: new Circle({
                fill: new Fill({
                    color: 'rgba(200,35,35,1)',
                }),
                stroke: new Stroke({
                    color: '#000000',
                    width: 2,
                }),
                radius: 8,
            }),
            stroke: new Stroke({
                color: 'rgba(200,35,35,1)',
                width: 3,
            }),

        })


        if (!this.selectInteraction && layer) this.selectInteraction = new Select({
            layers: [layer],
            condition: (mapBrowserEvent: MapBrowserEvent<UIEvent>) => {
                if (this.featureDragAndDroped == "pointerup") this.featureDragAndDroped = ""
                if (mapBrowserEvent.type == "pointerdrag") this.featureDragAndDroped = "pointerdrag"
                if (mapBrowserEvent.type == "pointerup" && this.featureDragAndDroped == "pointerdrag") this.featureDragAndDroped = "pointerup"
                // console.log('mapBrowserEvent', mapBrowserEvent)
                // debugger

                return click(mapBrowserEvent) || this.featureDragAndDroped == 'pointerup'
            },
            toggleCondition: never,
            style: style
        });

        if (!this.selectAllInteraction) this.selectAllInteraction = new Select({
            condition: click,
            toggleCondition: never,
            style: style,
            hitTolerance: 5
        });


        if (
            interactions.length == 0 ||
            interactions.find((inter) => inter == InteractionType.draw)
        ) {
            if (
                !this.drawInteraction &&
                layer &&
                typeSelect == 'LineString'
            ) {
                // console.log('source',source)
                this.drawInteraction = new Draw({
                    source: source,
                    type: typeSelect, //typeSelect.value,
                    // рисуем линии только между точками слоя
                    condition: (event) => {
                        let returnValue = false;
                        this.map.forEachFeatureAtPixel(
                            event.pixel,
                            (featureLike) => {
                                // console.log('drawInteraction condition', featureLike)
                                // console.log(featureLike.getProperties()['id'], MapService.drawLineEnded)
                                if (source.getFeaturesAtCoordinate(event.coordinate).filter(
                                    feature => feature.getGeometry().getType() == 'Point'
                                ).length > 0
                                    //Добавление линии, когда конечная точка в другом слое
                                    // || (!MapService.drawLineEnded &&
                                    //     featureLike
                                    //         .getGeometry()
                                    //         .getType() == 'Point' &&
                                    //     !!featureLike.getProperties()['id'])
                                ) returnValue = true;
                            }
                        );
                        return returnValue;
                    },
                    freehandCondition: never,
                    maxPoints: 2,
                });

                this.drawInteraction.setProperties({ type: 'LineString' });

                this.drawInteraction.on('drawend', (event) => {
                    // console.log('drawend')
                    // MapService.removeDrawLine = true
                    MapService.drawLineEnded = true;
                    this.removeDrawFeature = true;
                    // source.removeFeature
                });

                this.drawInteraction.on('drawstart', (event) => {
                    // console.log('drawstart')
                    MapService.drawLineEnded = false;
                    // source.removeFeature
                });

                // source.on("addfeature", (event) => {
                //     // console.log('addfeature', event)
                //     // if (MapService.removeDrawLine) source.removeFeature(event.feature)
                //     // MapService.removeDrawLine = false

                //     if (this.removeDrawFeature) {
                //         source.removeFeature(event.feature)
                //         this.removeDrawFeature = false
                //     }

                //     // console.log('drawInteraction', this.drawInteraction)
                // })

                // Draw.prototype.finishDrawing = function() {}

                // this.drawInteraction.on ('drawabort', (event) => {
                //     console.log('drawabort')
                // })
                // this.drawInteraction.on ('drawend', (event) => {
                //     (<Draw>event.target).abortDrawing();
                //     // (<Draw>event.target).getOverlay()
                //     console.log('event.target', event.target)
                //     console.log('source', source)
                //     // event.feature = undefined
                //     // event.preventDefault()
                //     // event.stopPropagation()
                // })
            } else {
                // console.log('source',source)
                this.drawInteraction = new Draw({
                    source: source,
                    type: typeSelect, //typeSelect.value,
                    freehandCondition: never,
                });

                this.drawInteraction.setProperties({ type: 'Point' });

                this.drawInteraction.on('drawend', (event) => {
                    this.removeDrawFeature = true;
                });

            }

            MapService.onKey = source.on('addfeature', (event) => {
                if (this.removeDrawFeature) {
                    source.removeFeature(event.feature);
                    this.removeDrawFeature = false;
                }
            });

        }

        // if (this.drawInteraction) {
        //     MapService.onKey = source.on('addfeature', (event) => {
        //         if (this.removeDrawFeature) {
        //             source.removeFeature(event.feature);
        //             this.removeDrawFeature = false;
        //         }
        //     });
        // }
        // else if (MapService.onKey) {
        //     unByKey(MapService.onKey)
        // }


        if (!this.snapInteraction && layer) {
            let allPointsSource = new VectorSource()
            if (typeSelect === 'Point') this.map.getAllLayers()
                .filter(layer => layer.getSource() instanceof VectorSource)
                .forEach(layer => allPointsSource.addFeatures((layer.getSource() as VectorSource).getFeatures()))

            this.snapInteraction = new Snap({
                source: typeSelect === 'Point'
                    ? allPointsSource
                    : source,
                pixelTolerance: 5
            });
        }

        // let interations = this.map.getInteractions()
        // console.log(interations.getKeys())
        // debugger

        if (this.selectAllInteraction && (
            interactions.length == 0 ||
            interactions.find(inter => inter == InteractionType.selectAll)
        )) this.map.addInteraction(this.selectAllInteraction);

        if (this.selectInteraction && (
            interactions.length == 0 ||
            interactions.find(inter => inter == InteractionType.select)
        )) this.map.addInteraction(this.selectInteraction);

        if (this.drawInteraction && (
            interactions.length == 0 ||
            interactions.find(inter => inter == InteractionType.draw)
        )) this.map.addInteraction(this.drawInteraction);

        if (this.modifyInteraction && (
            interactions.length == 0 ||
            interactions.find(inter => inter == InteractionType.modify)
        )) this.map.addInteraction(this.modifyInteraction);

        if (this.snapInteraction && (
            interactions.length == 0 ||
            interactions.find(inter => inter == InteractionType.snap)
        )) this.map.addInteraction(this.snapInteraction);

    }

    public removeInteractions(interactions: InteractionType[] = []) {
        if (
            this.snapInteraction
            && (
                interactions.length == 0
                || interactions.find(inter => inter == InteractionType.snap)
            )
        ) {
            this.map.removeInteraction(this.snapInteraction);
            this.snapInteraction = null;
        }
        if (this.modifyInteraction
            && (
                interactions.length == 0 ||
                interactions.find(inter => inter == InteractionType.modify)
            )
        ) {
            this.map.removeInteraction(this.modifyInteraction);
            this.modifyInteraction = null;
        }
        // this.map.removeInteraction(this.modifyInteraction)
        if (this.drawInteraction
            && (
                interactions.length == 0 ||
                interactions.find(inter => inter == InteractionType.draw))
        ) {
            this.map.removeInteraction(this.drawInteraction);
            this.drawInteraction = null;
        }

        // if (!this.selectAllInteraction.getActive()) this.selectAllInteraction.setActive(true)

        if (this.selectInteraction
            && (
                interactions.length == 0 ||
                interactions.find(inter => inter == InteractionType.select))
        ) {
            this.map.removeInteraction(this.selectInteraction);
            this.selectInteraction = null;
        }

        if (this.selectAllInteraction
            && (
                interactions.length == 0 ||
                interactions.find(inter => inter == InteractionType.selectAll))
        ) {
            this.map.removeInteraction(this.selectAllInteraction);
            //   this.selectAllInteraction = null;
        }

        // this.map.addInteraction(this.selectAllInteraction);

        // this.map.removeInteraction(this.drawInteraction)
    }

    public updateInteractions() {

        console.log('updateInteractions');

        if (this.layersStateService.editableLayerId) {
            let interArr: InteractionType[] = []
            let drawType: Type = 'Point'
            this.map.getInteractions().getArray().forEach(item => {
                if (item instanceof Select) interArr.push(InteractionType.select)
                if (item instanceof Draw) {
                    interArr.push(InteractionType.draw)
                    if ((<Draw>item).getProperties()['type'] == 'LineString') drawType = 'LineString'
                }
                if (item instanceof Modify) interArr.push(InteractionType.modify)
                if (item instanceof Snap) interArr.push(InteractionType.snap)
            })
            // console.log('getInteractions',this.map.getInteractions())
            // console.log('updateInteractions',interArr)
            this.removeInteractions()
            this.addInteractions(this.layersStateService.editableLayerId, drawType, interArr)
        }
    }

    ngOnInit(): void {
        // this.layersStateService.setData()
        // console.log('MapService ngOnInit')
    }

    ngOnDestroy(): void {
        // this.subcriptionLayerState.unsubscribe();
        // if (this.subcriptionLayerState !== null) {
        //     this.subcriptionLayerState.unsubscribe();
        //     this.subcriptionLayerState = null;
        // }
    }

    public initMap() {
        if (!this.map) {
            this.map = new Map({
                // interactions: defaultInteractions().extend([new PinchZoom()]),
                layers: [
                    // this.tileLayer
                    // this.tileLayer, this.vectorLayer, vectorLayer
                ],
                view: new View({
                    constrainResolution: true,
                }),
                controls: [],
                // defaultControls().extend([
                //   new Attribution(),
                //   // new ZoomToExtent({ extent: this.extent }),
                //   new FullScreen(),
                // ]),
            });
        } else {
            // this.map.setLayers([this.tileLayer])
            // this.map.setLayers([])
            this.map.getControls().clear()
        }
    }

    public deleteMap() {
        this.map = undefined
    }

    public addTileLayer() {
        //Добавляет слой по нулевому индексу, как самый нижний слой
        let layers = this.map.getLayers()
        if (!layers.getArray().find(layer => layer == this.tileLayer))
            this.map.getLayers().insertAt(0, this.tileLayer)
    }

    public setTileLayer() {
        this.map.setLayers([this.tileLayer])
    }

    /**
     * Удаляет выбранную промежуточную точку Point/Vertex из линии Plot
     * @returns true если точка была удалена, иначе false
     */

    public deleteSelectedPoint(): boolean {
        return this.modifyInteraction.removePoint()
    }

    createVectorLayer(coordinates, style, zIndex, item: UnitedItem): VectorLayer<any> {
        // new feature to put on a layer
        const feature = new Feature({
            // geometry: new Point(fromLonLat([37.600739, 55.751952]))
            geometry: new Point(fromLonLat(coordinates)),
        });

        feature.setStyle(style);

        const source = new VectorSource({
            // wrapX: false,
            features: [feature],
            // features: [this.dubna, this.moscow] // can be several
        });

        const vectorLayer = new VectorLayer({
            source: source,
            zIndex: zIndex,
            visible: item.checked,
            opacity: item.hasFilters ? (item as ItemRaster).filters.transparency / 100 : 1,
        });
        // vectorLayer.setZIndex(zIndex)
        return vectorLayer;
    }

    getMap() {
        return this.map;
    }

    updateView(zoom = 2, center: [number, number] | Coordinate = [0, 0], duration: number = 1000): void {
        // console.log('updateView', zoom, center, duration);
        this.map.getView().setZoom(zoom);
        this.map.getView().animate({ center: fromLonLat(center) });
    }

    updateCenter(center: [number, number] | any = [0, 0], duration: number = 1000) {
        this.map.getView().fit(center, { duration });
    }

    updateSize(target = 'map'): void {
        this.map.setTarget(target);
        this.map.updateSize();
    }

    setTileSource(source = this.selectedTileSource): void {
        if (!source) {
            let i = this.tileSources.findIndex(tileSource => tileSource.name == this.settingsService.settingsValue?.backgroundMap)
            source = i > -1 ? this.tileSources[i] : this.tileSources[0]
        }
        this.selectedTileSource = source;
        this.tileLayer.setSource(<OSM>source.source);
        this.selectedTileSourceSubject.next(source);
    }

    setVectorSource(source: any): void {
        this.vectorLayer.setSource(source.source);
        this.map
            .getView()
            .fit(this.vectorLayer.getSource().getExtent(), { duration: 1000 });
    }

    public zoomIn() {
        // this.map.getView().setZoom(this.map.getView().getZoom() + 1);
        this.map.getView().animate({
            zoom: this.map.getView().getZoom() + 1,
            duration: 250
        });
    }

    public zoomOut() {
        // this.map.getView().setZoom(this.map.getView().getZoom() - 1);
        this.map.getView().animate({
            zoom: this.map.getView().getZoom() - 1,
            duration: 250
        });
    }

    public zoomToExtent(extent: any) {
        if (typeof extent == 'string') {
            extent = JSON.parse(extent);
        }
        if (extent.length < 4) {
            throw new Error(`Ошибка формата данных Extent: Мало значений`)
            return false;
        }
        this.getMap().getView().fit(extent, {
            // size: this.getMap().getSize(),
            padding: [200, 200, 200, 200],
            duration: 1000,
            maxZoom: 20
        });
    }

    public zoomToDevice(device: DeviceTypes.IDevice) {
        // console.log('zoomToDevice', device);
        if (
            device.plot &&
            device.plot.properties 
            //TODO: Нужны ли условия с coords
            // device.plot.properties.coords &&
            // device.plot.properties.coords[0]
        ) {
            this.zoomToPlot(device.plot);
        } else if (
            device.node &&
            device.node.properties &&
            device.node.properties.lat &&
            device.node.properties.lon
        ) {
            this.zoomToNode(device.node);
        } else if (device.lat && device.lon) {
            this.setMarkerAfterZoom([device.lon, device.lat]);
            this.sonarShow();
            this.updateView(18, [
                parseFloat(device.lon),
                parseFloat(device.lat),
            ]);
        }
    }

    public zoomToPlot(plot: PlotFeature) {
        // this.setMarkerAfterZoom(plot.properties.coords[0]);
        this.mapSearchService.highlightedPlot.next(plot);
        setTimeout(() => {
            this.mapSearchService.highlightedPlot.next(undefined)
        }, 3000);

        this.sonarShow();
        let coords: number[][] = []
        coords.push([+plot.properties.start_node.properties.lon,+plot.properties.start_node.properties.lat])
        coords = [...coords,...plot.properties.coords]
        coords.push([+plot.properties.end_node.properties.lon,+plot.properties.end_node.properties.lat])
        const pointsCountDelay = 3000 / (coords.length + 1);
        
        //TODO: timeouts to RXJS
        setTimeout(() => {
            this.updateView(16, coords[0], 0);
        }, 0)
        for (let key = 0; coords.length > key; key++) {
            if (key != 0) {
                //TODO: timeouts to RXJS
                setTimeout(() => {
                    this.updateView(16, coords[key], pointsCountDelay);
                }, key * pointsCountDelay * 2);
            }
        }
    }

    public zoomToNode(node: NodeFeature) {
        this.mapSearchService.highlightedNode.next(node);
        setTimeout(() => {
            this.mapSearchService.highlightedNode.next(undefined)
        }, 3000);
        // this.setMarkerAfterZoom([
        //     parseFloat(node.properties.lon),
        //     parseFloat(node.properties.lat),
        // ]);
        this.sonarShow();
        this.updateView(18, [
            parseFloat(node.properties.lon),
            parseFloat(node.properties.lat),
        ]);
    }

    setMarkerAfterZoom(coords: any) {

        /** Добавление маркера */
        const marker = new VectorLayer({
            source: new VectorSource({
                features: [
                    new Feature({
                        geometry: new Point(
                            fromLonLat(coords)
                        ),
                    }),
                ],
            }),
            style: new Style({
                image: new Icon({
                    src: '../assets/img/icons/marker-icon.png',
                    anchor: [0.5, 1],
                }),
                zIndex: 101,
            }),
        });
        this.addSearchableMarker(marker);

    }

    private sonarShow() {
        this.sonarView.next(false);
        setTimeout(() => {
            this.sonarView.next(true);
            setTimeout(() => {
                this.sonarView.next(false);
            }, 6000);
        }, 1000);
    }

    private removeMarker(): void {
        if (this.searchableMarkerLayer) {
            this.map.removeLayer(this.searchableMarkerLayer);
        }
        this.searchableMarkerLayer = null;
    }

    private removeContour(): void {
        if (this.searchableContourLayer) {
            this.map.removeLayer(this.searchableContourLayer)
        }
        this.searchableContourLayer = null;
    }

}
