<div class="property-list" *ngIf="node$ | async as item">
    <div class="property-item">
        <app-property-title>Название точки</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'name'" [disable]="form.controls.name.invalid" #editor class="imus-row">
                    <span class="content">{{item?.name
                        ? item?.name
                        : 'Нет данных'}}</span>
            <div class="imus-row-item editor">
                <app-imus-input #fieldName placeholder="Введите название точки" label="Название точки" imusRequired
                                formControlName="name" observationEvent
                                (eventTrigger)="editField(item, fieldName.value, 'name')"
                                >
                                <!-- (eventToEdit)="setEvent($event)" -->
                </app-imus-input>
                <field-errors formFieldName="name"></field-errors>
            </div>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Тип объекта</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'node_type_id'" class="imus-row">
                    <span class="content">
                      {{item?.nodeType?.id !== null
                        ? item.nodeType?.russian
                        : 'Нет данных'}}
                    </span>
    <app-imus-select #select *ngIf="item?.nodeType" label="Тип объекта" class="editor" formControlName="node_type_id"
                             (selectionChange)="editField(item, $event, 'node_type_id')"
                             [specification]="{ key: 'id', value: 'russian'}"
                             [items]="(nodeTypes$ | async)! | sortByLayerType : (editableLayer$ | async).layerTypeId"></app-imus-select>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Координаты</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'lon' || (current$ | async) === 'lat'" [disable]="form?.controls.lon.invalid || form.controls.lat.invalid" #editor class="imus-row">
                    <span class="content">{{item
                        ? item.lat + ', ' + item.lon
                        : 'Нет данных'}}</span>
            <div *ngIf="item" class="editor">
                <div class="imus-row">
                    <div class="imus-row-item">
                        <app-imus-input #field2 imusRequired placeholder="Широта" title="Введите широту"
                                        label="Координаты lat" formControlName="lat" [mask]="coordinatesMask.lat" observationEvent
                                        (eventTrigger)="editField(item, field2.value, 'lat')"></app-imus-input>
                        <field-errors formFieldName="lat"></field-errors>
                    </div>
                </div>
                <div class="imus-row">
                    <div class="imus-row-item">
                        <app-imus-input #field1 imusRequired placeholder="Долгота" title="Введите долготу"
                                        label="Координаты lon" formControlName="lon" [mask]="coordinatesMask.lon" observationEvent
                                        (eventTrigger)="editField(item, field1.value, 'lon')"></app-imus-input>
                        <field-errors formFieldName="lon"></field-errors>
                    </div>
                </div>
            </div>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Режим работы</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'on'" class="imus-row">
            <span class="content">{{operationsValue(item)}}</span>
            <app-imus-select label="Режим работы" class="editor" formControlName="on"
                             (selectionChange)="editField(item, $event, 'on')"
                             [specification]="{ key: 'key', value: 'value'}"
                             [items]="operationsList$ | async"></app-imus-select>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Материал</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'material_id'" class="imus-row">
                    <span class="content">
                      {{item?.material?.properties?.russian
                        ? item.material.properties?.russian
                        : 'Нет данных'}}
                    </span>
            <app-imus-select #select label="Материал" class="editor" formControlName="material_id"
                             (selectionChange)="editField(item, $event, 'material_id')"
                             [specification]="{ key: 'id', value: 'russian'}"
                             [items]="categoryList.getOptionsByCategory(codeCategory.material)"></app-imus-select>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Отметка поверхности</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'height_mark'" class="imus-row">
            <span class="content">
              {{item?.height_mark
                ? item?.height_mark
                : 'Нет данных'}}
            </span>
            <div class="imus-row-item editor">
                <app-imus-input #fieldHeightMark type="number" placeholder="Введите отметку поверхности"
                                label="Отметка поверхности" imusRequired formControlName="height_mark" observationEvent
                                (eventTrigger)="editField(item, fieldHeightMark.value, 'height_mark')"></app-imus-input>
            </div>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Глубина колодца</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'well_depth'" class="imus-row">
            <span class="content">
              {{item?.well_depth
                ? item?.well_depth
                : 'Нет данных'}}
            </span>
            <div class="imus-row-item editor">
                <app-imus-input #fieldWell type="number" placeholder="Введите глубину колодца"
                                label="Глубина колодца" imusRequired formControlName="well_depth" observationEvent
                                (eventTrigger)="editField(item, fieldWell.value, 'well_depth')"></app-imus-input>
            </div>
        </app-switch-editor>
    </div>
<!--    <div class="property-item">-->
<!--        <app-property-title>Материал</app-property-title>-->
<!--        <app-switch-editor class="imus-row">-->
<!--                    <span class="content">-->
<!--                      {{item?. !== null-->
<!--                        ? item.nodeType.id == 2-->
<!--                            ? 'Точка'-->
<!--                            : 'Колодец'-->
<!--                        : 'Нет данных'}}-->
<!--                    </span>-->
<!--            <app-imus-select #select *ngIf="item?.nodeType" label="Тип объекта" class="editor" formControlName="material"-->
<!--                             (selectionChange)="nodeTypeEdit($event, item)"-->
<!--                             [specification]="{ key: 'id', value: 'name'}"-->
<!--                             [items]="categoryList.getOptionsByCategory(codeCategory.material)"></app-imus-select>-->
<!--        </app-switch-editor>-->
<!--    </div>-->
    <div class="property-item">
        <app-property-title>Примечание</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'notes'" [disable]="form.controls.name.invalid" #editor class="imus-row">
                    <span class="content">{{item?.notes
                        ? item?.notes
                        : 'Нет данных'}}</span>
            <div class="imus-row-item editor">
                <app-text-area #fieldNotes [title]="'Описание'" formControlName="notes" observationEvent
                               (eventTrigger)="editField(item, fieldNotes.value, 'notes')"></app-text-area>
            </div>
        </app-switch-editor>
    </div>
</div>
