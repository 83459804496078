import { ChangeDetectionStrategy, Component, EventEmitter, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTableModule } from "@angular/material/table";
import { AlertService, UserService } from "@shared/services";
import { AccountService } from "@app/modules/account/services/account.service";
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    debounceTime,
    filter,
    finalize,
    map, Observable,
    of,
    ReplaySubject,
    shareReplay,
    startWith,
    switchMap,
    takeUntil, tap
} from "rxjs";
import { ButtonComponentSettings } from "@shared/components/button/button.component";
import { MatIconModule } from "@angular/material/icon";
import { MatSortModule, Sort } from "@angular/material/sort";
import { SharedModule } from "@shared/shared.module";
import { Accounts } from "@shared/models/contractors";
import { PageEvent } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import {
    ConfirmDialogComponent,
    ConfirmDialogSettings
} from "@shared/components/confirm-dialog/confirm-dialog.component";
import { ImusDestroyService } from "@services/destroy.service";
import { AccountsService } from "@app/modules/account/services/accounts.service";
import {
    AddGuestDialogComponent
} from "@app/modules/account/pages/profile/components/guests/components/add-guest.dialog/add-guest.dialog.component";
import { InputType} from "@shared/input-type";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { UserAccountsPage } from "@shared/models";
import { ContractorService } from "@app/modules/account/services/contractor";
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, RouterLink } from '@angular/router';

/** Гости */
@Component({
    selector: 'app-guests',
    standalone: true,
    imports: [
        CommonModule,
        MatProgressBarModule,
        MatTableModule,
        MatIconModule,
        MatSortModule,
        SharedModule,
        ReactiveFormsModule,
        RouterLink,
    ],
    templateUrl: './guests.component.html',
    styleUrls: ['./guests.component.scss'],
    providers: [ImusDestroyService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuestsComponent implements OnInit {
    public accounts: UserAccountsPage;
    public component: "contractor" | "profile" = null;
    public readonly searchEmail = new FormControl('');
    private readonly userService = inject(UserService);
    private readonly accountService = inject(AccountService);
    private readonly accountsService = inject(AccountsService);
    private readonly contractorService = inject(ContractorService);
    private readonly alertService = inject(AlertService);
    private readonly matDialog = inject(MatDialog);
    private readonly destroy$ = inject(ImusDestroyService);
    private readonly route = inject(ActivatedRoute);
    private readonly _refresh = new EventEmitter<void>();
    private readonly _sortSettings$ = new BehaviorSubject<Sort | null>(null);
    public readonly pageSettings$ = new ReplaySubject<{ pageIndex: number, pageSize: number }>(1);
    public readonly isLoading$ = new BehaviorSubject<boolean>(false);
    public readonly component$ =  this.contractorService.contractorForGuests$.pipe(map(guest => guest.component))
    public readonly accounts$ = this._refresh.pipe(
        switchMap(() => this.contractorService.contractorForGuests$),
        filter(guest => !!guest),
        switchMap(guest => combineLatest([
            this.pageSettings$,
            this._sortSettings$.pipe(
                map(sortSettings => {
                    if (!sortSettings) {
                        return {}
                    }
                    const key = `orderBy[${sortSettings.active}]`
                    return { [key]: sortSettings.direction }
                })
            ),
        ]).pipe(
            switchMap(([pageSettings, sortSettings]) => this.userService.getUserAccountsView(Object.assign({}, {
                // switchMap(([pageSettings, sortSettings]) =>  this.accountsService.getAccounts(Object.assign({},{
                ['where[contractor_id]']: guest.id,
                ['where[is_main]']: false,
                'per_page': pageSettings.pageSize,
                page: pageSettings.pageIndex
            }, sortSettings)) as Observable<UserAccountsPage>),
            catchError(() => of(null))
        )),
        shareReplay({ refCount: true, bufferSize: 1 })
    );
    // public readonly accounts$ = combineLatest([
    //     this.contractorService.contractor$,
    //     this.pageSettings$,
    //     this._sortSettings$
    //         .pipe(
    //             map(sortSettings => {
    //                 if (!sortSettings) return {};
    //                 const key = `orderBy[${sortSettings.active}]`;
    //                 return { [key]: sortSettings.direction };
    //             })
    //         ),
    //     this._refresh,
    //     ])
    //     .pipe(
    //         filter(([contractor]) => !!contractor),
    //         switchMap(([contractor, pageSettings, sortSettings, _]) =>
    //             this.userService.getUserAccountsView(Object.assign(
    //                     {},
    //                     {
    //                         ['where[contractor_id]']: contractor.id,
    //                         ['where[is_main]']: false,
    //                         per_page: pageSettings.pageSize,
    //                         page: pageSettings.pageIndex,
    //                     },
    //                     sortSettings
    //                 )
    //             ) as Observable<UserAccountsPage>),
    //         catchError(() => of(null)),
    //         shareReplay({ refCount: true, bufferSize: 1 })
    //     );

    public readonly displayed$ = new BehaviorSubject([
        { name: 'user_name', title: 'ФИО' },
        { name: 'user_email', title: 'Email' },
        { name: 'user_phone', title: 'Телефон' },
        { name: 'account_type_descr', title: 'Тип основного аккаунта' },
        { name: 'contractor_descr', title: 'Основная Компания' },
        { name: 'start_date', title: 'Дата подключения' },
        { name: 'end_date', title: 'Дата отключения' },
    ]);
    public readonly displayedColumns$ = this.displayed$.pipe(
        map((items) => items.map((o) => o.name))
    );

    public readonly buttonType = ButtonComponentSettings.ButtonType;
    // private readonly contractorsAccess$ = inject(UserService).contractorsAccess$;
    // private readonly accountAccess$ = inject(UserService).accountsAccess$;
    public readonly access$ = this.component$
        .pipe(
            switchMap(component => {
                // if (component === 'profile') return this.accountAccess$;
                if (component === 'profile') {
                    this.component = 'profile';
                    return this.userService.getAccountsAccess();
                }
                // if (component === 'contractor') return this.contractorsAccess$;
                if (component === 'contractor') {
                    this.component = 'contractor';
                    const currentDisplayed = this.displayed$.value;
                    const updatedDisplayed = [
                        { name: 'user_id', title: 'ID' },
                        ...currentDisplayed
                    ];
                    this.displayed$.next(updatedDisplayed);
                    return this.userService.getContractorsAccess();
                }
                return of(null)
            }),
            shareReplay({bufferSize: 1, refCount: true})
        )



    ngOnInit(): void {
        this.pageSettings$.next({
            pageSize: 15,
            pageIndex: 1,
        });
        this.accounts$
            .pipe(
                tap(() => this.isLoading$.next(true)),
                tap((value) => (this.accounts = value)),
                finalize(() => this.isLoading$.next(false)),
                takeUntil(this.destroy$)
            )
            .subscribe(() => this.isLoading$.next(false));
        this._refresh.emit();
    }

    public closeAccount(acc: Accounts): void {
        this.matDialog
            .open(ConfirmDialogComponent, {
                    data: {
                        title: 'Внимание!',
                        text: `Вы действительно хотите закрыть гостевой аккаунт?`,
                    },
                    width: '500px'
                }
            ).afterClosed().pipe(
            switchMap(result => result === ConfirmDialogSettings.ConfirmDialogResult.ok
                ? this.accountsService.exitGuest(acc.id)
                : of(null)),
            catchError(() => {
                this.alertService.openSnackbar('Произошла ошибка при попытке закрытия аккаунта. Попробуйте позже');
                return of(null);
            }),
        ).subscribe(res => {
            if (res) {
                this.alertService.openSnackbar('Гостевой аккаунт закрыт');
            }
            this._refresh.emit();
        });
    }

    public sort(event: Sort) {
        this._sortSettings$.next(event.direction ? event : null);
    }

    public onPaginateData(event: PageEvent) {
        this.pageSettings$.next({
            pageSize: event.pageSize,
            pageIndex: event.pageIndex + 1
        })
    }

    public addGuest() {
        this.accountService.account$
            .pipe(
                switchMap(current =>
                    (this.accountsService.addGuest({
                        ['account_type_id']: current.account_type_id,
                        ['email']: this.searchEmail.value,
                        ['contractor_id']: current.contractor_id
                    }))),
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 404) this.alertService.openSnackbar('Гость с таким email уже добавлен');
                    else this.alertService.openSnackbar('Произошла ошибка при операции добавления гостя. Попробуйте позже');
                    return of(err);
                }),
                takeUntil(this.destroy$)
            )
            .subscribe(() => this._refresh.emit())

        // this.matDialog.open(AddGuestDialogComponent, {
        //     width: '900px'
        // }).afterClosed().pipe(
        //     takeUntil(this.destroy$)
        // ).subscribe(res => {
        //     if (res) {
        //         this._refresh.emit();
        //     }
        // })
    }

    protected readonly inputType = InputType;
}
