import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsEditPanelComponent } from './components/details-edit-panel/details-edit-panel.component';
import { MapModule } from '@app/modules/map/map.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import {
    NodesFilesViewComponent
} from "@app/modules/main/modules/details-info-panel/components/nodes-files.view/nodes-files.view.component";
import { UploadInspectionCardComponent } from './components/upload-inspection.card/upload-inspection.card.component';
import { UploadedInspectionFilesListComponent } from './components/uploaded-inspection-files.list/uploaded-inspection-files.list.component';
import { DetailsNodeViewComponent } from './components/details-node.view/details-node.view.component';
import { DetailsPlotViewComponent } from './components/details-plot.view/details-plot.view.component';
import { PropertyTitleComponent } from './components/property-title/property-title.component';
import { EditingFieldNameProvider } from "@app/modules/main/modules/details-info-panel/editing-field-name.provider";


@NgModule({
    declarations: [
        DetailsEditPanelComponent,
        NodesFilesViewComponent,
        UploadInspectionCardComponent,
        UploadedInspectionFilesListComponent,
        DetailsNodeViewComponent,
        DetailsPlotViewComponent,
        PropertyTitleComponent
    ],
    imports: [
        CommonModule,
        MapModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ],
    exports: [
        DetailsEditPanelComponent,
        PropertyTitleComponent,
        UploadInspectionCardComponent
    ],
    providers: [
        EditingFieldNameProvider
    ]
})
export class DetailsInfoPanelModule {
}
