<div class="property-list" *ngIf="plot$ | async as item">
    <div class="property-item">
        <app-property-title>Название участка</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'name'" [disable]="form.controls.name.invalid" #editor class="imus-row">
            <span class="content">{{item?.name
                ? item?.name
                : 'Нет данных'}}</span>
            <div class="imus-row-item editor">
                <app-imus-input #fieldName placeholder="Введите название участка" label="Название участка" imusRequired
                                formControlName="name" observationEvent
                                (eventTrigger)="editField(item, fieldName.value, 'name')"></app-imus-input>
                <field-errors formFieldName="name"></field-errors>
            </div>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Тип объекта</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'plot_type_id'" class="imus-row">
                    <span class="content">
                      {{item?.plot_type?.id !== null
                        ? item.plot_type!.russian
                        : 'Тип не установлен. Для редактирования участка необходимо установить тип!'}}
                    </span>
            <app-imus-select #select label="Тип объекта" class="editor" formControlName="plot_type_id"
                             imusRequired
                             (selectionChange)="editField(item, $event, 'plot_type_id')"
                             [specification]="{ key: 'id', value: 'russian'}"
                             [items]="(plotTypes$ | async)! | sortByLayerType : (editableLayer$ | async).layerTypeId"></app-imus-select>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Начальный узел</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'start_node'" class="imus-row">
            <span class="content">{{item?.startNode?.name
                ? item.startNode.name
                : 'Нет данных'}}</span>
            <div class="imus-row-item editor">
                <app-imus-input #fieldStart placeholder="Введите название начального узла" label="Начальный узел"
                                imusRequired
                                formControlName="start_node" observationEvent
                                (eventTrigger)="editField(item, fieldStart.value, 'start_node')"></app-imus-input>
                <field-errors formFieldName="start_node"></field-errors>
            </div>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Конечный узел</app-property-title>
        <div class="imus-row-item editor">
            <app-switch-editor [closeTrigger]="(current$ | async) === 'end_node'" class="imus-row">
            <span class="content">{{item?.endNode?.name
                ? item.endNode.name
                : 'Нет данных'}}</span>
                <div class="imus-row-item editor">
                    <app-imus-input #fieldEnd placeholder="Введите название конечного узла" label="Конечный узел"
                                    imusRequired
                                    formControlName="end_node" observationEvent
                                    (eventTrigger)="editField(item, fieldEnd.value, 'end_node')"></app-imus-input>
                    <field-errors formFieldName="end_node"></field-errors>
                </div>
            </app-switch-editor>
        </div>
    </div>
    <div class="property-item">
        <app-property-title>Режим работы</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'on'" class="imus-row">
            <span class="content">{{item.on ? 'Включен' : 'Отключен'}}</span>
            <app-imus-select label="Режим работы" class="editor" formControlName="on"
                             (selectionChange)="editField(item, $event, 'on')"
                             [specification]="{ key: 'key', value: 'value'}"
                             [items]="operationsList"></app-imus-select>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Длина</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'length'" class="imus-row">
            <span class="content">{{item?.length
                ? item.length + ' м'
                : 'Нет данных'}}</span>
            <div class="imus-row-item editor">
                <app-imus-input #fieldLength placeholder="Введите длину" label="Длина"
                                formControlName="length" observationEvent
                                (eventTrigger)="editField(item, fieldLength.value, 'length')"></app-imus-input>
            </div>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Длина секции</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'section_length'" class="imus-row">
            <span class="content">{{item?.section_length
                ? item.section_length + ' м'
                : 'Нет данных'}}</span>
            <div class="imus-row-item editor">
                <app-imus-input #fieldSection placeholder="Введите длину секции" label="Длина секции"
                                formControlName="section_length" observationEvent
                                (eventTrigger)="editField(item, fieldSection.value, 'section_length')"></app-imus-input>
            </div>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Облицовка</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'coating_id'" class="imus-row">
                    <span class="content">
                      {{item?.coating?.properties?.russian
                        ? item.coating.properties?.russian
                        : 'Нет данных'}}
                    </span>
            <app-imus-select #select label="Облицовка" class="editor" formControlName="coating_id"
                             (selectionChange)="editField(item, $event, 'coating_id')"
                             [specification]="{ key: 'id', value: 'russian'}"
                             [items]="categoryList.getOptionsByCategory(codeCategory.coating)"></app-imus-select>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Диаметр (ширина/высота)</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'diameter'" class="imus-row">
            <span class="content">{{item?.diameter
                ? item.diameter + ' мм'
                : 'Нет данных'}}</span>
            <div class="imus-row-item editor">
                <app-imus-input #fieldDiameter placeholder="Введите диаметр" label="Диаметр"
                                formControlName="diameter" observationEvent
                                (eventTrigger)="editField(item, fieldDiameter.value, 'diameter')"></app-imus-input>
            </div>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Уклон</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'slope'" class="imus-row">
            <span class="content">{{item?.slope
                ? item.slope
                : 'Нет данных'}}</span>
            <div class="imus-row-item editor">
                <app-imus-input #fieldSlope placeholder="Введите уклон" label="Уклон"
                                formControlName="slope" observationEvent
                                (eventTrigger)="editField(item, fieldSlope.value, 'slope')"></app-imus-input>
            </div>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Год прокладки</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'laying_year'" class="imus-row">
            <span class="content">{{item?.laying_year
                ? item.laying_year + ' г'
                : 'Нет данных'}}</span>
            <div class="imus-row-item editor">
                <app-imus-input #fieldLaying placeholder="Введите год прокладки" label="Год прокладки"
                                formControlName="laying_year" observationEvent
                                (eventTrigger)="editField(item, fieldLaying.value, 'laying_year')"></app-imus-input>
            </div>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Материал</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'material_id'" class="imus-row">
                    <span class="content">
                      {{item?.material?.properties?.russian
                        ? item.material.properties?.russian
                        : 'Нет данных'}}
                    </span>
            <app-imus-select #select label="Материал" class="editor" formControlName="material_id"
                             (selectionChange)="editField(item, $event, 'material_id')"
                             [specification]="{ key: 'id', value: 'russian'}"
                             [items]="categoryList.getOptionsByCategory(codeCategory.material)"></app-imus-select>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Использование</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'use_id'" class="imus-row">
                    <span class="content">
                      {{item?.use?.properties?.russian
                        ? item.use.properties?.russian
                        : 'Нет данных'}}
                    </span>
            <app-imus-select #select label="Использование" class="editor" formControlName="use_id"
                             (selectionChange)="editField(item, $event, 'use_id')"
                             [specification]="{ key: 'id', value: 'russian'}"
                             [items]="categoryList.getOptionsByCategory(codeCategory.use)"></app-imus-select>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Форма</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'shape_id'" class="imus-row">
                    <span class="content">
                      {{item?.shape?.properties?.russian
                        ? item.shape.properties?.russian
                        : 'Нет данных'}}
                    </span>
            <app-imus-select #select label="Назначение" class="editor" formControlName="shape_id"
                             (selectionChange)="editField(item, $event, 'shape_id')"
                             [specification]="{ key: 'id', value: 'russian'}"
                             [items]="categoryList.getOptionsByCategory(codeCategory.shape)"></app-imus-select>
        </app-switch-editor>
    </div>
<!--    <div class="property-item">-->
<!--        <app-property-title>Назначение</app-property-title>-->
<!--        <app-switch-editor [closeTrigger]="(current$ | async) === 'purpose_id'" class="imus-row">-->
<!--                    <span class="content">-->
<!--                      {{item?.purpose?.properties?.russian-->
<!--                        ? item.purpose.properties?.russian-->
<!--                        : 'Нет данных'}}-->
<!--                    </span>-->
<!--            <app-imus-select #select label="Назначение" class="editor" formControlName="purpose_id"-->
<!--                             (selectionChange)="editField(item, $event, 'purpose_id')"-->
<!--                             [specification]="{ key: 'id', value: 'russian'}"-->
<!--                             [items]="categoryList.getOptionsByCategory(codeCategory.purpose)"></app-imus-select>-->
<!--        </app-switch-editor>-->
<!--    </div>-->
    <div class="property-item">
        <app-property-title>Код расположения</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'location_id'" class="imus-row">
                    <span class="content">
                      {{item?.location?.properties?.russian
                        ? item.location.properties?.russian
                        : 'Нет данных'}}
                    </span>
            <app-imus-select #select label="Код расположения" class="editor" formControlName="location_id"
                             (selectionChange)="editField(item, $event, 'location_id')"
                             [specification]="{ key: 'id', value: 'russian'}"
                             [items]="categoryList.getOptionsByCategory(codeCategory.location)"></app-imus-select>
        </app-switch-editor>
    </div>
    <div class="property-item">
        <app-property-title>Примечание</app-property-title>
        <app-switch-editor [closeTrigger]="(current$ | async) === 'notes'" [disable]="form.controls.name.invalid" #editor class="imus-row">
                    <span class="content">{{item?.notes
                        ? item?.notes
                        : 'Нет данных'}}</span>
            <div class="imus-row-item editor">
                <app-text-area #fieldNotes [title]="'Описание'" formControlName="notes" observationEvent
                               (eventTrigger)="editField(item, fieldNotes.value, 'notes')"></app-text-area>
            </div>
        </app-switch-editor>
    </div>
</div>
